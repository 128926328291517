// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { liveImgURL } from "../../../style/Style";
import PostAPI from "../../../api/PostAPI";
import { error_toaster, success_toaster } from "../../../toaster/Toaster";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import GetAPI from "../../../api/GetAPI";
import moment from "moment";
import QRCode from "react-qr-code";
import { FaArrowDown } from "react-icons/fa";

export default function OrderCreatedCard(props) {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [firstModel, setFirstModel] = useState(false);
  const [secondModel, setSecondModel] = useState(false);
  const [modal, setModal] = useState(false);

  // View Details Page
  const viewDetailFunc = async (event) => {
    event.preventDefault();
    let res = await PostAPI("bookingdetails", {
      bookingId: props.apiEle.id,
    });
    if (res?.data?.status === "1") {
      navigate("/all_incoming_detail", {
        state: { res: res.data, bookingStatusId: props.apiEle.bookingStatusId },
      });
      success_toaster(res?.data?.message);
    } else {
      error_toaster(res?.data?.message);
    }
  };

  const { getData } = GetAPI("allassociateddrivers");

  const assignDriver = async (bookingType, driverId) => {
    let IncomingbookingType = "";
    if (
      bookingType === 1 ||
      bookingType === 2 ||
      bookingType === 3 ||
      bookingType === 4
    )
      IncomingbookingType = "receiving";
    else IncomingbookingType = "delivery";

    let res = await PostAPI("assigndriver", {
      bookingId: props.apiEle.id,
      bookingType: IncomingbookingType,
      overRide: false,
      driverId,
    });
    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
    }
    // else if (res?.data?.status === "2") {
    //   success_toaster(res?.data?.error);
    //   success_toaster(res?.data?.message);
    //   setSecondModel(true);
    // }
    else {
      error_toaster(res?.data?.message);
    }
  };

  const selfpickupdelivered = async (event) => {
    event.preventDefault();
    let res = await PostAPI("selfpickupdelivered", {
      bookingId: props.apiEle.id,
    });

    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      navigate("/self_pickup_waiting");
    } else {
      error_toaster(res?.data?.message);
    }
  };

  const handlePrint = () => {
    const sectionToPrint = document.getElementById("sectionToPrint");
    if (sectionToPrint) {
      const printContents = sectionToPrint.innerHTML;

      const newWindow = window.open();
      newWindow.document.write(`<!DOCTYPE html>
      <html lang="en">
   <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
    <title>Print Coupon</title>
    </head>
   <body>`);
      newWindow.document.write(printContents);
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      setTimeout(() => {
        newWindow.print();
        setModal(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (modal) {
      setTimeout(handlePrint, 500);
    }
  }, [modal]);

  return (
    <>
      <div className="grid grid-cols-12 gap-5 bg-white p-5 rounded-lg my-8">
        <div className="2xl:col-span-8 col-span-12 space-y-3">
          <div className="flex lg:flex-row flex-col  lg:items-center items-start gap-x-2">
            <h3 className="text-base font-medium text-opacity-60">
              {props.apiEle?.trackingId}
            </h3>
            <div
              className={`text-xs font-normal text-white px-10 py-2 rounded-md ${props.bg}`}
            >
              {props.apiEle?.bookingStatus?.title}
            </div>
            <div className="text-sm">
              {props?.apiEle?.dropoffDate && (
                <div>
                  <h3 className="text-base font-medium">
                    <span className="text-black text-opacity-60">
                      Drop Off Schedule:{" "}
                    </span>
                    {moment(props?.apiEle?.dropoffDate).format("DD-MM-YYYY")}
                     (
                    {moment(props?.apiEle?.dropoffEndTime, "HH:mm").format(
                      "hh:mm A"
                    )}
                    )
                  </h3>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="lg:col-span-1 col-span-2">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-5"
                  src={`${liveImgURL}/round.webp`}
                  alt="rounded"
                />
                <img
                  className="h-16"
                  src={`${liveImgURL}/line.webp`}
                  alt="rounded"
                />
                <img
                  className="w-5"
                  src={`${liveImgURL}/rectangle.webp`}
                  alt="rounded"
                />
              </div>
            </div>
            <div className="lg:col-span-11 col-span-12 flex flex-col justify-between space-y-4">
              <div>
                <h3 className="text-sm 2xl:text-base font-medium">Pickup</h3>
                <h3 className="text-sm 2xl:text-base font-medium  text-black text-opacity-60">
                  {props?.apiEle?.pickupAddress?.postalCode +
                    " " +
                    props?.apiEle?.pickupAddress?.secondPostalCode}
                </h3>
              </div>
              <div className="flex lg:flex-row flex-col gap-x-8 2xl:gap-x-16 gap-x-3 gap-y-3">
                <div>
                  <h3 className="text-sm 2xl:text-base font-medium text-opacity-60">
                    Drop-off
                  </h3>
                  <h3 className="text-sm 2xl:text-base font-medium text-black text-opacity-60">
                    {props?.apiEle?.dropoffAddress?.postalCode +
                      " " +
                      props?.apiEle?.dropoffAddress?.secondPostalCode}
                  </h3>
                </div>
                <div>
                  <h3 className="text-sm 2xl:text-base font-medium text-black text-opacity-60">
                    Weight
                  </h3>
                  <h3 className="text-sm 2xl:text-base font-medium text-opacity-60">
                    {props?.apiEle?.weight} {props?.apiEle?.weightUnitB?.symbol}
                  </h3>
                </div>
                <div>
                  <h3 className="text-sm 2xl:text-base font-medium text-black text-opacity-60">
                    Size
                  </h3>
                  <h3 className="text-sm 2xl:text-base font-medium text-opacity-60">
                    {props?.apiEle?.size?.title}
                  </h3>
                </div>
                <div>
                  <h3 className="text-sm 2xl:text-base font-medium text-black text-opacity-60">
                    Type
                  </h3>
                  <h3 className="text-sm 2xl:text-base font-medium text-opacity-60">
                    {props?.apiEle?.shipmentType?.title}
                  </h3>
                </div>
                <div>
                  <h3 className="text-sm 2xl:text-base font-medium text-black text-opacity-60">
                    Booking Type
                  </h3>
                  <h3 className="text-sm 2xl:text-base font-medium text-opacity-60">
                    {props?.apiEle?.bookingType?.title}
                  </h3>
                </div>
                <div>
                  <h3 className="text-sm 2xl:text-base font-medium text-black text-opacity-60">
                    Security Code
                  </h3>
                  <h3 className="text-sm 2xl:text-base font-medium text-opacity-60">
                    {props?.apiEle?.securityCode}
                  </h3>
                </div>

                {/* {props?.apiEle?.dropoffDate && (
                  <div>
                    <h3 className="text-base font-medium text-black text-opacity-60">
                      Drop Off Date
                    </h3>
                    <h3 className="text-base font-medium text-opacity-60">
                      {props?.apiEle?.dropoffDate}
                    </h3>
                  </div>
                )} */}

                <div>
                  <h3 className="text-sm 2xl:text-base font-medium text-black text-opacity-60">
                    Delivery warehouse
                  </h3>
                  <h3 className="text-sm 2xl:text-base font-medium text-opacity-60">
                    {props?.apiEle?.deliveryWarehouse?.name}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-4 col-span-12 gap-5 flex flex-col items-end justify-between">
          <div>
            {props?.apiEle?.bookingStatusId === 8 ? (
              <></>
            ) : (
              <>
                <div>
                  {props?.apiEle?.deliveryDriver !== null &&
                    location === "/all_outgoing" && (
                      <div className="grid grid-cols-2 justify-end items-center">
                        <div className="">
                          <img
                            className="w-16 h-16  ml-auto rounded-fullest"
                            src={`https://backend.pps507.com/${
                              props?.apiEle?.deliveryDriver?.image
                                ? props?.apiEle?.deliveryDriver?.image
                                : "Public/Images/Profile/default.png"
                            }`}
                            alt="driver"
                          />
                        </div>
                        <div className="ml-5">
                          <h3 className="text-themePersian text-xl font-bold">
                            {props?.apiEle?.deliveryDriver?.firstName +
                              " " +
                              props?.apiEle?.deliveryDriver?.lastName}
                          </h3>
                          <p className="text-sm font-normal text-[#20205399]">
                            {props?.apiEle?.deliveryDriver?.countryCode +
                              " " +
                              props?.apiEle?.deliveryDriver?.phoneNum}
                          </p>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {location === "/allincoming" &&
                    props.apiEle.bookingStatusId !== 1 &&
                    props?.apiEle?.receivingDriver !== null && (
                      <div className="grid grid-cols-2 justify-end items-center">
                        <div className="">
                          <img
                            className="w-16 h-16  ml-auto rounded-fullest"
                            src={`https://backend.pps507.com/${
                              props?.apiEle?.receivingDriver?.image
                                ? props?.apiEle?.receivingDriver?.image
                                : "Public/Images/Profile/default.png"
                            }`}
                            alt="driver"
                          />
                        </div>
                        <div className="ml-5">
                          <h3 className="text-themePersian text-xl font-bold">
                            {props?.apiEle?.receivingDriver?.firstName +
                              " " +
                              props?.apiEle?.receivingDriver?.lastName}
                          </h3>
                          <p className="text-sm font-normal text-[#20205399]">
                            {props?.apiEle?.receivingDriver?.countryCode +
                              " " +
                              props?.apiEle?.receivingDriver?.phoneNum}
                          </p>
                        </div>
                      </div>
                    )}
                </div>
                {/* <div className="flex items-center justify-end gap-x-1 mb-2">
              <h4 className="text-base font-normal">Drop Off Start Time</h4>
              <p className="bg-themeGrey text-center text-lg p-1">
                {props?.apiEle?.dropoffStartTime}
              </p>
            </div>
            <div className="flex items-center justify-end gap-x-1">
              <h4 className="text-base font-normal">Drop Off End Time</h4>
              <p className="bg-themeGrey text-center text-lg p-1">
                {props?.apiEle?.dropoffEndTime}
              </p>
            </div> */}
              </>
            )}
          </div>
          <div className="space-x-2">
            {props?.apiEle?.bookingStatusId === 1 && (
              <button
                className="text-xs font-normal text-themeBlue border border-themeBlue px-3 xl:px-5 py-2 rounded-md"
                onClick={() => setFirstModel(true)}
              >
                Assign Driver
              </button>
            )}
            {props?.apiEle?.bookingStatusId === 2 && (
              <button
                className="text-xs font-normal text-themeBlue border border-themeBlue px-3 xl:px-5 py-2 rounded-md"
                onClick={() => setFirstModel(true)}
              >
                Assign Driver
              </button>
            )}

            {props?.apiEle?.bookingStatusId === 9 &&
              props?.apiEle?.bookingTypeId === 1 && (
                <button
                  className="text-xs font-normal text-themeBlue border border-themeBlue px-3 xl:px-5 py-2 rounded-md"
                  onClick={() => setFirstModel(true)}
                >
                  Assign Driver
                </button>
              )}
            {props?.apiEle?.bookingStatusId === 10 &&
              props?.apiEle?.bookingTypeId === 1 && (
                <button
                  className="text-xs font-normal text-themeBlue border border-themeBlue px-3 xl:px-5 py-2 rounded-md"
                  onClick={() => setFirstModel(true)}
                >
                  Assign Driver
                </button>
              )}

            <Modal
              onClose={() => setFirstModel(false)}
              isOpen={firstModel}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Drivers List</ModalHeader>
                <ModalCloseButton />
                <ModalBody className="mb-6">
                  <div className="max-h-[50vh] overflow-y-auto">
                    {getData.data && getData.data.length > 0 ? (
                      getData?.data?.map((ele, key) => (
                        <div className="flex justify-between items-center mb-3">
                          <div className="flex items-center gap-x-3">
                            <img
                              className="w-16 h-16 rounded-fullest object-cover"
                              src={`https://backend.pps507.com/${ele?.user?.image}`}
                              alt=""
                            />
                            <div>
                              <p className="hidden">{key}</p>
                              <h1 className="font-semibold text-xl">
                                {ele?.user?.firstName +
                                  " " +
                                  ele?.user?.lastName ||
                                  "No data Available"}{" "}
                                <br />
                                <span className="text-sm">
                                  {" "}
                                  ({ele?.user?.countryCode}
                                  {ele?.user?.phoneNum} ){" "}
                                </span>
                              </h1>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                assignDriver(
                                  props?.apiEle?.bookingStatusId,
                                  ele?.user?.id
                                )
                              }
                              className="text-xs font-normal text-themeBlue border border-themeBlue px-3 xl:px-5 py-2 rounded-md"
                            >
                              Assign
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <h1>No Driver Available</h1>
                      </>
                    )}
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal
              onClose={() => setSecondModel(false)}
              isOpen={secondModel}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Assign Driver Again</ModalHeader>
                <ModalCloseButton />
                <ModalBody className="mb-6">
                  <div className="max-h-[50vh] overflow-y-auto"></div>
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal
              onClose={() => {
                setModal(false);
              }}
              isOpen={modal}
              isCentered
              size={"3xl"}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody paddingTop={5} paddingBottom={5}>
                  <div
                    id="sectionToPrint"
                    style={{ width: "80%", margin: "auto" }}
                  >
                    <div className="border-2 border-black m-10">
                      <div className="grid grid-cols-7">
                        <div
                          className="text-7xl flex items-center font-bold p-4 border-black border-r-2 
                          col-span-1"
                        >
                          E
                        </div>
                        <div className="col-span-6 p-4 flex flex-col gap-2">
                          <div className="flex justify-end text-xs font-semibold">
                            panamapostal.com
                          </div>
                          <div className="text-sm font-semibold">
                            <h2>Send: {props?.apiEle?.senderName}</h2>
                            <p>
                              From:{" "}
                              {props?.apiEle?.pickupAddress?.combinedPostal}
                            </p>
                          </div>
                          <div className="flex justify-center text-sm font-semibold">
                            Date:{" "}
                            {moment(props?.apiEle?.createdAt).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                          {/* <div>
                    <img
                      src="/images/qrcode.webp"
                      alt="qrcode"
                      className="w-full h-12 object-contain"
                    />
                  </div> */}
                        </div>
                      </div>
                      <div className="border-black border-t-2 border-b-2 py-3">
                        <div className="text-xl font-semibold uppercase text-center">
                          {props?.apiEle?.shipmentType?.title}{" "}
                          <sup className="uppercase text-sm">Tm</sup>
                        </div>
                      </div>
                      <div className="grid grid-cols-4 p-4">
                        <div className="col-span-3 space-y-5">
                          <div>
                            <div className="flex gap-5 text-sm font-semibold">
                              <div>Sending Warehouse:</div>
                              <div>
                                <div>{localStorage.getItem("name")}</div>
                                <div className="flex justify-center my-2">
                                  <FaArrowDown />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-5 text-sm font-semibold">
                              <div>Receiving Warehouse: </div>
                              <div>
                                {props?.apiEle?.deliveryWarehouse?.name}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-5 text-sm font-semibold">
                            <div>Receives: </div>
                            <div className="flex flex-col">
                              <div className="capitalize">
                                {props?.apiEle?.receiverName}
                              </div>
                              <div>
                                {
                                  props?.apiEle?.deliveryWarehouse
                                    ?.dropoffAddress?.combinedPostal
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <img src="/images/logo-gray.webp" alt="logo-gray" />
                        </div>
                      </div>
                      <div className="border-black border-t-2 border-b-2 py-3 flex flex-col items-center gap-2">
                        <h2 className="text-xl font-semibold uppercase text-center">
                          Pps Tracking #
                        </h2>
                        <QRCode
                          size={256}
                          style={{
                            height: "80px",
                            width: "80px",
                          }}
                          value={props?.apiEle?.qrCode}
                          viewBox={`0 0 256 256`}
                        />
                        <div className="text-sm text-center">
                          {props?.apiEle?.trackingId}
                        </div>
                      </div>
                      <div className="p-4 text-xl font-semibold uppercase text-center">
                        {
                          props?.apiEle?.deliveryWarehouse?.dropoffAddress
                            ?.combinedPostal
                        }
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>

            <button
              onClick={viewDetailFunc}
              className="text-xs font-normal text-white bg-themePersian px-3 xl:px-5 py-2 rounded-md"
            >
              View Details
            </button>

            <button
              onClick={() => {
                setModal(true);
              }}
              className="text-xs font-normal text-white bg-themePersian px-3 xl:px-5 py-2 rounded-md"
            >
              Print
            </button>

            {props?.apiEle?.bookingStatusId === 16 &&
              props?.apiEle?.billingDetail?.pickupDriverEarning === null && (
                <button
                  onClick={props.onClick}
                  className="text-xs font-normal text-white bg-themeOrange px-2 xl:px-5 py-2 rounded-md"
                >
                  Confirm Received
                </button>
              )}

            {location === "/self_pickup_waiting" && (
              <button
                onClick={selfpickupdelivered}
                className="text-xs font-normal text-white bg-themeOrange px-2 xl:px-5 py-2 rounded-md"
              >
                Delivered
              </button>
            )}

            {location === "/returned_packages" &&
            props.tab === "Returned Jobs(Incoming)" ? (
              <button
                onClick={props.onClick}
                className="text-xs font-normal text-white bg-themeOrange px-2 xl:px-5 py-2 rounded-md"
              >
                Receive from Driver
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
