// @ts-nocheck
import React from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/TableStyle";
import { Helmet } from "react-helmet";
import Layout from "../layout/Layout";
import GetAPI from "../../api/GetAPI";
import { useLocation } from "react-router-dom";
import { ImArrowLeft2 } from "react-icons/im";
import Loader from "../../loader/Loader";
import { IMG_URL } from "../../api/API";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   Button,
//   useDisclosure,
// } from "@chakra-ui/react";

export default function Drivers() {
  const location = useLocation();
  const { getData } = GetAPI(`driverdetails?id=${location?.state?.userId}`);
  // const [modal, setModal] = useState(false);
  // const [modalType, setModalType] = useState(false);

  const columns = [
    {
      name: "Sr#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Pickup DBS #",
      selector: (row) => row.pickupAddress,
      sortable: true,
    },
    {
      name: "Drop off DBS #",
      selector: (row) => row.dropoffAddress,
    },
    {
      name: "Order #",
      selector: (row) => row.trackingId,
    },
    {
      name: "Date",
      selector: (row) => row.pickupDate,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
  ];
  const datas = [];
  getData?.data?.receivingDriver?.map((dri, index) => {
    datas.push({
      id: index + 1,
      pickupAddress: `${dri?.pickupAddress?.postalCode} ${dri?.pickupAddress?.secondPostalCode}`,
      dropoffAddress: `${dri?.dropoffAddress?.postalCode} ${dri?.dropoffAddress?.secondPostalCode}`,
      trackingId: dri?.trackingId,
      pickupDate: dri?.pickupDate,
      status: dri?.bookingStatus?.title,
    });
  });

  return getData.length === 0 ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>PPS - Driver Details</title>
      </Helmet>

      <Layout
        extend={true}
        data={
          <section className="grid grid-cols-12 gap-10">
            <div className="col-span-9">
              <div
                onClick={() => window.history.back()}
                className="flex items-center text-sm text-white bg-themeBlue3 cursor-pointer gap-x-2 px-3 py-2 mb-4 w-20"
              >
                <ImArrowLeft2 size={18} /> Back
              </div>

              <DataTable
                columns={columns}
                data={datas}
                customStyles={customStyles}
              />
            </div>

            <div className="bg-white p-10 mt-20 col-span-3 rounded-lg">
              <div>
                <img
                  src={`${IMG_URL}${getData?.data?.image}`}
                  alt="profile"
                  className="w-24 h-24 m-auto rounded-full"
                />
              </div>
              <div className="space-y-1">
                <h2 className="text-xl text-themePersian pt-5">
                  {getData?.data?.firstName} {getData?.data?.lastName}
                </h2>
                <p className="text-xs text-[#20205399] pb-5">
                  Member Since: {getData?.data?.joinedOn}
                </p>
                <div className="w-full h-[2px] bg-[#00000066] "></div>
              </div>
              <div className="space-y-1">
                <h2 className="text-sm text-[#00000099] pt-5">Email</h2>
                <p className="text-black text-base pb-5">
                  {getData?.data?.email}
                </p>
                <div className="w-full h-[2px] bg-[#00000066] "></div>
              </div>
              <div className="space-y-1">
                <h2 className="text-sm text-[#00000099] pt-5">Phone no</h2>
                <p className="text-black text-base pb-5">
                  {getData?.data?.phoneNum}
                </p>
                <div className="w-full h-[2px] bg-[#00000066] "></div>
              </div>
              <div className="space-y-1">
                <h2 className="text-sm text-[#00000099] pt-5">Vehicle</h2>
                <p className="text-black text-base pb-5">
                  {getData?.data?.driverDetail?.vehicleType?.title}
                </p>
              </div>
              {/* <div>
                <Button
                  onClick={() => {
                    setModal(true);
                    setModalType("document");
                  }}
                  background={"#F4F5FA"}
                >
                  View Documents
                </Button>

                <Modal
                  isOpen={modal}
                  onClose={() => setModal(false)}
                  isCentered
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {modalType ? <>Hello</> : <>Not welcome</>}
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={() => setModal(false)}
                      >
                        Close
                      </Button>
                      <Button variant="ghost">Secondary Action</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div> */}
            </div>
          </section>
        }
      />
    </>
  );
}
