// @ts-nocheck
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import PostAPI from "../../api/PostAPI";
import { labelStyle, liveImgURL } from "../../style/Style";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import IncomingDetailsStatus from "../allincoming/allIncomingElements/IncomingDetailsStatus";
import Layout from "../layout/Layout";
import moment from "moment";

export default function TrackID() {
  const [trackID, setTrackID] = useState("");
  const [disbaled, setDisabled] = useState(false);
  const [res, setRes] = useState([]);

  const track = async () => {
    if (trackID === "") {
      error_toaster("Please Enter Tracking ID");
    } else {
      setDisabled(true);
      const res = await PostAPI("/trackorder", {
        trackingId: trackID,
      });
      if (res?.data.status === "1") {
        success_toaster(res?.data?.message);
        setRes(res?.data);
        setDisabled(false);
      } else {
        error_toaster(res?.data?.message);
        setDisabled(false);
      }
    }
  };

  function formatPickupTimeRange(pickupTimeRange) {
    if (!pickupTimeRange) {
      return null;
    }
    const [startTime, endTime] = pickupTimeRange.split(" - ");
    const formattedStartTime = moment(startTime, "HH:mm:ss").format("hh:mm A");
    const formattedEndTime = moment(endTime, "HH:mm:ss").format("hh:mm A");
    const formattedRange = `${formattedStartTime} - ${formattedEndTime}`;
    return formattedRange;
  }

  return (
    <>
      <Helmet>
        <title>PPS - Tracking</title>
      </Helmet>
      <Layout
        extend={true}
        data={
          <>
            <h1>Track Packages</h1>
            <div className="mb-5">
              <label className={labelStyle} htmlFor="trackID">
                Track ID
              </label>
              <div className="inline-flex items-center gap-x-5">
                <div>
                  <input
                    value={trackID}
                    onChange={(e) => setTrackID(e.target.value)}
                    type="text"
                    name="trackingId"
                    id="trackID"
                    placeholder="pps-132-123"
                    className="text-base font-normal  rounded-lg border border-transparent w-72 px-4 py-3 focus:outline-none "
                  />
                  <button
                    onClick={track}
                    disabled={disbaled}
                    className="text-lg font-light text-white bg-themeGreen rounded-md px-4 py-3 "
                  >
                    Track
                  </button>
                </div>
                {res?.data && (
                  <div>
                    <h1 className="border border-themeOrange border-opacity-30 text-themeOrange rounded-md cursor-pointer px-3 py-2">
                      <span className="text-black  opacity-30">Status:</span>
                      {res?.data?.bookingStatus}
                    </h1>
                  </div>
                )}
              </div>
            </div>
            {res?.status === "1" && (
              <>
                <IncomingDetailsStatus
                  data={res?.data}
                  bookingStatusId={res?.data.bookingStatusId}
                />
                <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 my-10 gap-5">
                  <div className="row-span-3 font-medium bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                    <h3 className="text-2xl">
                      Order#
                      <span className="text-[#00000066]">
                        {res?.data?.trackingId}
                      </span>
                    </h3>
                    <h1 className="font-medium text-2xl">
                      Scheduled By:
                      <span className={` text-black text-opacity-40 ml-2`}>
                        {res?.data?.scheduleSetBy
                          ? (res?.data?.scheduleSetBy).charAt(0).toUpperCase() +
                            (res?.data?.scheduleSetBy).slice(1)
                          : "No Data"}
                      </span>
                    </h1>
                    <div>
                      <h3 className="text-2xl">Sender Details</h3>

                      <h3 className="text-themePersian text-xl font-bold">
                        {res?.data?.senderDetails?.name || <Skeleton />}
                      </h3>
                      <p className="text-sm font-normal text-[#20205399]">
                        Member Since :{res?.data?.senderDetails?.memberSince}
                      </p>
                      <p className="text-base font-normal text-[#20205399]">
                        Email
                      </p>
                      <p className="text-base font-normal">
                        {res?.data?.senderDetails?.email || <Skeleton />}
                      </p>
                      <p className="text-base font-normal text-[#20205399]">
                        Phone no
                      </p>
                      <p className="text-base font-normal">
                        {res?.data?.senderDetails?.phone || <Skeleton />}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-2xl">Recipient Details</h3>
                      <h3 className="text-themePersian text-xl font-bold">
                        {res?.data?.recipientDetails?.name || <Skeleton />}
                      </h3>
                      <p className="text-base font-normal text-[#20205399]">
                        Email
                      </p>
                      <p className="text-base font-normal">
                        {" "}
                        {res?.data?.recipientDetails?.email || <Skeleton />}
                      </p>
                      <p className="text-base font-normal text-[#20205399]">
                        Phone no
                      </p>
                      <p className="text-base font-normal">
                        {" "}
                        {res?.data?.recipientDetails?.phone || <Skeleton />}
                      </p>
                    </div>
                    <hr />
                    <h3 className="text-2xl">Order Instruction</h3>
                  </div>
                  <div className="row-span-3 bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                    <div className="text-2xl flex items-center gap-x-6">
                      <img
                        className="w-9"
                        src={`${liveImgURL}/delivery.webp`}
                        alt="Delivery Details"
                      />
                      Delivery Details
                    </div>{" "}
                    <div className="flex gap-x-5 relative">
                      <div className="flex flex-col items-center">
                        <div className="w-fit border border-themeOrange rounded-fullest p-1">
                          <div className="w-4 h-4 rounded-fullest bg-themeOrange"></div>
                        </div>
                        <img src={`${liveImgURL}/line.webp`} alt="line" />
                        <div className="w-fit border border-themeOrange p-1">
                          <div className="w-4 h-4 bg-themeOrange"></div>
                        </div>
                        <img src={`${liveImgURL}/line.webp`} alt="line" />
                        <div className="w-fit border border-themeOrange p-1">
                          <div className="w-4 h-4 bg-themeOrange"></div>
                        </div>
                        <img src={`${liveImgURL}/line.webp`} alt="line" />
                        <div className="w-fit border border-themeOrange rounded-fullest p-1">
                          <div className="w-4 h-4 rounded-fullest bg-themeOrange"></div>
                        </div>
                      </div>
                      <div className="space-y-6 w-full">
                        <div className="flex justify-between min-h-[44px]">
                          <div>
                            <p className="font-medium text-base">Pickup</p>
                            <p
                              className={`font-normal text-sm text-opacity-60`}
                            >
                              {res?.data?.deliveryDetails?.pickupCode}
                            </p>
                          </div>
                          <div className="flex flex-col items-end">
                            <p
                              className={`font-normal text-sm  text-opacity-60`}
                            >
                              {formatPickupTimeRange(
                                res?.data?.deliveryDetails?.pickupTime
                              )}
                            </p>
                            <p
                              className={`font-normal text-sm text-opacity-60`}
                            >
                              {res?.data?.deliveryDetails?.pickupDate}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between min-h-[44px]">
                          <div>
                            <p className="font-medium text-base">
                              Receiving Warehouse
                            </p>
                            <p
                              className={`font-normal text-sm text-opacity-60`}
                            >
                              {res?.data?.receivingWarehouse &&
                                `${res?.data?.receivingWarehouse?.addressDB?.PostalCode} ${res?.data?.receivingWarehouse?.addressDB?.secondPostalCode}`}
                            </p>
                          </div>
                          <div className="flex flex-col items-end">
                            <p
                              className={`font-normal text-sm  text-opacity-60`}
                            >
                              {res?.data?.receivingWarehouse &&
                                res?.data?.receivingWarehouse?.name}
                            </p>
                            <p
                              className={`font-normal text-sm  text-opacity-60`}
                            >
                              {res?.data?.receivingWarehouse &&
                                res?.data?.receivingWarehouse?.email}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between min-h-[44px]">
                          <div>
                            <p className="font-medium text-base">
                              Delivery Warehouse
                            </p>
                            <p
                              className={`font-normal text-sm  text-opacity-60`}
                            >
                              {res?.data?.deliveryWarehouse &&
                                `${res?.data?.deliveryWarehouse?.addressDB?.PostalCode} ${res?.data?.deliveryWarehouse?.addressDB?.secondPostalCode}`}
                            </p>
                          </div>
                          <div className="flex flex-col items-end">
                            <p
                              className={`font-normal text-sm text-opacity-60`}
                            >
                              {res?.data?.deliveryWarehouse &&
                                res?.data?.deliveryWarehouse?.name}
                            </p>
                            <p
                              className={`font-normal text-sm  text-opacity-60`}
                            >
                              {res?.data?.deliveryWarehouse &&
                                res?.data?.deliveryWarehouse?.email}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between min-h-[44px]">
                          <div>
                            <p className="font-medium text-base">Drop off</p>
                            <p
                              className={`font-normal text-sm text-opacity-60`}
                            >
                              {res?.data?.deliveryDetails?.dropoffCode}
                            </p>
                          </div>
                          <div className="flex flex-col items-end">
                            <p
                              className={`font-normal text-sm text-opacity-60`}
                            >
                              {res?.data?.deliveryDetails?.dropoffTime}
                            </p>
                            <p
                              className={`font-normal text-sm text-opacity-60`}
                            >
                              {res?.data?.deliveryDetails?.dropoffDate}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-2xl flex items-center gap-x-6">
                      <img
                        className="w-8"
                        src={`${liveImgURL}/Vector.webp`}
                        alt="Delivery Details"
                      />
                      Parcel Details
                    </div>
                    <div>
                      <div className="rounded-lg py-4 space-y-4">
                        <div className="flex justify-between">
                          <h6 className="text-lg font-normal text-black text-opacity-60">
                            Type of shipment
                          </h6>
                          <p className="text-lg font-medium">
                            {res?.data?.parcelDetails.shipmentType || (
                              <Skeleton />
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <h6 className="text-lg font-normal text-black text-opacity-60">
                            Category
                          </h6>
                          <p className="text-lg font-medium">
                            {res?.data?.parcelDetails.category || <Skeleton />}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <h6 className="text-lg font-normal text-black text-opacity-60">
                            Size
                          </h6>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: res?.data?.parcelDetails.size,
                            }}
                            className="text-lg font-medium"
                          />
                        </div>
                        <div className="flex justify-between">
                          <h6 className="text-lg font-normal text-black text-opacity-60">
                            Weight
                          </h6>
                          <p className="text-lg font-medium">
                            {res?.data?.parcelDetails.weight || <Skeleton />}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <h6 className="text-lg font-normal text-black text-opacity-60">
                            Distance
                          </h6>
                          <p className="text-lg font-medium">
                            {res?.data?.parcelDetails.distance || <Skeleton />}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <h6 className="text-lg font-normal text-black text-opacity-60">
                            Pickup Date
                          </h6>
                          <p className="text-lg font-medium">
                            {res?.data?.parcelDetails.pickupDate || (
                              <Skeleton />
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <h6>Total Amount</h6>
                          <p>
                            {res?.data?.parcelDetails.orderTotal || (
                              <Skeleton />
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                    <h3 className="text-2xl">
                      Receiving Driver <hr />
                    </h3>
                    {Object.keys(res.data?.receivingDriver).length !== 0 ? (
                      <>
                        <div className="grid grid-cols-12 items-center">
                          <div className="col-span-2">
                            <img
                              className="w-16 h-16 rounded-fullest"
                              src={`https://backend.pps507.com/${
                                res?.data?.receivingDriver?.image
                                  ? res?.data?.receivingDriver?.image
                                  : "Public/Images/Profile/default.png"
                              }`}
                              alt="driver"
                            />
                          </div>
                          <div className="col-span-10 ml-4">
                            <h3 className="text-themePersian text-xl font-bold">
                              {res?.data?.receivingDriver?.name}
                            </h3>
                            <p className="text-sm font-normal text-[#20205399]">
                              Member Since:{" "}
                              {res?.data?.receivingDriver?.memberSince}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-x-4">
                            <p className="text-base font-normal text-[#20205399]">
                              Email
                            </p>
                            <p className="text-base font-normal">
                              {res?.data?.receivingDriver?.email}
                            </p>
                          </div>
                          <div className="flex gap-x-4">
                            <p className="text-base font-normal text-[#20205399]">
                              Phone no
                            </p>
                            <p className="text-base font-normal">
                              {res?.data?.receivingDriver?.phone}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        <div className="bg-themeYellow bg-opacity-50 text-lg rounded-lg inline px-10 py-2 ">
                          Not Assigned Yet
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                    <h3 className="text-2xl mb-2">
                      Transporter Guy <hr />{" "}
                    </h3>
                    {Object.keys(res.data?.transporterGuy).length !== 0 ? (
                      <>
                        <div className="grid grid-cols-12 items-center">
                          <div className="col-span-2">
                            <img
                              className="w-16 h-16  rounded-fullest"
                              src={`https://backend.pps507.com/${
                                res?.data?.transporterGuy?.image
                                  ? res?.data?.transporterGuy?.image
                                  : "Public/Images/Profile/default.png"
                              }`}
                              alt="driver"
                            />
                          </div>
                          <div className="col-span-10 ml-5">
                            <h3 className="text-themePersian text-xl font-bold">
                              {res?.data?.transporterGuy?.name}
                            </h3>
                            <p className="text-sm font-normal text-[#20205399]">
                              Member Since:{" "}
                              {res?.data?.transporterGuy?.memberSince}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-x-4">
                            <p className="text-base font-normal text-[#20205399]">
                              Email
                            </p>
                            <p className="text-base font-normal">
                              {res?.data?.transporterGuy?.email}
                            </p>
                          </div>
                          <div className="flex gap-x-4">
                            <p className="text-base font-normal text-[#20205399]">
                              Phone no
                            </p>
                            <p className="text-base font-normal">
                              {res?.data?.transporterGuy?.phone}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        <div className=" bg-themeYellow bg-opacity-50 text-lg rounded-lg inline px-10 py-2 ">
                          Not Assigned Yet{" "}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                    <h3 className="text-2xl">
                      Delivery Driver
                      <hr />
                    </h3>
                    {Object.keys(res.data?.deliveryDriver).length !== 0 ? (
                      <>
                        <div className="grid grid-cols-12 items-center">
                          <div className="col-span-2">
                            <img
                              className="w-16 h-16  rounded-fullest"
                              src={`https://backend.pps507.com/${
                                res?.data?.deliveryDriver?.image
                                  ? res?.data?.deliveryDriver?.image
                                  : "Public/Images/Profile/default.png"
                              }`}
                              alt="driver"
                            />
                          </div>
                          <div className="col-span-10 ml-4">
                            <h3 className="text-themePersian text-xl font-bold">
                              {res?.data?.deliveryDriver?.name}
                            </h3>
                            <p className="text-sm font-normal text-[#20205399]">
                              Member Since:{" "}
                              {res?.data?.deliveryDriver?.memberSince}
                            </p>
                          </div>
                        </div>
                        <div className="mt-5">
                          <div className="flex gap-x-5">
                            <p className="text-base font-normal text-[#20205399]">
                              Email
                            </p>
                            <p className="text-base font-normal">
                              {res?.data?.deliveryDriver?.email}
                            </p>
                          </div>
                          <div className="flex gap-x-4">
                            <p className="text-base font-normal text-[#20205399]">
                              Phone no
                            </p>
                            <p className="text-base font-normal">
                              {res?.data?.deliveryDriver?.phone}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        <div className="bg-themeYellow bg-opacity-50 text-lg rounded-lg inline px-10 py-2 ">
                          Not Assigned Yet
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        }
      />
    </>
  );
}
