// @ts-nocheck
import React, { useContext } from "react";
import { ExpandContext } from "./Layout";

export default function MainSection(props) {
  const { expand } = useContext(ExpandContext);
  return (
    <>
      <section
        className={`text-2xl font-bold float-right absolute top-16 right-0 px-10 2xl:px-16 min-h-screen py-3 bg-[#F4F7FF]  
        ${!expand ? "md:w-[calc(100%-256px)]" : "w-full"}`}
      >
        <h1 className="my-3">{props.pageTitle}</h1>
        {props.extend && props.data}
      </section>
    </>
  );
}
