import React from "react";

export default function SucessPayment() {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="h-[90%] w-[90%] rounded-xl bg-themePersian flex items-center justify-center">
        <h1 className=" text-2xl font-bold text-teal-50">Payment Sucessfull</h1>
      </div>
    </div>
  );
}
