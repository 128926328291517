// @ts-nocheck
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { liveImgURL } from "../../style/Style";
import Layout from "../layout/Layout";
import { info_toaster } from "../../toaster/Toaster";
import { Tooltip } from "@chakra-ui/react";
import Select from "react-select";
import GetAPI from "../../api/GetAPI";

export default function CreateOrder1() {
  const navigate = useNavigate();
  const { getData } = GetAPI("bookingdata");
  const [order, setOrder] = useState({
    bookingTypeId: "",
    bookingCheck: false,
    setScheduleBy: "",
    instruction: "",
    shipmentTypeId: "",
    sizeId: "",
    categoryId: "",
    catCheck: false,
    catText: "",
  });
  const onChange = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };
  const checkOne = (e) => {
    e.preventDefault();
    if (order.bookingTypeId === "") {
      info_toaster("Please select Booking Type");
    } else if (order.bookingCheck === true && order.setScheduleBy === "") {
      info_toaster("Please select Schedule Type");
    } else if (order.shipmentTypeId === "") {
      info_toaster("Please select Shipment Type");
    } else if (order.sizeId === "") {
      info_toaster("Please select Size");
    } else if (order.catCheck === true && order.catText === "") {
      info_toaster("Please enter Category Text");
    } else if (order.categoryId === "") {
      info_toaster("Please select Category");
    } else {
      navigate("/create_order/step_two", { state: { order: order, warehouses: getData?.data?.allActiveWarehouses } });
    }
  };
  const options = [];
  getData?.data?.categoryIds.map((ele, key) => {
    return options.push({
      value: ele?.id,
      label: ele?.title,
    });
  });

  const style = "input[type='radio']:checked+label {border-color:red;}";
  return (
    <Layout
      extend={true}
      data={
        <>
          <style>{style}</style>
          <section className="bg-white rounded-md my-5 md:px-16 px-4 py-5">
            <div className="xl:grid xl:grid-cols-2 space-x-2">
              <div className="w-full md:11/12 lg:11/12 xl:w-11/12 2xl:w-4/5">
                <div>
                  <h2 className="text-2xl font-medium">
                    Parcel Detail (Booking Type ID)
                  </h2>
                  <div>
                    {getData?.data?.bookingTypeIds.map((ele, key) => (
                      <>
                        <input
                          value={ele?.id}
                          onChange={(e) => {
                            setOrder({
                              ...order,
                              bookingTypeId: e.target.value,
                            });
                            e.target.value === "2"
                              ? setOrder({
                                  ...order,
                                  setScheduleBy: "reciever",
                                  bookingTypeId: e.target.value,
                                  bookingCheck: false,
                                })
                              : setOrder({
                                  ...order,
                                  setScheduleBy: "",
                                  bookingTypeId: e.target.value,
                                  bookingCheck: true,
                                });
                          }}
                          id={`booking${ele?.id}`}
                          type="radio"
                          name="bookingTypeId"
                          className="hidden"
                        />
                        <label
                          className="flex bg-themeGrey rounded-xl border border-transparent cursor-pointer my-5 py-8 px-5 gap-x-4"
                          htmlFor={`booking${ele?.id}`}
                        >
                          <div>
                            <img
                              className="w-9"
                              src={`${liveImgURL}/delivery.webp`}
                              alt="delivery"
                            />
                          </div>
                          <div>
                            <h3 className="text-xl font-medium">{ele.title}</h3>
                            <h5 className="text-base font-normal">
                              {ele.description}
                            </h5>
                          </div>
                        </label>
                      </>
                    ))}
                  </div>
                  {order.bookingTypeId === "1" && (
                    <>
                      <h6 className="text-xl font-bold">
                        Who will schedule the receiving date and time?
                      </h6>
                      <div className="flex md:flex-row flex-col  w-full gap-x-3">
                        <div className="w-full relative">
                          <input
                            value="sender"
                            onChange={onChange}
                            id="sender"
                            type="radio"
                            name="setScheduleBy"
                            className="absolute top-1/2 right-10 -translate-y-1/2"
                          />
                          <label
                            className="flex bg-themeGrey rounded-xl border border-transparent cursor-pointer my-5 p-4 gap-x-4"
                            htmlFor="sender"
                          >
                            <div>
                              <h3 className="text-xl font-medium">Sender</h3>
                            </div>
                          </label>
                        </div>
                        <div className="w-full relative">
                          <input
                            value="receiver"
                            onChange={onChange}
                            id="receiver"
                            type="radio"
                            name="setScheduleBy"
                            className="absolute top-1/2 right-10 -translate-y-1/2"
                          />
                          <label
                            className="flex bg-themeGrey rounded-xl border border-transparent cursor-pointer my-5 p-4 gap-x-4"
                            htmlFor="receiver"
                          >
                            <div>
                              <h3 className="text-xl font-medium">Receiver</h3>
                            </div>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <h2 className="text-xl font-bold">Type of shipment</h2>
                  <div className="flex justify-between gap-x-2 text-center">
                    {getData?.data?.shipmentTypeIds.map((ele, key) => (
                      <>
                        <input
                          className="hidden"
                          id={`shipment${ele?.id}`}
                          type="radio"
                          name="shipmentTypeId"
                          value={ele?.id}
                          onChange={onChange}
                        />
                        <Tooltip
                          label={ele?.description}
                          aria-label="A tooltip"
                        >
                          <label
                            className="relative flex-auto w-[50%] bg-themeGrey rounded-xl border border-transparent cursor-pointer space-y-3 my-5 py-8 px-5"
                            htmlFor={`shipment${ele?.id}`}
                          >
                            <div>
                              <img
                                className="w-9 m-auto"
                                src={`${liveImgURL}/flash.webp`}
                                alt="flash"
                              />
                            </div>
                            <div>
                              <h3 className="text-xl font-medium">
                                {ele.title}
                              </h3>
                            </div>
                          </label>
                        </Tooltip>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-full md:w-11/12 lg:w-11/12 space-y-6">
                <div>
                  <h1 className="text-sm font-normal">Choose a size</h1>
                  <div className="flex flex-wrap gap-x-2 text-center">
                    {getData?.data?.sizeIds.map((ele, key) => (
                      <>
                        <p className="hidden">{key}</p>
                        <input
                          className="hidden"
                          type="radio"
                          id={`size${ele.id}`}
                          name="sizeId"
                          onChange={onChange}
                          value={ele.id}
                        />
                        <label
                          className="w-[30%] bg-themeGrey rounded-xl border border-transparent cursor-pointer space-y-3 my-5 py-8 px-2 hover:border hover:border-themeOrange"
                          htmlFor={`size${ele.id}`}
                        >
                          <div>
                            <img
                              className="w-9 m-auto"
                              src={`${liveImgURL}/box.webp`}
                              alt="SmallBox"
                            />
                          </div>
                          <div>
                            <h3 className="text-xl font-medium">{ele.title}</h3>
                            <h3 className="text-xs font-normal text-black text-opacity-40">
                              Max. weight: {ele.weight} {ele.weightUnitS.symbol}
                            </h3>
                            <h3 className="text-xs font-normal text-black text-opacity-40">
                              Max. size: {ele.length} x {ele.width} x{" "}
                              {ele.height} {ele.lengthUnitS.symbol}
                            </h3>
                          </div>
                        </label>
                      </>
                    ))}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="instruction"
                    className="block mb-2 text-sm font-medium text-black text-opacity-60"
                  >
                    Order instructions
                  </label>
                  <textarea
                    name="instruction"
                    id="instruction"
                    onChange={onChange}
                    value={order.instruction}
                    rows="8"
                    className="block p-2.5 w-full text-sm text-black bg-themeGrey rounded-lg focus:outline-none"
                    placeholder="Your notes here..."
                  ></textarea>
                </div>
                <div>
                  <label
                    htmlFor="categoryId"
                    className="block mb-2 text-sm font-medium text-black text-opacity-60"
                  >
                    Parcel Parameter
                  </label>

                  <Select
                    value={order.categoryId}
                    onChange={(e) => {
                      setOrder({
                        ...order,
                        categoryId: e,
                      });
                      e.label === "Other"
                        ? setOrder({ ...order, categoryId: e, catCheck: true })
                        : setOrder({
                            ...order,
                            categoryId: e,
                            catCheck: false,
                          });
                    }}
                    options={options}
                    inputId="categoryId"
                  />
                </div>
                {order.catCheck && (
                  <div>
                    <label
                      htmlFor="OtherDetails"
                      className="block mb-2 text-sm font-medium text-black text-opacity-60"
                    >
                      Other Category
                    </label>
                    <textarea
                      name="catText"
                      id="OtherDetails"
                      onChange={onChange}
                      value={order.catText}
                      rows="1"
                      className="block p-2.5 w-full text-sm text-black bg-themeGrey rounded-lg focus:outline-none"
                      placeholder="Define other details ..."
                    ></textarea>
                  </div>
                )}
              </div>
            </div>
            <div className="flex md:justify-end justify-center my-12">
              <button
                onClick={checkOne}
                className="bg-themePersian text-white text-sm font-medium cursor-pointer rounded-md px-16 py-3 hover:text-themePersian hover:bg-transparent hover:border hover:border-themePersian "
              >
                Next
              </button>
            </div>
          </section>
        </>
      }
    />
  );
}
