// @ts-nocheck
import { useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
export default function Card(props) { 
  const [sm] = useMediaQuery("(min-width: 800px)");
  const { Icon } = props;
  return (
    <div>
      <div className="flex items-center bg-white lg:px-6 px-3 lg:py-4 py-2 rounded-lg space-y-2  md:h-[152px] h-[150px] w-full">
        <div className="">
          <h3 className="text-black lg:text-xl text-lg font-medium text-opacity-60 mb-3">
            {props.title}
          </h3>
          <div className="flex items-center lg:gap-x-5 gap-x-2">
            <div
              className={`text-white ${props.bgColor} rounded-md lg:p-3 p-2`}
            >
              <Icon size={sm ? 32 : 20} />
            </div>
            <h1 className={`lg:text-4xl text-2xl font-bold ${props.textColor}`}>
              {props.quantity}
            </h1>
          </div>
          <h6 className="hidden lg:justify-end justify-start items-center text-base font-normal text-black text-opacity-60 h-5">
            {" "}
            {props.variation === "up" && (
              <IoMdArrowDropup
                className="text-themeGreen"
                size={sm ? 50 : 30}
              />
            )}{" "}
            {props.variation === "down" && (
              <IoMdArrowDropdown
                className="text-themeRed"
                size={sm ? 50 : 30}
              />
            )}{" "}
            <span>
              {props.percentage} {props.variation}
            </span>
          </h6>
        </div>
      </div>
    </div>
  );
}
