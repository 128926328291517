// @ts-nocheck
import React, { createContext, useState } from "react";
import Leftbar from "./Leftbar";
import MainSection from "./MainSection";
import Topbar from "./Topbar";

export const ExpandContext = createContext(null);
export default function Layout(props) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <ExpandContext.Provider
        value={{ expand, setExpand }}
      >
      <Leftbar />
      <Topbar />
      <MainSection
        pageTitle={props.pageTitle}
        data={props.data}
        extend={props.extend}
      />
      </ExpandContext.Provider>
    </>
  );
}
