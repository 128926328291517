import React, { useEffect, useState } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { FaArrowDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { liveImgURL } from "../../style/Style";
import Layout from "../layout/Layout";
import IncomingDetailsStatus from "./allIncomingElements/IncomingDetailsStatus";
import { IMG_URL } from "../../api/API";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import moment from "moment";
import QRCode from "react-qr-code";

export default function AllIncomingDetail() {
  const location = useLocation();
  const [modal, setModal] = useState(false);
  console.log(location?.state?.res?.data);

  const handlePrint = () => {
    const sectionToPrint = document.getElementById("sectionToPrint");
    if (sectionToPrint) {
      const printContents = sectionToPrint.innerHTML;

      const newWindow = window.open();
      newWindow.document.write(`<!DOCTYPE html>
      <html lang="en">
   <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
    <title>Print Coupon</title>
    </head>
   <body>`);
      newWindow.document.write(printContents);
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      setTimeout(() => {
        newWindow.print();
        setModal(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (modal) {
      setTimeout(handlePrint, 500);
    }
  }, [modal]);

  return (
    <>
      <Layout
        pageTitle="Packages > Order Details"
        extend={true}
        data={
          <>
            <div className="flex justify-between items-center">
              <button
                onClick={() => window.history.back()}
                className="flex items-center gap-x-2 font-medium text-base text-white bg-themePersian 
                py-2.5 px-5 border border-themePersian rounded hover:bg-transparent
                 hover:text-themePersian"
              >
                <ImArrowLeft2 size={18} /> Back
              </button>
              <div>
                <button
                  onClick={() => setModal(true)}
                  className="flex items-center gap-x-2 font-medium text-base text-white bg-themePersian 
                  py-2.5 px-5 border border-themePersian rounded hover:bg-transparent
                   hover:text-themePersian"
                >
                  Print
                </button>
              </div>
            </div>
            <IncomingDetailsStatus
              data={location?.state?.res?.data}
              bookingStatusId={location?.state?.bookingStatusId}
            />
            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 my-10 gap-5">
              <div className="row-span-3 font-medium bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                <h3 className="text-2xl">
                  Order#
                  <span className="text-[#00000066]">
                    {location?.state?.res?.data?.trackingId}
                  </span>
                </h3>
                <hr />
                <h3 className="text-2xl">
                  Scheduled By:
                  <span className="text-[#00000066] capitalize">
                    {location?.state?.res?.data?.scheduleSetBy}
                  </span>
                </h3>
                <hr />
                <div>
                  <h3 className="text-2xl">Sender Details</h3>
                  <h3 className="text-themePersian text-xl font-bold">
                    {location?.state?.res?.data?.senderDetails?.name}
                  </h3>
                  <p className="text-sm font-normal text-[#20205399]">
                    Member Since :
                    {location.state?.res?.data?.senderDetails?.memberSince}
                  </p>
                  <p className="text-base font-normal text-[#20205399]">
                    Email
                  </p>
                  <p className="text-base font-normal">
                    {location.state?.res?.data?.senderDetails?.email}
                  </p>
                  <p className="text-base font-normal text-[#20205399]">
                    Phone no
                  </p>
                  <p className="text-base font-normal">
                    {location.state?.res?.data?.senderDetails?.phone}
                  </p>
                </div>
                <div>
                  <h3 className="text-2xl">Recipient Details</h3>
                  <h3 className="text-themePersian text-xl font-bold">
                    {location.state?.res?.data?.recipientDetails?.name}
                  </h3>
                  <p className="text-base font-normal text-[#20205399]">
                    Email
                  </p>
                  <p className="text-base font-normal">
                    {location?.state?.res?.data?.recipientDetails?.email}
                  </p>
                  <p className="text-base font-normal text-[#20205399]">
                    Phone no
                  </p>
                  <p className="text-base font-normal">
                    {location.state?.res?.data?.recipientDetails?.phone}
                  </p>
                </div>
                <hr />
                <div className="space-y-2">
                  <div>
                    <h3 className="text-xl"> Order Instruction</h3>
                    {location.state.res?.data?.instructions ? (
                      <>
                        <div className="text-base font-normal text-[#20205399]">
                          {location.state.res.data?.instructions}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-base font-normal text-[#20205399]">
                          No Instruction
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <h3 className="text-xl">Leave At Door</h3>

                    <div className="text-base font-normal text-[#20205399]">
                      {location.state.res.data?.leaveAtDoor ? "True" : "False"}
                    </div>
                  </div>
                  <div>
                    <h3 className="text-xl">Security Code</h3>

                    <div className="text-base font-normal text-[#20205399]">
                      {location.state.res.data?.securityCode
                        ? location.state.res.data?.securityCode
                        : "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="text-xl">Pod Image</span>
                    {location.state.res.data?.podImage ? (
                      <img
                        src={`${IMG_URL}${location.state.res.data?.podImage}`}
                        alt=""
                      />
                    ) : (
                      <p className="font-normal text-base">N/A</p>
                    )}
                  </div>
                  <div>
                    <span className="text-xl">Signature Image</span>
                    {location.state.res.data?.signatureImage ? (
                      <img
                        src={`${IMG_URL}${location.state.res.data?.signatureImage}`}
                        alt=""
                      />
                    ) : (
                      <p className="font-normal text-base">N/A</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row-span-3 bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                <div className="text-2xl flex items-center gap-x-6">
                  <img
                    className="w-9"
                    src={`${liveImgURL}/delivery.webp`}
                    alt="Delivery Details"
                  />
                  Delivery Details
                </div>

                <div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-1">
                      <div className="flex flex-col items-center justify-start">
                        <img
                          className="w-5"
                          src={`${liveImgURL}/round.webp`}
                          alt="rounded"
                        />
                        <img
                          className="h-16"
                          src={`${liveImgURL}/line.webp`}
                          alt="rounded"
                        />
                        <img
                          className="w-5"
                          src={`${liveImgURL}/rectangle.webp`}
                          alt="rounded"
                        />
                      </div>
                    </div>
                    <div className="col-span-11 flex flex-col justify-between space-y-4">
                      <div>
                        <h3 className="text-base font-medium">Pickup</h3>
                        <h3 className="text-base font-medium  text-black text-opacity-60">
                          {location.state.res?.data?.deliveryDetails
                            .pickupCode || <Skeleton />}
                        </h3>
                      </div>
                      <div className="flex gap-x-16">
                        <div>
                          <h3 className="text-base font-medium text-opacity-60">
                            Drop-off
                          </h3>
                          <h3 className="text-base font-medium text-black text-opacity-60">
                            {location.state.res?.data?.deliveryDetails
                              .dropoffCode || <Skeleton />}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-2xl flex items-center gap-x-6">
                  <img
                    className="w-8"
                    src={`${liveImgURL}/Vector.webp`}
                    alt="Delivery Details"
                  />
                  Parcel Details
                </div>
                <div>
                  <div className="rounded-lg py-4 space-y-4">
                    <div className="flex justify-between">
                      <h6 className="text-lg font-normal text-black text-opacity-60">
                        Type of shipment
                      </h6>
                      <p className="text-lg font-medium">
                        {
                          location?.state?.res?.data?.parcelDetails
                            ?.shipmentType
                        }
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h6 className="text-lg font-normal text-black text-opacity-60">
                        Booking Type
                      </h6>
                      <p className="text-lg font-medium">
                        {
                          location?.state?.res?.data?.bookingType
                            ?.title
                        }
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h6 className="text-lg font-normal text-black text-opacity-60">
                        Category
                      </h6>
                      <p className="text-lg font-medium">
                        {location?.state?.res?.data?.parcelDetails?.category}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h6 className="text-lg font-normal text-black text-opacity-60">
                        Size
                      </h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            location?.state?.res?.data?.parcelDetails?.size,
                        }}
                        className="text-lg font-medium"
                      />
                    </div>
                    <div className="flex justify-between">
                      <h6 className="text-lg font-normal text-black text-opacity-60">
                        Weight
                      </h6>
                      <p className="text-lg font-medium">
                        {location?.state?.res?.data?.parcelDetails?.weight}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h6 className="text-lg font-normal text-black text-opacity-60">
                        Distance
                      </h6>
                      <p className="text-lg font-medium">
                        {location.state.res.data.parcelDetails.distance || (
                          <Skeleton />
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h6 className="text-lg font-normal text-black text-opacity-60">
                        Pickup Date
                      </h6>
                      <p className="text-lg font-medium">
                        {location.state.res.data.parcelDetails.pickupDate || (
                          <Skeleton />
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h6>Total Amount</h6>
                      <p>
                        {location.state.res.data.parcelDetails.orderTotal || (
                          <Skeleton />
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                <h3 className="text-2xl">Receiving Driver</h3>
                {Object.keys(location.state.res.data?.receivingDriver)
                  .length !== 0 ? (
                  <>
                    <div className="grid grid-cols-12 items-center">
                      <div className="col-span-2">
                        <img
                          className="w-16 h-16 rounded-fullest"
                          src={`https://backend.pps507.com/${location.state.res.data?.receivingDriver?.image}`}
                          alt="driver"
                        />
                      </div>
                      <div className="col-span-10 ml-4">
                        <h3 className="text-themePersian text-xl font-bold">
                          {location.state.res.data?.receivingDriver?.name}
                        </h3>
                        <p className="text-sm font-normal text-[#20205399]">
                          Member Since:{" "}
                          {
                            location.state.res.data?.receivingDriver
                              ?.memberSince
                          }
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-base font-normal text-[#20205399]">
                        Email
                      </p>
                      <p className="text-base font-normal">
                        {location.state.res.data?.receivingDriver?.email}
                      </p>
                      <p className="text-base font-normal text-[#20205399]">
                        Phone no
                      </p>
                      <p className="text-base font-normal">
                        {location.state.res.data?.receivingDriver?.phone}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="bg-themeYellow bg-opacity-50 text-lg rounded-lg inline px-10 py-2 ">
                        Not Assigned Yet
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                <h3 className="text-2xl">Transporter Guy</h3>
                {Object.keys(location.state.res.data?.transporterGuy).length !==
                0 ? (
                  <>
                    <div className="grid grid-cols-12 items-center">
                      <div className="col-span-2">
                        <img
                          className="w-16 h-16  rounded-fullest"
                          src={`https://backend.pps507.com/${
                            location.state.res.data?.transporterGuy?.image
                              ? location.state.res.data?.transporterGuy?.image
                              : "Public/Images/Profile/default.png"
                          }`}
                          alt="driver"
                        />
                      </div>
                      <div className="col-span-10 ml-5">
                        <h3 className="text-themePersian text-xl font-bold">
                          {location.state.res.data?.transporterGuy?.name}
                        </h3>
                        <p className="text-sm font-normal text-[#20205399]">
                          Member Since:{" "}
                          {location.state.res.data?.transporterGuy?.memberSince}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-base font-normal text-[#20205399]">
                        Email
                      </p>
                      <p className="text-base font-normal">
                        {location.state.res.data?.transporterGuy?.email}
                      </p>
                      <p className="text-base font-normal text-[#20205399]">
                        Phone no
                      </p>
                      <p className="text-base font-normal">
                        {location.state.res.data?.transporterGuy?.phone}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="bg-themeYellow bg-opacity-50 text-lg rounded-lg inline px-10 py-2 ">
                        Not Assigned Yet
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                <h3 className="text-2xl">Delivery Driver</h3>
                {Object.keys(location.state.res.data?.deliveryDriver).length !==
                0 ? (
                  <>
                    <div className="grid grid-cols-12 items-center">
                      <div className="col-span-2">
                        <img
                          className="w-16 h-16  rounded-fullest"
                          src={`https://backend.pps507.com/${
                            location.state.res.data?.deliveryDriver?.image
                              ? location.state.res.data?.deliveryDriver?.image
                              : "Public/Images/Profile/default.png"
                          }`}
                          alt="driver"
                        />
                      </div>
                      <div className="col-span-10 ml-4">
                        <h3 className="text-themePersian text-xl font-bold">
                          {location.state.res.data?.deliveryDriver?.name}
                        </h3>
                        <p className="text-sm font-normal text-[#20205399]">
                          Member Since:{" "}
                          {location.state.res.data?.deliveryDriver?.memberSince}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-base font-normal text-[#20205399]">
                        Email
                      </p>
                      <p className="text-base font-normal">
                        {location.state.res.data?.deliveryDriver?.email}
                      </p>
                      <p className="text-base font-normal text-[#20205399]">
                        Phone no
                      </p>
                      <p className="text-base font-normal">
                        {location.state.res.data?.deliveryDriver?.phone}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <div className="bg-themeYellow bg-opacity-50 text-lg rounded-lg inline px-10 py-2 ">
                        Not Assigned Yet
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <Modal
              onClose={() => {
                setModal(false);
              }}
              isOpen={modal}
              isCentered
              size={"3xl"}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody paddingTop={5} paddingBottom={5}>
                  <div
                    id="sectionToPrint"
                    style={{ width: "80%", margin: "auto" }}
                  >
                    <div className="border-2 border-black m-10">
                      <div className="grid grid-cols-7">
                        <div
                          className="text-7xl flex items-center font-bold p-4 border-black border-r-2 
                col-span-1"
                        >
                          E
                        </div>
                        <div className="col-span-6 p-4 flex flex-col gap-2">
                          <div className="flex justify-end text-xs font-semibold">
                            panamapostal.com
                          </div>
                          <div className="text-sm font-semibold">
                            <h2>
                              Send:{" "}
                              {location?.state?.res?.data?.senderDetails?.name}
                            </h2>
                            <p>
                              From:{" "}
                              {
                                location?.state?.res?.data?.deliveryDetails
                                  ?.pickupCode
                              }
                            </p>
                          </div>
                          <div className="flex justify-center text-sm font-semibold">
                            Date:{" "}
                            {moment(
                              location?.state?.res?.data?.createdAt
                            ).format("DD-MM-YYYY")}
                          </div>
                          {/* <div>
                    <img
                      src="/images/qrcode.webp"
                      alt="qrcode"
                      className="w-full h-12 object-contain"
                    />
                  </div> */}
                        </div>
                      </div>
                      <div className="border-black border-t-2 border-b-2 py-3">
                        <div className="text-xl font-semibold uppercase text-center">
                          {
                            location?.state?.res?.data?.parcelDetails
                              ?.shipmentType
                          }{" "}
                          <sup className="uppercase text-sm">Tm</sup>
                        </div>
                      </div>
                      <div className="grid grid-cols-4 p-4">
                        <div className="col-span-3 space-y-5">
                          <div>
                            <div className="flex gap-5 text-sm font-semibold">
                              <div>Sending Warehouse:</div>
                              <div>
                                <div>{localStorage.getItem("name")}</div>
                                <div className="flex justify-center my-2">
                                  <FaArrowDown />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-5 text-sm font-semibold">
                              <div>Receiving Warehouse: </div>
                              <div>
                                {
                                  location?.state?.res?.data?.deliveryWarehouse
                                    ?.name
                                }
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-5 text-sm font-semibold">
                            <div>Receives: </div>
                            <div className="flex flex-col">
                              <div className="capitalize">
                                {
                                  location?.state?.res?.data?.recipientDetails
                                    ?.name
                                }
                              </div>
                              <div>
                                {
                                  location?.state?.res?.data?.deliveryDetails
                                    ?.dropoffCode
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <img src="/images/logo-gray.webp" alt="logo-gray" />
                        </div>
                      </div>
                      <div className="border-black border-t-2 border-b-2 py-3 flex flex-col items-center gap-2">
                        <h2 className="text-xl font-semibold uppercase text-center">
                          Pps Tracking #
                        </h2>
                        <QRCode
                          size={256}
                          style={{
                            height: "80px",
                            width: "80px",
                          }}
                          value={location?.state?.res?.data?.qrCode}
                          viewBox={`0 0 256 256`}
                        />
                        <div className="text-sm text-center">
                          {location?.state?.res?.data?.trackingId}
                        </div>
                      </div>
                      <div className="p-4 text-xl font-semibold uppercase text-center">
                        {
                          location?.state?.res?.data?.deliveryDetails
                            ?.dropoffCode
                        }
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        }
      />
    </>
  );
}
