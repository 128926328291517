// @ts-nocheck
import React, { useEffect, useState } from "react";
import OrderCreatedCard from "../allincoming/allIncomingElements/OrderCreatedCard";
import axios from "../../api/API";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import Layout from "../layout/Layout";

export default function SelfPickupWaiting() {
  const [resData, setResData] = useState();
  const [searchUniversal, setSearchUniversal] = useState("");
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/warehouse/selfpickupbookings", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setResData(res.data);
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    getMyPostData();
  }, []);
  return (
    <>
      <Helmet>
        <title>PPS - Alloutgoing</title>
      </Helmet>
      <Layout
        extend={true}
        data={
          <section>
            <h1>Self Pickup Waiting</h1>
            <div className="grid md:grid-cols-3 gap-4 mt-2">
              <input
                value={searchUniversal}
                onChange={(e) => setSearchUniversal(e.target.value)}
                className="text-base font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none"
                type="text"
                placeholder="Search"
              />
            </div>
            <div className="mt-8">
              {!resData?.data ? (
                <div className="grid grid-cols-12 bg-white p-5 rounded-lg my-8">
                  <div className="col-span-10 space-y-3">
                    <div className="flex items-center gap-x-2">
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                      <div className="w-48">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-1">
                        <div className="w-5 m-auto">
                          <Skeleton count={3} />
                        </div>
                      </div>
                      <div className="col-span-11 flex flex-col justify-between space-y-4">
                        <div>
                          <h3 className="w-28 text-base font-medium text-black text-opacity-60">
                            <Skeleton />
                          </h3>
                        </div>
                        <div className="flex gap-x-16">
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 gap-x-5 flex flex-col items-end justify-between">
                    <div>
                      <div className="space-x-1 w-20">
                        <span>
                          <Skeleton />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {resData?.data?.waiting?.map((ele, key) => {
                    return searchUniversal === "" ? (
                      <OrderCreatedCard
                        apiEle={ele}
                        badge="Waiting"
                        bg="bg-themeYellow"
                      />
                    ) : (
                      searchUniversal.length > 0 &&
                        (
                          ele?.id?.toString()?.match(searchUniversal) ||
                          ele?.trackingId
                            ?.toLowerCase()
                            ?.match(searchUniversal.toLowerCase()) ||
                          ele?.shipmentType?.title
                            ?.toLowerCase()
                            .match(searchUniversal.toLowerCase()) ||
                          ele?.bookingStatus?.title
                            ?.toLowerCase()
                            .match(searchUniversal.toLowerCase()) ||
                          ele?.deliveryWarehouse?.name
                            ?.toLowerCase()
                            .match(searchUniversal.toLowerCase())
                        )(
                          <OrderCreatedCard
                            apiEle={ele}
                            badge="Waiting"
                            bg="bg-themeYellow"
                          />
                        )
                    );
                  })}
                </div>
              )}
            </div>
          </section>
        }
      />
    </>
  );
}
