// @ts-nocheck
import React, { useContext } from "react";
import { ExpandContext } from "./Layout";
import { ImArrowLeft2, ImArrowRight2 } from "react-icons/im";
import { Link } from "react-router-dom";

export default function Topbar() {
  const { expand, setExpand } = useContext(ExpandContext);
  return (
    <section
      className={`md:absolute fixed z-50 top-0 right-0 float-right lg:px-10 px-4 py-1 bg-white border-b border-b-black border-opacity-20 h-[68px] ${expand ? "w-full" : "w-[calc(100%-256px)]"}`}
    >
      <div className="flex justify-between">
        <button className="relative z-50"  onClick={() => setExpand(!expand)}>
          {expand ? <ImArrowRight2 size={24} /> : <ImArrowLeft2 size={24} />}
        </button>  
        <div className="flex items-center gap-3 cursor-pointer">
          <Link to="/warehouse_profile" className=" capitalize"> <span className=" text-lg font-semibold">{localStorage.getItem("name")}</span> <br /> ({localStorage.getItem("address")})   </Link> 
         </div>     
      </div>
    </section>
  );
}
