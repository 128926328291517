import React from 'react'
import { Link } from "react-router-dom";
import AuthSide from "./AuthSide";

export default function Signup() {
  const inputStyle = 'text-black bg-white rounded-md border-none font-normal text-base focus:outline-none py-3 px-5 w-full placeholder:text-black placeholder:text-opacity-40'
  return (
    <section className="h-[100vh] bg-themePersian bg-auth bg-no-repeat bg-cover flex items-center text-white">
      <div className="grid grid-cols-2 w-1/2 mx-auto">
        <AuthSide />
        <div className="flex flex-col items-center justify-center p-8 gap-y-16 w-10/12 mx-auto">
          <h1 className="font-bold text-3xl">Sign Up</h1>
          <div className="w-full space-y-5">
            <div className="space-y-3">
              <input className={inputStyle} type="text" placeholder="Email" />
              <input className={inputStyle} type="password" placeholder="Create Password" />
              <input className={inputStyle} type="password" placeholder="Confirm Password" />
            </div>
            <div>
              <Link to="/signin" className="block text-center bg-themeOrange rounded-md font-medium text-xl border border-themeOrange py-3 w-full hover:bg-transparent hover:text-themeOrange">Sign up</Link>
            </div>
          </div>
          <div className="w-full text-center">
            {/* <div className="text-center text-base font-normal">
            <Link to={'/signin'}>Don't have a PPS account?</Link>
          </div> */}
            <div >
              <Link to={'/signin'} className="block text-opacity-60 bg-transparent rounded-md font-normal text-lg border border-white border-opacity-60 py-3 w-full hover:bg-white hover:text-themePersian">Already have a PPS account?</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
