// @ts-nocheck
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import OrderCreatedCard from "./allIncomingElements/OrderCreatedCard";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import GetAPI from "../../api/GetAPI";
import PostAPI from "../../api/PostAPI";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import axios from "../../api/API";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

export default function ReachedAtWarehouse() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabs, setTabs] = useState("reachedAtWarehouse");
  const [wareHouseData, setwareHouseData] = useState();
  const [transporterGuyData, settransporterGuyData] = useState();
  const [bookingIds, setbookingIds] = useState("");
  const [assignTransporter, setassignTransporter] = useState("");
  const { getData, reFetch } = GetAPI("allincoming");
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const [check, setCheck] = useState(false);
  const [dataBookingCreated, setBookingCreated] = useState([]);
  const [dataReachedAtWarehouse, setReachedAtWarehouse] = useState([]);
  const [dataDeliveredAtWarehouse, setDeliveredAtWarehouse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setBookingCreated(getData?.data?.bookingCreated);
    setReachedAtWarehouse(getData?.data?.reachedAtWarehouse);
    setDeliveredAtWarehouse(getData?.data?.deliveredAtWarehouse);
  }, [getData]);

  const navigate = useNavigate();
  const confirmReceivedFunc = async (id) => {
    let res = await PostAPI("atwarehousefromdriver", {
      bookingId: id,
    });
    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      reFetch();
    } else {
      error_toaster(res?.data?.message);
    }
  };
  const getBookingCreated = () => {
    const filteredArray = getData?.data?.bookingCreated.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };
  const getReachedAtWarehouse = () => {
    const filteredArray = getData?.data?.reachedAtWarehouse.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };
  const getDeliveredAtWarehouse = () => {
    const filteredArray = getData?.data?.deliveredAtWarehouse.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    let handlePagination = [];
    if (tabs === "created") handlePagination = dataBookingCreated;
    else if (tabs === "reachedAtWarehouse")
      handlePagination = dataReachedAtWarehouse;
    else handlePagination = dataDeliveredAtWarehouse;

    const totalPages = Math.ceil(handlePagination.length / itemsPerPage);

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Get Active Warehouse data
  const getActiveWarehouse = async () => {
    try {
      const res = await axios.get("/warehouse/allactivewarehouse", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setwareHouseData(res.data);
    } catch (error) {}
  };

  // Get Active Transporter Guys data
  const getTransporterGuy = async () => {
    try {
      const res = await axios.get("/warehouse/allactivetransporterguy", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      settransporterGuyData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getActiveWarehouse();
    getTransporterGuy();
  }, []);

  const [firstWarehouseName, setFirstWarehouseName] = useState("");
  const [firstWarehouseID, setFirstWarehouseID] = useState();
  const [isFirstCheckboxClick, setIsFirstCheckboxClick] = useState(true);

  const handlecheckbox = async (id, warehouseID, warehouseName) => {
    if (isFirstCheckboxClick) {
      setFirstWarehouseName(warehouseName);
      setFirstWarehouseID(warehouseID);
      setIsFirstCheckboxClick(false);
    }

    if (warehouseName === firstWarehouseName) {
      if (bookingIds.includes(id)) {
        setbookingIds(bookingIds.filter((filterId) => filterId !== id));
        if (bookingIds.length === 1) {
          setFirstWarehouseName("");
          setFirstWarehouseID("");
          setIsFirstCheckboxClick(true);
        }
      } else {
        setbookingIds([...bookingIds, id]);
        setCheck(true);
        setIsFirstCheckboxClick(false);
      }
    } else if (isFirstCheckboxClick) {
      if (bookingIds.includes(id)) {
        setbookingIds(bookingIds.filter((filterId) => filterId !== id));
      } else {
        setbookingIds([...bookingIds, id]);
        setCheck(true);
        setIsFirstCheckboxClick(false);
      }
    } else {
      error_toaster(`Warehouse should ${firstWarehouseName}`);
    }
  };

  const transporterid = (e) => {
    setassignTransporter(e.target.value);
  };
  const assignTransporterData = async () => {
    if (bookingIds.length === 0) {
      error_toaster("Please Select Order First");
    } else if (assignTransporter === "") {
      error_toaster("Please Select Transporter Guy");
    } else {
      let res = await PostAPI("totransit", {
        bookingIds: bookingIds,
        transporterGuyId: assignTransporter,
        deliveryWarehouseId: firstWarehouseID,
      });
      if (res?.data?.status === "1") {
        onClose();
        reFetch();
        setbookingIds([]);
        setTabs();
        success_toaster(res?.data?.message);
      } else {
        error_toaster(res?.data?.error);
      }
      navigate("/allincoming");
      setTabs("deliveredAtWarehouse");
    }
  };

  return (
    <>
      <Helmet>
        <title>PPS - All Incoming</title>
      </Helmet>
      <Layout
        pageTitle=""
        extend={true}
        data={
          <section>
            <h1>Reached at warehouse</h1>
            {/* <div className="flex justify-between my-5 relative before:absolute before:content-[''] before:-bottom-6 before:z-20 before:w-full before:h-2 before:rounded-md before:bg-[#FF745A33]">
              <TabHead
                title="Incoming"
                activeTab={tabs === "created" && true}
                onClick={() => {
                  setTabs("created");
                }}
              />
              <TabHead
                title="Reached at warehouse"
                activeTab={tabs === "reachedAtWarehouse" && true}
                onClick={() => {
                  setTabs("reachedAtWarehouse");
                }}
              />
              <TabHead
                title="Received at Warehouse"
                activeTab={tabs === "deliveredAtWarehouse" && true}
                onClick={() => {
                  setTabs("deliveredAtWarehouse");
                }}
              />
            </div> */}
            <div className="mt-6">
              <div className="grid md:grid-cols-3 gap-4">
                <input
                  className="text-base font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none"
                  type="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                />
              </div>
              {tabs === "deliveredAtWarehouse" &&
                getData?.data?.deliveredAtWarehouse && (
                  <>
                    <div className="flex lg:flex-row flex-col justify-end gap-3 mt-4">
                      <select
                        onChange={(e) => setSearchWarehouse(e.target.value)}
                        className="text-base font-medium border border-transparent bg-white rounded-lg py-3 px-4 w-full lg:w-80 focus:outline-none"
                      >
                        <option value="">Select WareHouse</option>
                        {wareHouseData?.data?.map((ele, key) => (
                          <option value={ele.id}>{ele.name}</option>
                        ))}
                      </select>
                      <button
                        className="text-lg font-bold font-feixen text-white bg-themePersian px-3 xl:px-5 py-2 rounded-md"
                        onClick={onOpen}
                      >
                        Assign Transporter Guy
                      </button>
                    </div>
                    <Modal
                      className="font-feixen"
                      onClose={onClose}
                      isOpen={isOpen}
                      isCentered
                    >
                      <ModalOverlay style={{ background: "rgba(0,0,0,0.1)" }} />
                      <ModalContent>
                        <ModalHeader>Modal Title</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          {" "}
                          <select
                            onChange={transporterid}
                            className="text-base font-medium border border-transparent shadow-inner rounded-lg py-3 px-4 w-full focus:outline-none"
                          >
                            <option>Select Transpoter Guy</option>
                            {transporterGuyData?.data?.map((ele, key) => (
                              <option value={ele.id}>
                                {ele.firstName + " " + ele.lastName}
                              </option>
                            ))}
                          </select>
                        </ModalBody>
                        <div className="model_footer flex items-center justify-between m-6">
                          <button
                            onClick={assignTransporterData}
                            className="text-normal font-bold text-white border border-transparent bg-themePersian px-3 xl:px-5 py-1 rounded-md hover:text-black hover:bg-transparent hover:border hover:border-black"
                          >
                            Assign
                          </button>
                          <button
                            className="text-normal font-bold text-white border border-transparent bg-themeRed px-3 xl:px-5 py-1 rounded-md hover:text-black hover:bg-transparent hover:border hover:border-black"
                            onClick={onClose}
                          >
                            Close
                          </button>
                        </div>
                      </ModalContent>
                    </Modal>
                  </>
                )}
              {!getData?.data ? (
                <div className="grid grid-cols-12 bg-white p-5 rounded-lg my-8">
                  <div className="col-span-10 space-y-3">
                    <div className="flex items-center gap-x-2">
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                      <div className="w-48">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-1">
                        <div className="w-5 m-auto">
                          <Skeleton count={3} />
                        </div>
                      </div>
                      <div className="col-span-11 flex flex-col justify-between space-y-4">
                        <div>
                          <h3 className="w-28 text-base font-medium text-black text-opacity-60">
                            <Skeleton />
                          </h3>
                        </div>
                        <div className="flex gap-x-16">
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 gap-x-5 flex flex-col items-end justify-between">
                    <div>
                      <div className="space-x-1 w-20">
                        <span>
                          <Skeleton />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {getData?.data?.bookingCreated.length > 0 &&
                  tabs === "created"
                    ? getBookingCreated().map((ele, key) => (
                        <OrderCreatedCard
                          apiEle={ele}
                          badge={ele.bookingStatus.title}
                          bg={
                            ele.bookingStatusId === 1
                              ? "bg-themeOrange"
                              : "bg-themePersianLight"
                          }
                        />
                      ))
                    : tabs === "reachedAtWarehouse"
                    ? getReachedAtWarehouse()?.map((ele, key) => (
                        <OrderCreatedCard
                          apiEle={ele}
                          onClick={() => confirmReceivedFunc(ele?.id)}
                          badge={ele.bookingStatus.title}
                          bg="bg-themeOrange"
                        />
                      ))
                    : getDeliveredAtWarehouse().map((ele, key) =>
                        searchWarehouse === "" ? (
                          <>
                            <div className="relative">
                              {check && bookingIds.includes(ele?.id) ? (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked={false}
                                />
                              )}

                              <OrderCreatedCard
                                apiEle={ele}
                                badge="Received Warehouse"
                                bg="bg-themeGreen"
                              />
                            </div>
                          </>
                        ) : ele?.deliveryWarehouseId
                            .toString()
                            .match(searchWarehouse.toString()) ? (
                          <>
                            <div className="relative">
                              {check && bookingIds.includes(ele?.id) ? (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked={false}
                                />
                              )}

                              <OrderCreatedCard
                                apiEle={ele}
                                badge="Received Warehouse"
                                bg="bg-themeGreen"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                  <div className="flex justify-end gap-x-4">
                    <button
                      className="text-lg text-themePersian  border border-themePersian flex items-center rounded-md px-4 py-2"
                      onClick={handlePrevClick}
                    >
                      <RiArrowLeftSLine /> Previous
                    </button>
                    <button
                      className="text-lg text-themePersian border border-themePersian flex items-center justify-center rounded-md px-4 py-2 w-[123px] text-center"
                      onClick={handleNextClick}
                    >
                      Next <RiArrowRightSLine />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        }
      />
    </>
  );
}
