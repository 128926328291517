// @ts-nocheck
// @ts-ignore
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PostAPI from "../../api/PostAPI";
import { liveImgURL } from "../../style/Style";
import Select from "react-select";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import Layout from "../layout/Layout";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import PutApi from "../../api/PutApi";
import { MiniLoaderTwo } from "../../loader/Loader";

export default function ScanOrder() {
  const navigate = useNavigate();
  const [orderDetails, setorderDetails] = useState("");
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({
    pickupDate: "",
    pickupStartTime: "",
    pickupEndTime: "",
  });
  const [leaveAtDoor, setLeaveAtDoor] = useState("");
  const [btnDisable, setbtnDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const bookingDetFunc = async () => {
    let res = await PostAPI("bookingdetails", {
      bookingId: location.state.res.data.bookingId,
    });
    setorderDetails(res?.data.data);
  };

  useEffect(() => {
    bookingDetFunc();
    if (location?.state?.res?.status === "2") {
      setScheduleModal(true);
    }
  }, [location?.state?.res?.status]); // Add necessary dependencies here

  const confirmPayment = async () => {
    setModal(true);
  };
  const waqar = async () => {
    setDisabled(true);
    let res = await PostAPI("confirmpayment", {
      bookingId: location.state.res.data.bookingId,
    });

    if (res?.data?.status === "1") {
      navigate("/");
      success_toaster(res?.data?.message);
    } else {
      error_toaster(res?.data?.message);
    }
  };

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#D9D9D9",
      outline: "none",
      boxShadow: state.isFocused ? `0 0 0 1px lightGray` : "none",
      cursor: "pointer",
      border: "1px solid #E2E8F0",
      borderRadius: "6px",
      paddingTop: "3px",
      paddingBottom: "3px",
      outerWidth: "300px",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "black" : "black", // You can change these colors as needed
      "&:hover": {
        color: "black", // You can change this color as needed
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "white" : "white",
      color: "black",
      "&:hover": {
        background: "#D9D9D9", // Background color of option on hover
      },
    }),
  };

  const options = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  // Get the current date in YYYY-MM-DD format
  const currentDate = new Date().toISOString().split("T")[0];

  const handlesubmit = async (e) => {
    if (data?.pickupDate === "") {
      error_toaster("Please Select Date ");
    } else if (data?.pickupStartTime === "") {
      error_toaster("Please Select Start Time");
    } else if (data?.pickupEndTime === "") {
      error_toaster("Please Select End Time");
    } else if (data?.pickupStartTime === data?.pickupEndTime) {
      error_toaster("Start time and end time cannot be the same.");
    } else if (leaveAtDoor === "") {
      error_toaster("Please Select Leave at door");
    } else {
      setLoader(true);
      let res = await PutApi(`schedule-same-warehouse-order`, {
        bookingId: location?.state?.res?.data?.bookingId,
        pickupDate: data?.pickupDate,
        pickupStartTime: data?.pickupStartTime,
        pickupEndTime: data?.pickupEndTime,
        leaveAtDoor: leaveAtDoor?.label === "Yes" ? true : false,
      });
      if (res?.data?.status === "1") {
        success_toaster(res?.data?.message);
        setbtnDisable(!btnDisable);
        setLoader(false);
        setScheduleModal(false);
      } else {
        error_toaster(res?.data?.error);
        setScheduleModal(false);
        setLoader(false);
      }
    }
  };

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Layout
        pageTitle=""
        extend={true}
        data={
          <>
            <section className="bg-white rounded-md my-5 md:px-16 px-4 py-5 ">
              <Modal onClose={() => setModal(false)} isOpen={modal} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Confirm Payment</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <div className="flex gap-x-5">
                      <button
                        onClick={waqar}
                        className="bg-themePersian text-white text-sm font-medium border border-themePersian cursor-pointer rounded-md px-3 lg:px-20 py-3 hover:text-themePersian hover:bg-transparent hover:border hover:border-themePersian"
                        disabled={disabled}
                      >
                        OK
                      </button>
                      <button
                        onClick={() => setModal(false)}
                        className="bg-themeRed text-white text-sm font-medium border border-themeRed cursor-pointer rounded-md px-3 lg:px-20 py-3 hover:text-themeRed hover:bg-transparent hover:border hover:border-themeRed"
                      >
                        Close
                      </button>
                    </div>
                  </ModalBody>
                </ModalContent>
              </Modal>
              <Modal
                onClose={() => {
                  setScheduleModal(false);
                }}
                isOpen={scheduleModal}
                isCentered
              >
                <ModalOverlay />

                <ModalContent>
                  <ModalHeader>
                    <div className="flex justify-center">
                      Confirm Schedule Delivery
                    </div>
                  </ModalHeader>
                  <ModalCloseButton />
                  {loader ? (
                    <div className="h-[400px]">
                      <MiniLoaderTwo />
                    </div>
                  ) : (
                    <ModalBody>
                      <div className="flex flex-col gap-5 justify-center items-center w-4/5 mx-auto pb-4">
                        <div className="space-y-5">
                          <div className="flex flex-col gap-2">
                            <label htmlFor="pickupDate">Pickup Date</label>
                            <input
                              id="pickupDate"
                              name="pickupDate"
                              className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none"
                              value={data?.pickupDate}
                              onChange={onChange}
                              min={currentDate}
                              type="date"
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label htmlFor="pickupStartTime">
                              Pickup Start Time
                            </label>
                            <input
                              id="pickupStartTime"
                              name="pickupStartTime"
                              className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none"
                              value={data?.pickupStartTime}
                              onChange={onChange}
                              type="time"
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label htmlFor="pickupEndTime">
                              Pickup End Time
                            </label>
                            <input
                              id="pickupEndTime"
                              name="pickupEndTime"
                              className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none"
                              value={data?.pickupEndTime}
                              onChange={onChange}
                              type="time"
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label htmlFor="=leaveAtDoor">Leave at Door</label>
                            <Select
                              onChange={setLeaveAtDoor}
                              className="text-base font-medium border border-transparent rounded-lg w-96 focus:outline-none"
                              options={options}
                              styles={selectStyles}
                            ></Select>
                          </div>

                          <button
                            className="block text-center bg-themePersian text-white rounded-md font-medium text-xl border border-themePersian py-2 w-96 hover:bg-transparent hover:text-themePersian"
                            onClick={handlesubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </ModalBody>
                  )}
                </ModalContent>
              </Modal>
              <div className="flex md:flex-row flex-col justify-end items-center gap-x-3 gap-y-4">
                <div className="text-center">
                  <button
                    onClick={confirmPayment}
                    className="bg-themePersian text-white text-sm font-medium border border-themePersian cursor-pointer rounded-md px-3 lg:px-20 py-3 hover:text-themePersian hover:bg-transparent hover:border hover:border-themePersian"
                  >
                    Confirm Payment
                  </button>
                </div>
                <div className="text-center">
                  <a
                    href={`https://backend.pps507.com/customer/downloadpdf?id=${orderDetails?.id}`}
                    className="bg-themeOrange block text-white text-sm font-medium border border-themeOrange cursor-pointer rounded-md px-3 lg:px-20 py-3 hover:text-themePersian hover:bg-transparent hover:border hover:border-themePersian"
                  >
                    Download Invoice
                  </a>
                </div>
              </div>
              <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 my-10 gap-5">
                <div className="font-medium bg-white rounded-md shadow-2xl px-10 py-6 space-y-6">
                  <h3 className="text-2xl">
                    Order#
                    <span className="text-[#00000066]">
                      {orderDetails?.trackingId}
                    </span>
                  </h3>
                  <div>
                    <h3 className="text-2xl">Sender Details</h3>
                    <h3 className="text-themePersian text-xl font-bold">
                      {orderDetails?.senderDetails?.name}
                    </h3>
                    <p className="text-base font-normal text-[#20205399]">
                      Email
                    </p>
                    <p className="text-base font-normal">
                      {orderDetails?.senderDetails?.email}
                    </p>
                    <p className="text-base font-normal text-[#20205399]">
                      Phone no
                    </p>
                    <p className="text-base font-normal">
                      {orderDetails?.senderDetails?.phone}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-2xl">Recipient Details</h3>
                    <h3 className="text-themePersian text-xl font-bold">
                      {orderDetails?.recipientDetails?.name}
                    </h3>
                    <p className="text-base font-normal text-[#20205399]">
                      Email
                    </p>
                    <p className="text-base font-normal">
                      {orderDetails?.recipientDetails?.email}
                    </p>
                    <p className="text-base font-normal text-[#20205399]">
                      Phone no
                    </p>
                    <p className="text-base font-normal">
                      {orderDetails?.recipientDetails?.phone}
                    </p>
                  </div>
                  <hr />
                  <h3 className="text-2xl"> Order Instruction</h3>
                  <p className="text-base font-normal text-[#20205399]">
                    {orderDetails?.instructions}
                  </p>
                </div>
                <div className="bg-white rounded-md shadow-xl px-10 py-6 space-y-6">
                  <div className="text-2xl flex items-center gap-x-6">
                    <img
                      className="w-9"
                      src={`${liveImgURL}/delivery.webp`}
                      alt="Delivery Details"
                    />
                    Delivery Details
                  </div>
                  <div className="flex gap-x-5 relative">
                    <div className="flex flex-col items-center">
                      <div className="w-fit border border-themeOrange rounded-fullest p-1">
                        <div className="w-4 h-4 rounded-fullest bg-themeOrange"></div>
                      </div>
                      <img src={`${liveImgURL}/line.webp`} alt="line" />
                      <div className="w-fit border border-themeOrange p-1">
                        <div className="w-4 h-4 bg-themeOrange"></div>
                      </div>
                      <img src={`${liveImgURL}/line.webp`} alt="line" />
                      <div className="w-fit border border-themeOrange p-1">
                        <div className="w-4 h-4 bg-themeOrange"></div>
                      </div>
                      <img src={`${liveImgURL}/line.webp`} alt="line" />
                      <div className="w-fit border border-themeOrange rounded-fullest p-1">
                        <div className="w-4 h-4 rounded-fullest bg-themeOrange"></div>
                      </div>
                    </div>
                    <div className="space-y-6 w-full">
                      <div className="flex justify-between min-h-[44px]">
                        <div>
                          <p className="font-medium text-base">Pickup</p>
                          <p className={`font-normal text-sm text-opacity-60`}>
                            {orderDetails?.deliveryDetails?.pickupCode}
                          </p>
                        </div>
                        <div className="flex flex-col items-end">
                          <p className={`font-normal text-sm  text-opacity-60`}>
                            {orderDetails?.deliveryDetails?.pickupTime}
                          </p>
                          <p className={`font-normal text-sm text-opacity-60`}>
                            {orderDetails?.deliveryDetails?.pickupDate}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between min-h-[44px]">
                        <div>
                          <p className="font-medium text-base">
                            Receiving Warehouse
                          </p>
                          <p className={`font-normal text-sm text-opacity-60`}>
                            {orderDetails?.receivingWarehouse &&
                              `${orderDetails?.receivingWarehouse?.addressDB?.PostalCode} ${orderDetails?.receivingWarehouse?.addressDB?.secondPostalCode}`}
                          </p>
                        </div>
                        <div className="flex flex-col items-end">
                          <p className={`font-normal text-sm  text-opacity-60`}>
                            {orderDetails?.receivingWarehouse &&
                              orderDetails?.receivingWarehouse?.name}
                          </p>
                          <p className={`font-normal text-sm  text-opacity-60`}>
                            {orderDetails?.receivingWarehouse &&
                              orderDetails?.receivingWarehouse?.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between min-h-[44px]">
                        <div>
                          <p className="font-medium text-base">
                            Delivery Warehouse
                          </p>
                          <p className={`font-normal text-sm  text-opacity-60`}>
                            {orderDetails?.deliveryWarehouse &&
                              `${orderDetails?.deliveryWarehouse?.addressDB?.PostalCode} ${orderDetails?.deliveryWarehouse?.addressDB?.secondPostalCode}`}
                          </p>
                        </div>
                        <div className="flex flex-col items-end">
                          <p className={`font-normal text-sm text-opacity-60`}>
                            {orderDetails?.deliveryWarehouse &&
                              orderDetails?.deliveryWarehouse?.name}
                          </p>
                          <p className={`font-normal text-sm  text-opacity-60`}>
                            {orderDetails?.deliveryWarehouse &&
                              orderDetails?.deliveryWarehouse?.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between min-h-[44px]">
                        <div>
                          <p className="font-medium text-base">Drop off</p>
                          <p className={`font-normal text-sm text-opacity-60`}>
                            {orderDetails?.deliveryDetails?.dropoffCode}
                          </p>
                        </div>
                        <div className="flex flex-col items-end">
                          <p className={`font-normal text-sm text-opacity-60`}>
                            {orderDetails?.deliveryDetails?.dropoffTime}
                          </p>
                          <p className={`font-normal text-sm text-opacity-60`}>
                            {orderDetails?.deliveryDetails?.dropoffDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-2xl flex items-center gap-x-6">
                    <img
                      className="w-8"
                      src={`${liveImgURL}/Vector.webp`}
                      alt="Delivery Details"
                    />
                    Parcel Details
                  </div>
                  <div>
                    <div className="rounded-lg py-4 space-y-4">
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Type of shipment
                        </h6>
                        <p className="text-lg font-medium">
                          {orderDetails?.parcelDetails?.shipmentType}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Category
                        </h6>
                        <p className="text-lg font-medium">
                          {orderDetails?.parcelDetails?.category}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Size
                        </h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: orderDetails?.parcelDetails?.size,
                          }}
                          className="text-lg font-medium"
                        />
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Weight
                        </h6>
                        <p className="text-lg font-medium">
                          {orderDetails?.parcelDetails?.weight}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Distance
                        </h6>
                        <p className="text-lg font-medium">
                          {orderDetails?.parcelDetails?.distance}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Date
                        </h6>
                        <p className="text-lg font-medium">
                          {orderDetails?.parcelDetails?.ETA}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6>Total Amount</h6>
                        <p>{orderDetails?.parcelDetails?.orderTotal}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
}
