// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GetAPI from "../../api/GetAPI";
import PostAPI from "../../api/PostAPI";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../toaster/Toaster";
import Layout from "../layout/Layout";


export default function DriverSignup() {
  const BASE_URL2 = "https://backend.pps507.com/";
  const navigate = useNavigate();
  const activeVeh = GetAPI("getactivevehicles");

  const [timeline, setTimeline] = useState("1");
  const [visible, setVisible] = useState(false);
  const [driver, setDriver] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "92",
    phoneNum: "",
    password: "",
    profileImage: "",
  });
  const [vehicle, setVehicle] = useState({
    vehicleMake: "",
    vehicleModel: "",
    vehicleYear: "",
    vehicleColor: "",
    userId: "",
    vehImages: [],
    vehicleTypeId: "",
  });

  const [license, setLicense] = useState({
    licIssueDate: "",
    licExpiryDate: "",
    frontImage: "",
    backImage: "",
    userId: "",
  });
  const onChange = (e) => {
    setDriver({ ...driver, [e.target.name]: e.target.value });
  };
  const onChange2 = (e) => {
    if (e.target.name === "vehImages") {
      setVehicle({ ...vehicle, [e.target.name]: [...e.target.files] });
    } else {
      setVehicle({ ...vehicle, [e.target.name]: e.target.value });
    }
  };
  const onChange3 = (e) => {
    setLicense({ ...license, [e.target.name]: e.target.value });
  };

  // Email Validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const driverFunc = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(driver.email);
    if (driver.firstName === "") {
      info_toaster("Please enter Driver's First Name");
    } else if (driver.lastName === "") {
      info_toaster("Please enter Driver's Last Name");
    } else if (driver.email === "") {
      info_toaster("Please enter Driver's Email");
    } else if (driver.phoneNum === "") {
      info_toaster("Please enter Driver's Phone Number");
    } else if (driver.password === "") {
      info_toaster("Please create Driver's Password");
    } else if (driver.profileImage === "") {
      info_toaster("Please enter Driver's Profile Image");
    } else {
      const formData = new FormData();
      formData.append("firstName", driver.firstName);
      formData.append("lastName", driver.lastName);
      formData.append("email", driver.email);
      formData.append("countryCode", driver.countryCode);
      formData.append("phoneNum", driver.phoneNum);
      formData.append("password", driver.password);
      formData.append("profileImage", driver.profileImage);
      let res = await PostAPI("registerstep1", formData);
      if (!isValidEmail) {
        error_toaster("Please Enter a Valid Email ");
      } else if (res?.data?.status === "1") {
        success_toaster(res?.data?.message);
        setTimeline("2");
        setVehicle({ ...vehicle, userId: res?.data?.data?.id });
        // setDriver({
        //   firstName: "",
        //   lastName: "",
        //   email: "",
        //   countryCode: "92",
        //   phoneNum: "",
        //   password: "",
        //   profileImage: "",
        // });
      } else {
        error_toaster(res?.data?.message);
      }
    }
  };
  const vehicleFunc = async (e) => {
    e.preventDefault();
    if (vehicle.vehicleMake === "") {
      info_toaster("Please enter Vehicle's Make");
    } else if (vehicle.vehicleModel === "") {
      info_toaster("Please enter Vehicle's Model");
    } else if (vehicle.vehicleColor === "") {
      info_toaster("Please enter Vehicle's Color");
    } else if (vehicle.vehicleYear === "") {
      info_toaster("Please enter Vehicle's Year");
    } else if (vehicle.vehImages.length === 0) {
      info_toaster("Please select at least one image for the vehicle");
    } else if (vehicle.vehicleTypeId === "") {
      info_toaster("Please select Vehicle Type");
    } else {
      const formData = new FormData();
      formData.append("vehicleMake", vehicle.vehicleMake);
      formData.append("vehicleModel", vehicle.vehicleModel);
      formData.append("vehicleColor", vehicle.vehicleColor);
      formData.append("vehicleYear", vehicle.vehicleYear.value);
      formData.append("vehicleTypeId", vehicle.vehicleTypeId.value);
      formData.append("userId", vehicle.userId);
      vehicle.vehImages.forEach((file) => {
        formData.append("vehImages", file);
      });
      let res = await PostAPI("registerstep2", formData);
      if (res?.data?.status === "1") {
        success_toaster(res?.data?.message);
        setTimeline("3");
        setLicense({ ...license, userId: res?.data?.data?.userId });
        setVehicle({
          vehicleMake: "",
          vehicleModel: "",
          vehicleYear: "",
          vehicleColor: "",
          userId: "",
          vehImages: [],
          vehicleTypeId: "",
        });
      } else {
        error_toaster(res?.data?.message);
      }
    }
  };
  const licenseFunc = async (e) => {
    e.preventDefault();
    if (license.licIssueDate === "") {
      info_toaster("Please enter License's Issue Date");
    } else if (license.licExpiryDate === "") {
      info_toaster("Please enter License's Expiry Date");
    } else if (license.frontImage === "") {
      info_toaster("Please enter License's Front Image");
    } else if (license.backImage === "") {
      info_toaster("Please enter License's Back Image");
    } else {
      const formData = new FormData();
      formData.append("licIssueDate", license.licIssueDate);
      formData.append("licExpiryDate", license.licExpiryDate);
      formData.append("frontImage", license.frontImage);
      formData.append("backImage", license.backImage);
      formData.append("userId", license.userId);
      let res = await PostAPI("registerstep3", formData);
      if (res?.data?.status === "1") {
        navigate("/");
        success_toaster(res?.data?.message);
        setLicense({
          licIssueDate: "",
          licExpiryDate: "",
          frontImage: "",
          backImage: "",
          userId: "",
        });
      } else {
        error_toaster(res?.data?.message);
      }
    }
  };
  const options = [];
  activeVeh.getData?.data?.map((activeVeh, index) =>
    options.push({
      value: activeVeh?.id,
      label: activeVeh?.title,
    })
  );
  // activeWare.data?.data?.map((activeWare, index) =>
  //   options2.push({
  //     value: activeWare?.id,
  //     label: activeWare?.name,
  //   })
  // );
  // const currentYear = new Date().getFullYear();
  // const startYear = 1990;
  // // const years = [];
  const currentYear = new Date().getFullYear();
  const startYear = 1980;
  const [years, setYears] = useState([]);

  useEffect(() => {
    const yearArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearArray.push(year);
    }
    setYears(yearArray);
  }, [currentYear, startYear]);

  const style =
    ".react-tel-input {width: 88px} .react-tel-input input {pointer-events: none;}";
  const labelStyle = "font-normal text-sm text-black";
  const inputStyle =
    "w-full resize-none font-normal text-base text-black rounded py-2.5 px-4 bg-themeGray border-none placeholder:text-black placeholder:text-opacity-60 focus:outline-none";

  return (
    <>
      <style>{style}</style>
      <Helmet>
        <title>PPS - Driver Signup</title>
      </Helmet>
      <Layout
        extend={true}
        data={
          <section>
            <h1>Driver Signup</h1>
            <div className="grid grid-cols-12 gap-5">
              <form className="col-span-8 grid grid-cols-2 gap-x-20 gap-y-5 bg-white rounded-md p-8">
                <div className="col-span-2 flex items-center text-lg my-4">
                  <div
                    className={`
                    ${
                      timeline === "1" || timeline === "2" || timeline === "3"
                        ? "bg-themePurple text-white"
                        : "bg-black bg-opacity-20"
                    } min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
                  >
                    1
                  </div>
                  <div
                    className={`${
                      timeline === "2" || timeline === "3"
                        ? "border-y-themePurple"
                        : "border-y-black border-opacity-20"
                    } w-full h-0 border-y mx-4`}
                  ></div>
                  <div
                    className={`
                    ${
                      timeline === "2" || timeline === "3"
                        ? "bg-themePurple text-white"
                        : "bg-black bg-opacity-20"
                    } min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
                  >
                    2
                  </div>
                  <div
                    className={`${
                      timeline === "3"
                        ? "border-y-themePurple"
                        : "border-y-black border-opacity-20"
                    } w-full h-0 border-y mx-4`}
                  ></div>
                  <div
                    className={`
                    ${
                      timeline === "3"
                        ? "bg-themePurple text-white"
                        : "bg-black bg-opacity-20"
                    } min-w-[40px] min-h-[40px] rounded-fullest flex justify-center items-center`}
                  >
                    3
                  </div>
                </div>
                {timeline === "1" ? (
                  <>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="firstName">
                        First Name
                      </label>
                      <input
                        value={driver.firstName}
                        onChange={onChange}
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter Driver's First Name"
                        className={inputStyle}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="lastName">
                        Last Name
                      </label>
                      <input
                        value={driver.lastName}
                        onChange={onChange}
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Enter Driver's Last Name"
                        className={inputStyle}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="email">
                        Email
                      </label>
                      <input
                        value={driver.email}
                        onChange={onChange}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter Driver's Email"
                        className={inputStyle}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="phoneNum">
                        Phone no
                      </label>
                      <div className="flex gap-x-1">
                        <PhoneInput
                          inputStyle={{
                            display: "block",
                            width: "88px",
                            paddingTop: "22px",
                            paddingBottom: "22px",
                            background: "#F4F5FA",
                            color: "black",
                            border: "none",
                          }}
                          inputProps={{
                            id: "countryCode",
                            name: "countryCode",
                          }}
                          country="pk"
                          value={driver.countryCode}
                          onChange={(code) =>
                            setDriver({ ...driver, countryCode: code })
                          }
                        />
                        <input
                          value={driver.phoneNum}
                          onChange={onChange}
                          type="number"
                          name="phoneNum"
                          id="phoneNum"
                          placeholder="Enter Driver's Phone Number"
                          className={inputStyle}
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="password">
                        Create Password
                      </label>
                      <div className="relative">
                        <button
                          onClick={() => setVisible(!visible)}
                          type="button"
                          className="text-black text-opacity-40 absolute right-4 top-1/2 -translate-y-1/2"
                        >
                          {visible ? (
                            <AiOutlineEyeInvisible size={20} />
                          ) : (
                            <AiOutlineEye size={20} />
                          )}
                        </button>
                        <input
                          value={driver.password}
                          onChange={onChange}
                          type={visible ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Create Driver's Password"
                          className={inputStyle}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="profileImage">
                        Profile Image
                      </label>
                      <input
                        onChange={(e) =>
                          setDriver({
                            ...driver,
                            [e.target.name]: e.target.files[0],
                          })
                        }
                        type="file"
                        name="profileImage"
                        id="profileImage"
                        className={inputStyle}
                      />
                    </div>
                  </>
                ) : timeline === "2" ? (
                  <>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="vehicleMake">
                        Vehicle Make
                      </label>
                      <input
                        value={vehicle.vehicleMake}
                        onChange={onChange2}
                        type="text"
                        name="vehicleMake"
                        id="vehicleMake"
                        placeholder="Enter Vehicle's Make"
                        className={inputStyle}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="vehicleModel">
                        Vehicle Model
                      </label>
                      <input
                        value={vehicle.vehicleModel}
                        onChange={onChange2}
                        type="text"
                        name="vehicleModel"
                        id="vehicleModel"
                        placeholder="Enter Vehicle's Model"
                        className={inputStyle}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="vehicleColor">
                        Vehicle Color
                      </label>
                      <input
                        value={vehicle.vehicleColor}
                        onChange={onChange2}
                        type="text"
                        name="vehicleColor"
                        id="vehicleColor"
                        placeholder="Enter Vehicle's Color"
                        className={inputStyle}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="vehicleYear">
                        Vehicle Year
                      </label>
                      <Select
                        value={vehicle.vehicleYear}
                        onChange={(selectedYear) => {
                          setVehicle({ ...vehicle, vehicleYear: selectedYear });
                        }}
                        options={years.map((year) => ({
                          value: year,
                          label: year,
                        }))}
                        placeholder="Year"
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="vehImages">
                        Vehicle Images
                      </label>
                      <input
                        onChange={onChange2}
                        type="file"
                        name="vehImages"
                        id="vehImages"
                        className={inputStyle}
                        multiple={true}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="vehicleTypeId">
                        Vehicle Type
                      </label>
                      <Select
                        value={vehicle.vehicleTypeId}
                        onChange={(e) =>
                          setVehicle({ ...vehicle, vehicleTypeId: e })
                        }
                        options={options}
                        inputId="vehicleTypeId"
                      />
                    </div>
                  </>
                ) : timeline === "3" ? (
                  <>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="licIssueDate">
                        License Issue Date
                      </label>
                      <input
                        value={license.licIssueDate}
                        onChange={onChange3}
                        type="date"
                        name="licIssueDate"
                        id="licIssueDate"
                        className={inputStyle}
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="licExpiryDate">
                        License Expiry Date
                      </label>
                      <input
                        value={license.licExpiryDate}
                        onChange={onChange3}
                        type="date"
                        name="licExpiryDate"
                        id="licExpiryDate"
                        className={inputStyle}
                        min={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="frontImage">
                        Front Image
                      </label>
                      <input
                        onChange={(e) =>
                          setLicense({
                            ...license,
                            [e.target.name]: e.target.files[0],
                          })
                        }
                        type="file"
                        name="frontImage"
                        id="frontImage"
                        className={inputStyle}
                      />
                    </div>
                    <div className="space-y-1">
                      <label className={labelStyle} htmlFor="backImage">
                        Back Image
                      </label>
                      <input
                        onChange={(e) =>
                          setLicense({
                            ...license,
                            [e.target.name]: e.target.files[0],
                          })
                        }
                        type="file"
                        name="backImage"
                        id="backImage"
                        className={inputStyle}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-span-2 flex justify-end items-center gap-x-5 mt-20">
                  {/* <button
                  type="button"
                  className="bg-transparent w-40 font-medium text-xl text-themePurple py-2.5 px-5 rounded border border-themePurple hover:text-white hover:bg-themePurple"
                >
                  Back
                </button> */}
                  <button
                    type="submit"
                    onClick={
                      timeline === "1"
                        ? driverFunc
                        : timeline === "2"
                        ? vehicleFunc
                        : timeline === "3"
                        ? licenseFunc
                        : ""
                    }
                    className="bg-themePurple w-40 font-medium text-xl text-white py-2.5 px-5 rounded border border-themePurple hover:text-themePurple hover:bg-transparent"
                  >
                    Create
                  </button>
                </div>
              </form>
              <div className="col-span-4 bg-white rounded-md space-y-5 p-8">
                <h2 className="font-bold text-xl text-themePurple">
                  Your Entries
                </h2>
                <hr className="border-none h-0.5 bg-black bg-opacity-10" />
                <div>
                  <h6 className="font-medium text-sm">Name</h6>
                  <p className="font-bold text-base text-black text-opacity-60">
                    {driver.firstName === ""
                      ? "Enter Driver's Name"
                      : driver.firstName + " " + driver.lastName}
                  </p>
                </div>
                <hr className="border-none h-0.5 bg-black bg-opacity-10" />
                <div>
                  <h6 className="font-medium text-sm">Email</h6>
                  <p className="font-bold text-base text-black text-opacity-60">
                    {driver.email === ""
                      ? "Enter Driver's Email"
                      : driver.email}
                  </p>
                </div>
                <hr className="border-none h-0.5 bg-black bg-opacity-10" />
                <div>
                  <h6 className="font-medium text-sm">Phone No</h6>
                  <p className="font-bold text-base text-black text-opacity-60">
                    {driver.phoneNum === ""
                      ? "Enter Driver's Phone Number"
                      : "+" + driver.countryCode + " " + driver.phoneNum}
                  </p>
                </div>
              </div>
            </div>
          </section>
        }
      />
    </>
  );
}
