// @ts-nocheck
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/TableStyle";
import { Helmet } from "react-helmet";
import { FaEye, FaTrash } from "react-icons/fa";
import Layout from "../layout/Layout";
import GetAPI from "../../api/GetAPI";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader/Loader";
import PutApi from "../../api/PutApi";
import { error_toaster, success_toaster } from "../../toaster/Toaster";

export default function Drivers() {
  const { getData, reFetch } = GetAPI("alldrivers");
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const driverDetails = (id) => {
    navigate("/driver_details", {
      state: {
        userId: id,
      },
    });
  };

  const deleteDriver = async (id) => {
    setDisabled(true);
    const res = await PutApi("deletedriver", {
      userId: id,
    });

    if (res?.data?.status === "1") {
      reFetch();
      success_toaster(res?.data?.message);
      setDisabled(false);
    } else {
      error_toaster(res?.data?.message);
      setDisabled(false);
    }
  };

  const blockStatus = async (id) => {
    setDisabled(true);
    let response = await PutApi("changedriverstatus", {
      userId: id,
      status: false,
    });

    if (response?.data?.status === "1") {
      reFetch();
      success_toaster(response?.data?.message);
      setDisabled(false);
    } else {
      error_toaster(response?.data?.message);
      setDisabled(false);
    }
  };
  const activeStatus = async (id) => {
    setDisabled(true);
    let response = await PutApi("changedriverstatus", {
      userId: id,
      status: true,
    });
    if (response?.data?.status === "1") {
      reFetch();
      success_toaster(response?.data?.message);
      setDisabled(false);
    } else {
      error_toaster(response?.data?.message);
      setDisabled(false);
    }
  };
  const columns = [
    {
      name: "Sr#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone No",
      selector: (row) => row.phone,
    },
    {
      name: "Approval Status",
      selector: (row) => row.approval,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  getData?.data?.map((dri, index) => {
     datas.push({
      id: index + 1,
      name: `${dri?.user?.firstName} ${dri?.user?.lastName}`,
      phone: `${dri?.user?.countryCode} ${dri?.user?.phoneNum}`,
      approval: dri?.approvedByAdmin,
      status: (
        <div>
          {dri?.user?.status === true ? (
            <button
              className="border border-green-500 rounded-sm w-20 h-8 text-green-500"
              onClick={(e) => {
                blockStatus(dri?.user?.id);
              }}
              disabled={disabled}
            >
              Active
            </button>
          ) : (
            <button
              className="border border-red-500 rounded-sm w-20 h-8 text-red-500"
              onClick={(e) => {
                activeStatus(dri?.user?.id);
              }}
              disabled={disabled}
            >
              Inactive
            </button>
          )}
        </div>
      ),
      action: (
        <div className="flex items-center gap-3">
          <button
            onClick={() => driverDetails(dri?.user?.id)}
            className=" border border-black rounded-full w-8 h-8 flex justify-center items-center"
          >
            <FaEye className="text-xl" />
          </button>

          <button
            onClick={() => deleteDriver(dri?.user?.id)}
            className="border border-red-500 rounded-full w-8 h-8 flex justify-center items-center"
            disabled={disabled}
          >
            <FaTrash className="text-lg text-red-500" />
          </button>
        </div>
      ),
    });
  });

  return getData?.length === 0 ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>PPS - All Drivers</title>
      </Helmet>

      <Layout
        extend={true}
        data={
          <section>
            <DataTable
              columns={columns}
              data={datas}
              customStyles={customStyles}
            />
          </section>
        }
      />
    </>
  );
}
