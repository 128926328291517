import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import Skeleton from "react-loading-skeleton";
import GetAPI from "../../api/GetAPI";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import OrderCreatedCard from "../allincoming/allIncomingElements/OrderCreatedCard";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import PutApi from "../../api/PutApi";

export default function ReturnPackages() {
  const { getData, reFetch } = GetAPI("returned-packeges");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [atWarehouse, setAtWarehouse] = useState([]);
  const [returnTab, setReturnTab] = useState("Returned Jobs(Incoming)");
  const inputRef = useRef(null);

  const getReturnPackInc = () => {
    const filteredArray = getData?.data?.incomming.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const getReturnPackReceived = () => {
    const filteredArray = getData?.data?.received.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    let handlePagination = [];
    handlePagination = atWarehouse;
    const totalPages = Math.ceil(handlePagination.length / itemsPerPage);

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const receivedFromDriver = async (id) => {
    let res = await PutApi(`accept-returned-pacakege/${id}`);
    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      reFetch();
    } else {
      error_toaster(res?.data?.message);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setAtWarehouse(getData?.data);
  }, [getData]);

  return (
    <>
      <Layout
        pageTitle=""
        extend={true}
        data={
          <section>
            <h1>
              {returnTab === "Returned Jobs(Incoming)"
                ? "Returned Jobs(Incoming)"
                : "Returned Jobs(Received)"}
            </h1>
            <div className="mt-7">
              <div className="flex justify-between items-center">
                <div className="flex my-5 ">
                  <button
                    onClick={() => setReturnTab("Returned Jobs(Incoming)")}
                    className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                      returnTab === "Returned Jobs(Incoming)"
                        ? "bg-themePersian text-white"
                        : "text-themePersian"
                    }`}
                  >
                    Returned Jobs(Incoming)
                  </button>
                  <button
                    onClick={() => setReturnTab("Returned Jobs(Received)")}
                    className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                      returnTab === "Returned Jobs(Received)"
                        ? "bg-themePersian text-white"
                        : "text-themePersian"
                    }`}
                  >
                    Returned Jobs(Received)
                  </button>
                </div>

                <div>
                  <input
                    className="text-base font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none w-96"
                    type="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    ref={inputRef}
                    autoFocus
                  />
                </div>
              </div>

              {!getData?.data ? (
                <div className="grid grid-cols-12 bg-white p-5 rounded-lg my-8">
                  <div className="col-span-10 space-y-3">
                    <div className="flex items-center gap-x-2">
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                      <div className="w-48">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-1">
                        <div className="w-5 m-auto">
                          <Skeleton count={3} />
                        </div>
                      </div>
                      <div className="col-span-11 flex flex-col justify-between space-y-4">
                        <div>
                          <h3 className="w-28 text-base font-medium text-black text-opacity-60">
                            <Skeleton />
                          </h3>
                        </div>
                        <div className="flex gap-x-16">
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 gap-x-5 flex flex-col items-end justify-between">
                    <div>
                      <div className="space-x-1 w-20">
                        <span>
                          <Skeleton />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {getData?.data?.incomming.length > 0 &&
                  returnTab === "Returned Jobs(Incoming)" ? (
                    getReturnPackInc().map((ele, key) => (
                      <OrderCreatedCard
                        apiEle={ele}
                        badge={ele.bookingStatus.title}
                        bg={
                          ele.bookingStatusId === 1
                            ? "bg-themeOrange"
                            : "bg-themePersianLight"
                        }
                        onClick={() => {
                          receivedFromDriver(ele?.id);
                        }}
                        tab={returnTab}
                      />
                    ))
                  ) : returnTab === "Returned Jobs(Received)" ? (
                    getReturnPackReceived().map((ele, key) => (
                      <OrderCreatedCard
                        apiEle={ele}
                        badge={ele.bookingStatus.title}
                        bg={
                          ele.bookingStatusId === 1
                            ? "bg-themeOrange"
                            : "bg-themePersianLight"
                        }
                      />
                    ))
                  ) : (
                    <></>
                  )}

                  <div className="flex justify-end gap-x-4">
                    <button
                      className="text-lg text-themePersian  border border-themePersian flex items-center rounded-md px-4 py-2"
                      onClick={handlePrevClick}
                    >
                      <RiArrowLeftSLine /> Previous
                    </button>
                    <button
                      className="text-lg text-themePersian border border-themePersian flex items-center justify-center rounded-md px-4 py-2 w-[123px] text-center"
                      onClick={handleNextClick}
                    >
                      Next <RiArrowRightSLine />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        }
      />
    </>
  );
}
