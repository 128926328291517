// @ts-nocheck
// @ts-ignore
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { customStyles } from "../../style/TableStyle";
import Layout from "../layout/Layout";
import GetAPI from "../../api/GetAPI";
import Loader, { MiniLoaderTwo } from "../../loader/Loader";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../toaster/Toaster";
import PutApi from "../../api/PutApi";
import moment from "moment";

export default function Pickup() {
  const [search, setSearch] = useState("");
  const [select, setSelect] = useState("");
  const { getData, reFetch } = GetAPI("expired-jobs");
  const [modal, setModal] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [loader, setLoader] = useState();
  const [reschedule, setReschedule] = useState({
    bookings: "",
    date: "",
    startTime: "",
    endTime: "",
  });

  const setNewArr = (arg) => {
    setSelectedRows(arg);
  };

  const closeModal = () => {
    setModal(false);
  };

  const openModal = () => {
    setModal(true);
  };

  const handleOnChange = (e) => {
    setReschedule({ ...reschedule, [e.target.name]: e.target.value });
  };

  const rescheduleJobs = async () => {
    if (reschedule.date === "") {
      info_toaster("Please Select Date");
    } else if (reschedule.startTime === "") {
      info_toaster("Please Select Start Time");
    } else if (reschedule.endTime === "") {
      info_toaster("Please Select End Time");
    } else if (selectedRows.bookings === "") {
      info_toaster("Please Select At Least One Booking");
    } else {
      setLoader(true);
      const bookingIds = selectedRows?.map((e) => e.id);
      const res = await PutApi("self-re-schedule-expired-jobs/pickup", {
        bookings: bookingIds,
        date: reschedule.date,
        startTime: reschedule.startTime,
        endTime: reschedule.endTime,
      });
      if (res?.data?.status === "1") {
        reFetch();
        success_toaster(res?.data?.message);
        setLoader(false);
        setModal(false);
        setReschedule({
          bookings: "",
          date: "",
          startTime: "",
          endTime: "",
        });
      } else {
        error_toaster(res?.data?.message);
        setLoader(false);
      }
    }
  };

  const columns = [
    {
      name: "Sr #",
      selector: (row) => row.Sr,
      sortable: true,
    },
    {
      name: "Tracking Id",
      selector: (row) => row.TrackingId,
      sortable: true,
    },
    {
      name: "Pickup Date",
      selector: (row) => row.pickupDate,
    },
    {
      name: "Pickup Start Time",
      selector: (row) => row.pickupStartTime,
    },
    {
      name: "Pickup End Time",
      selector: (row) => row.pickupEndTime,
    },
    {
      name: "Pickup Address",
      selector: (row) => row.pickupAddress,
    },
  ];

  const pickupJobs = () => {
    const filteredArray = getData?.data?.pickupJobs?.filter(
      (book) =>
        search === "" ||
        select === null ||
        ((book?.trackingId).toLowerCase().includes(search.toLowerCase()) &&
          select.value === "1")
      //   ||
      // (book?.dropoffDate !== null &&
      //   (book?.dropoffDate).includes(search) &&
      //   select.value === "2")
    );
    return filteredArray;
  };

  const datas = [];
  pickupJobs()?.map((ele, index) =>
    datas.push({
      Sr: index + 1,
      TrackingId: ele?.trackingId,
      date: ele?.dropoffDate,
      pickupAddress:
        ele?.pickupAddress?.postalCode +
        " " +
        ele?.pickupAddress?.secondPostalCode,
      pickupDate: moment(ele?.pickupDate).format("DD-MM-YYYY"),
      pickupStartTime: moment(ele?.pickupStartTime, "HH:mm").format("hh:mm A"),
      pickupEndTime: moment(ele?.pickupEndTime, "HH:mm").format("hh:mm A"),
    })
  );

  return getData?.length === 0 ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>PPS - In Transit</title>
      </Helmet>
      <Layout
        extend={true}
        data={
          <section>
            <h1>Expired Jobs(Pickup)</h1>
            <div>
              <div className="flex items-center justify-end">
                <div className="flex gap-x-4 items-center">
                  <input
                    disabled={select === null || select === "" ? true : false}
                    value={search}
                    onChange={(e) =>
                      setSearch(e.target.value.replace(/\+/g, ""))
                    }
                    type="search"
                    id="search"
                    name="search"
                    placeholder="Search..."
                    className="bg-white px-4 py-2 rounded font-normal text-xl placeholder:text-opacity-40 md:w-96 focus:outline-none disabled:opacity-70 disabled:cursor-not-allowed"
                  />
                  <div className="w-60">
                    <Select
                      value={select}
                      onChange={(e) => {
                        setSelect(e);
                        e === null && setSearch("");
                      }}
                      options={[
                        { value: "1", label: "Tracking Id" },
                        // { value: "2", label: "Date" },
                      ]}
                      inputId="dbs"
                      placeholder="Filter..."
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end my-5">
                <button
                  className="bg-themePersian border border-themePersian text-white text-lg px-10 py-2.5 rounded-md hover:bg-transparent hover:text-themePersian 
                  duration-200"
                  onClick={openModal}
                >
                  Reschedule
                </button>
              </div>

              <div>
                <Modal
                  className="font-feixen"
                  onClose={closeModal}
                  isOpen={modal}
                  isCentered
                  size={"2xl"}
                >
                  <ModalOverlay style={{ background: "rgba(0,0,0,0.1)" }} />
                  <ModalContent>
                    <ModalHeader className="text-center text-themePersian">
                      Reschedule Expired Jobs
                    </ModalHeader>
                    <ModalCloseButton />
                    {loader ? (
                      <div className="h-[300px]">
                        <MiniLoaderTwo />
                      </div>
                    ) : (
                      <ModalBody>
                        <div className="grid grid-cols-2 gap-5">
                          <div className="flex flex-col gap-y-2">
                            <label
                              htmlFor="date"
                              className="text-themePersian font-medium"
                            >
                              Select Date
                            </label>
                            <input
                              value={reschedule.date}
                              type="date"
                              name="date"
                              className="bg-gray-100 px-2 py-2 rounded-md"
                              onChange={handleOnChange}
                            />
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <label
                              htmlFor="date"
                              className="text-themePersian font-medium"
                            >
                              Select Start Time
                            </label>
                            <input
                              value={reschedule.startTime}
                              type="time"
                              name="startTime"
                              className="bg-gray-100 px-2 py-2 rounded-md"
                              onChange={handleOnChange}
                            />
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <label
                              htmlFor="date"
                              className="text-themePersian font-medium"
                            >
                              Select End Time
                            </label>
                            <input
                              value={reschedule.endTime}
                              type="time"
                              name="endTime"
                              className="bg-gray-100 px-2 py-2 rounded-md"
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>

                        <div className="flex justify-end mt-5">
                          <button
                            className="bg-themePersian border border-themePersian text-white px-10 py-2.5 rounded-md hover:bg-white hover:text-themePersian 
                        duration-200"
                            onClick={rescheduleJobs}
                          >
                            Reschedule
                          </button>
                        </div>
                      </ModalBody>
                    )}
                  </ModalContent>
                </Modal>
              </div>
              <DataTable
                columns={columns}
                data={datas}
                pagination
                customStyles={customStyles}
                selectableRows
                onSelectedRowsChange={({ selectedRows }) =>
                  setNewArr(selectedRows)
                }
              />
            </div>
          </section>
        }
      />
    </>
  );
}
