import axios from "axios";

const API = axios.create({
  baseURL: "https://backend.pps507.com",
});

export default API;


export const BASE_URL = "https://backend.pps507.com/warehouse/"
export const IMG_URL = "https://backend.pps507.com/"