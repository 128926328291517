// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "../layout/Layout";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/TableStyle";
import PostAPI from "../../api/PostAPI";
import { error_toaster, success_toaster } from "../../toaster/Toaster";

export default function InTransitDetails() {
  const [inTransit, setinTransit] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const [bookingids, setbookingIds] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setinTransit(location.state.inTransit);
  }, [location.state.inTransit]);
  const receivedfromtransporter = async () => {
    setDisabled(true);
    let res = await PostAPI("receivedfromtransporter", {
      bookingIds: location.state.bookingIds.split(","),
      inTransitGroupId: location.state.groupID,
      deliveryWarehouseId: location.state.deliveryWarehouseId,
    });
    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      setbookingIds([]);
      navigate("/incoming_transit");
      setDisabled(false);
    } else {
      setDisabled(false);
      error_toaster(res?.data?.error);
    }
  };
  const columns = [
    {
      name: "Sr #",
      selector: (row) => row.Sr,
      grow: 1,
    },
    {
      name: "Tracking ID",
      selector: (row) => row.tracking,
    },
    {
      name: "Pickup",
      selector: (row) => row.pickup,
    },
    {
      name: "Drop-off",
      selector: (row) => row.dropoff,
    },
    {
      name: "Shipment Type",
      selector: (row) => row.ShipmentType,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
    },
  ];
  const datas = [];
  inTransit
    ?.sort((a, b) => b.id - a.id)
    .map((ele, index) =>
      datas.push({
        Sr: index + 1,
        tracking: ele?.trackingId,
        pickup:
          ele.pickupAddress.postalCode +
          " " +
          ele.dropoffAddress.secondPostalCode,
        dropoff:
          ele.dropoffAddress.postalCode +
          " " +
          ele.dropoffAddress.secondPostalCode,

        ShipmentType: ele?.shipmentType?.title,
        Status: (
          <button className="px-5 lg:px-8 py-3 rounded-md text-[#66CC99] bg-[#C7FFE3]">
            {ele.bookingStatus.title}
          </button>
        ),
      })
    );
  return (
    <>
      <Helmet>
        <title>PPS - In Transit Details</title>
      </Helmet>
      <Layout
        extend={true}
        data={
          <section>
            <h1>Intransit Details</h1>
            {location.state.tabsData === "inOngoing" && (
              <div className="flex justify-end">
                <button
                  className="text-lg font-bold font-feixen text-white bg-themePersian px-3 xl:px-5 py-2 rounded-md"
                  onClick={receivedfromtransporter}
                  disabled={disabled}
                >
                  Parcel Received
                </button>
              </div>
            )}
            <div>
              <DataTable
                columns={columns}
                data={datas}
                customStyles={customStyles}
                pagination
              />
            </div>
          </section>
        }
      />
    </>
  );
}
