// @ts-nocheck
import "./App.css";
import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/pages/auth/SignIn";
import Signup from "./components/pages/auth/Signup";
import Home from "./components/pages/home/Home";
import AllIncoming from "./components/pages/allincoming/AllIncoming";
import OrderCreated from "./components/pages/allincoming/OrderCreated";
import ReachedAtWarehouse from "./components/pages/allincoming/ReachedAtWarehouse";
import ReceivedAtWarehouse from "./components/pages/allincoming/ReceivedAtWarehouse";
import CustomerInfo from "./components/pages/createorder/CustomerInfo";
import ScanOrder from "./components/pages/createorder/ScanOrder";
import InTransit from "./components/pages/intransit/InTransit";
import AllIncomingDetail from "./components/pages/allincoming/AllIncomingDetail";
import ProtectedRoute from "./components/utilities/ProtectedRoute";
import AllOutgoing from "./components/pages/outgoing/AllOutgoing";
import { SkeletonTheme } from "react-loading-skeleton";
import OutgoingTransit from "./components/pages/intransit/OutgoingTransit";
import WaitingToBeScheduled from "./components/pages/outgoing/WaitingToBeScheduled";
import Delivered from "./components/pages/outgoing/Delivered";
import WaitingToBePicked from "./components/pages/outgoing/WaitingToBePicked";
import "react-loading-skeleton/dist/skeleton.css";
import SelfPickDelivered from "./components/pages/selfpickupbookings/SelfPickDelivered";
import SelfPickupWaiting from "./components/pages/selfpickupbookings/SelfPickupWaiting";
import SelfPickUpBookings from "./components/pages/selfpickupbookings/SelfPickUpBookings";
import SetSchedule from "./components/pages/auth/SetSchedule";
import OutgoingTransitDetails from "./components/pages/intransit/OutgoingTransitDetails";
import InTransitDetails from "./components/pages/intransit/InTransitDetails";
import SucessPayment from "./components/pages/payments/SucessPayment";
import CancelPayment from "./components/pages/payments/CancelPayment";
import DriverSignup from "./components/pages/auth/DriverSignup";
import CompletedBooking from "./components/pages/completedbooking/CompletedBooking";
import TrackID from "./components/pages/home/TrackID";
import WarehouseProfile from "./components/pages/home/WarehouseProfile";
import Pagination from "./components/pages/Pagination";
import CreateOrder1 from "./components/pages/createorder/CreateOrder1";
import CreateOrder3 from "./components/pages/createorder/CreateOrder3";
import CreateOrder2 from "./components/pages/createorder/CreateOrder2";
import Csv from "./components/pages/Csv";
import BookingDetails from "./components/pages/home/BookingDetails";
import Drivers from "./components/pages/drivers/Drivers";
import PendingPaymentOrders from "./components/pages/createorder/PendingPaymentOrders";
import DriverDetails from "./components/pages/drivers/DriverDetails";
import ExpiredJobs from "./components/pages/expiredjobs/ExpiredJobs";
import Pickup from "./components/pages/expiredjobs/Pickup";
import Dropoff from "./components/pages/expiredjobs/Dropoff";
import Warehouse from "./components/pages/warehouse/Warehouse";
import AtWarehouse from "./components/pages/warehouse/AtWarehouse";
import ReturnPackages from "./components/pages/warehouse/ReturnPackages";
import PrintReceipt from "./components/pages/auth/PrintReceipt";
// import { listenForUpdates } from "./components/Firebase";

function App() {
  return (
    <>
      <section className="font-feixen bg-[#F4F7FF]">
        <ToastContainer />
        <ChakraProvider>
          <SkeletonTheme baseColor="#EBEBEB" highlightColor="#202053">
            <Router>
              <Routes>
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path="/driver_signup"
                  element={<ProtectedRoute Component={DriverSignup} />}
                />
                <Route
                  path="/drivers"
                  // element={<Drivers />}
                  element={<ProtectedRoute Component={Drivers} />}
                />
                <Route
                  path="/booking_details"
                  element={<ProtectedRoute Component={BookingDetails} />}
                />
                <Route path="/track_id" element={<TrackID />} />
                <Route
                  path="/warehouse_profile"
                  element={<ProtectedRoute Component={WarehouseProfile} />}
                />
                <Route
                  path="/sucess_payment"
                  element={<ProtectedRoute Component={SucessPayment} />}
                />
                <Route
                  path="/cancel_payment"
                  element={<ProtectedRoute Component={CancelPayment} />}
                />
                <Route path="/set_schedule" element={<SetSchedule />} />
                <Route path="/print_receipt" element={<PrintReceipt />} />

                <Route path="/" element={<ProtectedRoute Component={Home} />} />
                <Route
                  path="/create_order/step_one"
                  element={<ProtectedRoute Component={CreateOrder1} />}
                />
                <Route
                  path="/create_order/step_two"
                  element={<ProtectedRoute Component={CreateOrder2} />}
                />
                <Route
                  path="/create_order/step_three"
                  element={<ProtectedRoute Component={CreateOrder3} />}
                />
                <Route
                  path="/customer_info"
                  element={<ProtectedRoute Component={CustomerInfo} />}
                />
                <Route
                  path="/scan_order"
                  element={<ProtectedRoute Component={ScanOrder} />}
                />
                <Route
                  path="/warehouse"
                  element={<ProtectedRoute Component={Warehouse} />}
                />
                <Route
                  path="/at_warehouse"
                  element={<ProtectedRoute Component={AtWarehouse} />}
                />
                <Route
                  path="/returned_packages"
                  element={<ProtectedRoute Component={ReturnPackages} />}
                />
                <Route
                  path="/allincoming"
                  element={<ProtectedRoute Component={AllIncoming} />}
                />
                <Route
                  path="/order_created"
                  element={<ProtectedRoute Component={OrderCreated} />}
                />
                <Route
                  path="/reached_at_warehouse"
                  element={<ProtectedRoute Component={ReachedAtWarehouse} />}
                />
                <Route
                  path="/require_transportation"
                  element={<ProtectedRoute Component={ReceivedAtWarehouse} />}
                />
                <Route
                  path="/all_incoming_detail"
                  element={<ProtectedRoute Component={AllIncomingDetail} />}
                />
                <Route
                  path="/incoming_transit"
                  element={<ProtectedRoute Component={InTransit} />}
                />
                <Route
                  path="/incoming_transit_details"
                  element={<ProtectedRoute Component={InTransitDetails} />}
                />
                <Route
                  path="/outgoing_transit"
                  element={<ProtectedRoute Component={OutgoingTransit} />}
                />
                <Route
                  path="/outgoing_transit_details"
                  element={
                    <ProtectedRoute Component={OutgoingTransitDetails} />
                  }
                />
                <Route
                  path="/all_outgoing"
                  element={<ProtectedRoute Component={AllOutgoing} />}
                />
                <Route
                  path="/waiting_to_be_scheduled"
                  element={<ProtectedRoute Component={WaitingToBeScheduled} />}
                />
                <Route
                  path="/waiting_to_be_picked"
                  element={<ProtectedRoute Component={WaitingToBePicked} />}
                />
                <Route
                  path="/delivered"
                  element={<ProtectedRoute Component={Delivered} />}
                />
                <Route
                  path="/self_pick_up_bookings"
                  element={<ProtectedRoute Component={SelfPickUpBookings} />}
                />
                <Route
                  path="/self_pick_delivered"
                  element={<ProtectedRoute Component={SelfPickDelivered} />}
                />
                <Route
                  path="/self_pickup_waiting"
                  element={<ProtectedRoute Component={SelfPickupWaiting} />}
                />
                <Route
                  path="/completed_bookings"
                  element={<ProtectedRoute Component={CompletedBooking} />}
                />
                <Route
                  path="/expired-jobs"
                  element={<ProtectedRoute Component={ExpiredJobs} />}
                />
                <Route
                  path="/expired-jobs-pickup"
                  element={<ProtectedRoute Component={Pickup} />}
                />
                <Route
                  path="/expired-jobs-dropoff"
                  element={<ProtectedRoute Component={Dropoff} />}
                />
                <Route
                  path="/pagination_check"
                  element={<ProtectedRoute Component={Pagination} />}
                />
                <Route
                  path="/test"
                  element={<ProtectedRoute Component={Csv} />}
                />
                <Route
                  path="/pending_payement_orders"
                  element={<ProtectedRoute Component={PendingPaymentOrders} />}
                />
                <Route
                  path="/driver_details"
                  element={<ProtectedRoute Component={DriverDetails} />}
                />
              </Routes>
            </Router>
          </SkeletonTheme>
        </ChakraProvider>
      </section>
    </>
  );
}

export default App;
