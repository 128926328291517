import {createTheme} from 'react-data-table-component'



export const theme = createTheme("myTheme", {
    background: {
        default: "transparent",
    },
    divider: {
        default: "transparent",
    },
});

export const customStyles = {
    table: {
        style: {
            color: 'black',
            backgroundColor: 'transparent',
        },
    },
    tableWrapper: {
        style: {
            display: 'table',
        },
    },
    headRow: {
        style: {
            backgroundColor: 'transparent',
            minHeight: '52px',
            marginBottom: '10px',
            borderBottomWidth: 'none',
        },
    },
    head: {
		style: {
			fontSize: '16px',
			fontWeight: 600,
		},
	},
    rows: {
        style: {
            minHeight: '72px', // override the row height
            padding : '10px',
            wordBreak: 'break-word',
            marginBottom: '10px',
            borderRadius : '8px',
            borderBottomStyle: 'none',
            borderBottomWidth: 'none',
            borderBottomColor: 'none',
            '&:not(:last-of-type)': {
                borderBottomStyle: 'none',
                borderBottomWidth: 'none',
                borderBottomColor: 'none',
            },
            '&:hover': {
                // cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            marginTop: '8px',
        },
        draggingStyle: {
            cursor: 'pointer',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            wordBreak: 'break-word',
        },
    },

};