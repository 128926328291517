import React from "react";
import { MdOutlineDoneOutline } from "react-icons/md";

export default function StatusPill(props) {
  return (
    <div className="space-y-1">
      {props.pillStatus === "completed" ? (
        <div className="bg-[#202053] h-12 w-12 rounded-full relative">
          <div className="text-white absolute top-[50%] left-[50%]  -translate-x-[50%] -translate-y-[50%]">
            <MdOutlineDoneOutline />
          </div>
        </div>
      ) : props.pillStatus === "inProcess" ? (
        <div className="bg-[#519DE966] h-12 w-12 rounded-full relative">
          <div className="bg-[#202053] h-5 w-5 rounded-fullest absolute animate-pulse top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"></div>
        </div>
      ) : (
        <div className="bg-white border border-[#202053] h-12 w-12 rounded-full"></div>
      )}
      <h4 className={`font-bold text-sm leading-4 text-black`}>
        {props.title}
      </h4>
      <p className={`font-bold text-xs text-black text-opacity-60 leading-4`}>
        {props.text}
      </p>
      <div className="flex flex-col font-medium text-xs">
        <div>{props.date}</div>
        <div>{props.time}</div>
      </div>
    </div>
  );
}
