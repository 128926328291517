// @ts-nocheck
// @ts-ignore
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { customStyles } from "../../style/TableStyle";
import Layout from "../layout/Layout";
import GetAPI from "../../api/GetAPI";
import Loader from "../../loader/Loader";
import Select from "react-select";
import { BsFillEyeFill } from "react-icons/bs";
import PostAPI from "../../api/PostAPI";
import { useNavigate } from "react-router-dom";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { IMG_URL } from "../../api/API";
import moment from "moment";

export default function CompletedBooking() {
  const [tabs, setTabs] = useState("receivingCompleted");
  const [search, setSearch] = useState("");
  const [select, setSelect] = useState("");
  const [podModal, setPodModal] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [podImage, setPodImage] = useState("");
  const [signImage, setSignImage] = useState("");
  const { getData } = GetAPI("compeltedbookings");
  const navigate = useNavigate();

  const viewDetails = async (id) => {
    let res = await PostAPI("bookingdetails", {
      bookingId: id,
    });
    if (res?.data?.status === "1") {
      navigate("/all_incoming_detail", {
        state: { res: res?.data },
      });
      success_toaster(res?.data?.message);
    } else {
      error_toaster(res?.data?.message);
    }
  };

  const openPodModal = (image) => {
    setPodModal(true);
    setPodImage(image);
  };

  const openSignModal = (image) => {
    setSignModal(true);
    setSignImage(image);
  };

  const columns = [
    {
      name: "Sr #",
      selector: (row) => row.Sr,
      sortable: true,
    },
    {
      name: "Tracking Id",
      selector: (row) => row.TrackingId,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.From,
    },
    {
      name: "To",
      selector: (row) => row.To,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "200px",
    },
  ];
  const deliveryCompleted = () => {
    const filteredArray = getData?.data?.deliveryCompleted?.filter(
      (book) =>
        search === "" ||
        select === null ||
        ((book?.trackingId).toLowerCase().includes(search.toLowerCase()) &&
          select.value === "1") ||
        (book?.dropoffDate !== null &&
          (book?.dropoffDate).includes(search) &&
          select.value === "2")
    );
    return filteredArray;
  };
  const receivingCompleted = () => {
    const filteredArray = getData?.data?.receivingCompleted?.filter(
      (book) =>
        search === "" ||
        select === null ||
        ((book?.trackingId).toLowerCase().includes(search.toLowerCase()) &&
          select.value === "1") ||
        (book?.dropoffDate !== null &&
          (book?.dropoffDate).includes(search) &&
          select.value === "2")
    );
    return filteredArray;
  };

  const datas = [];
  if (tabs === "receivingCompleted") {
    deliveryCompleted()?.map((ele, index) =>
      datas.push({
        Sr: index + 1,
        TrackingId: ele?.trackingId,
        date: moment(ele?.dropoffDate).format("DD-MM-YYYY"),
        From:
          ele?.pickupAddress?.postalCode +
          " " +
          ele?.pickupAddress?.secondPostalCode,
        To:
          ele?.dropoffAddress?.postalCode +
          " " +
          ele?.dropoffAddress?.secondPostalCode,
        Status: (
          <div className="border border-themeOrange text-themeOrange rounded-md p-1">
            {" "}
            {ele?.bookingStatus?.title}
          </div>
        ),
        action: (
          <>
            <div className="flex items-center gap-2">
              <button
                onClick={() => viewDetails(ele?.id)}
                className="text-themePersian"
              >
                <BsFillEyeFill size={20} />
              </button>

              <button
                className="text-themePersian border border-themePersian p-1 rounded-md"
                onClick={() => {
                  openPodModal(ele?.PODImage);
                }}
              >
                POD
              </button>
              <button
                className="text-themePersian border border-themePersian p-1 rounded-md"
                onClick={() => {
                  openSignModal(ele?.signatureImage);
                }}
              >
                Signature
              </button>
            </div>
          </>
        ),
      })
    );
  } else if (tabs === "deliveryCompleted") {
    receivingCompleted()?.map((ele, index) =>
      datas.push({
        Sr: index + 1,
        TrackingId: ele?.trackingId,
        date: moment(ele?.dropoffDate).format("DD-MM-YYYY"),
        From:
          ele?.pickupAddress?.postalCode +
          " " +
          ele?.pickupAddress?.secondPostalCode,
        To:
          ele?.dropoffAddress?.postalCode +
          " " +
          ele?.dropoffAddress?.secondPostalCode,
        Status: (
          <div className="border border-themeOrange text-themeOrange rounded-md p-1">
            {" "}
            {ele?.bookingStatus?.title}
          </div>
        ),
        action: (
          <>
            <div className="flex items-center gap-2">
              <button
                onClick={() => viewDetails(ele?.id)}
                className="text-themePersian"
              >
                <BsFillEyeFill size={20} />
              </button>

              <button
                className="text-themePersian border border-themePersian p-1 rounded-md"
                onClick={() => {
                  openPodModal(ele?.PODImage);
                }}
              >
                POD
              </button>
              <button
                className="text-themePersian border border-themePersian p-1 rounded-md"
                onClick={() => {
                  openSignModal(ele?.signatureImage);
                }}
              >
                Signature
              </button>
            </div>
          </>
        ),
      })
    );
  }

  return getData?.length === 0 ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>PPS - In Transit</title>
      </Helmet>
      <Layout
        extend={true}
        data={
          <section>
            <h1>Completed Bookings</h1>
            <div>
              <div className="flex items-center gap-5 justify-between">
                <div className="flex my-5 ">
                  <button
                    onClick={() => setTabs("receivingCompleted")}
                    className={`font-medium text-sm border border-themePersian px-4 py-3 ${
                      tabs === "receivingCompleted"
                        ? "bg-themePersian text-white"
                        : "text-themePersian"
                    }`}
                  >
                    Delivery Completed
                  </button>
                  <button
                    onClick={() => setTabs("deliveryCompleted")}
                    className={`font-medium text-sm border border-themePersian px-4 py-3 ${
                      tabs === "deliveryCompleted"
                        ? "bg-themePersian text-white"
                        : "text-themePersian"
                    }`}
                  >
                    Receiving Completed
                  </button>
                </div>
                <div className="flex gap-x-4 items-center">
                  <input
                    disabled={select === null || select === "" ? true : false}
                    value={search}
                    onChange={(e) =>
                      setSearch(e.target.value.replace(/\+/g, ""))
                    }
                    type="search"
                    id="search"
                    name="search"
                    placeholder="Search..."
                    className="bg-white px-4 py-2 rounded font-normal text-xl placeholder:text-opacity-40 md:w-96 focus:outline-none disabled:opacity-70 disabled:cursor-not-allowed"
                  />
                  <div className="w-40">
                    <Select
                      value={select}
                      onChange={(e) => {
                        setSelect(e);
                        e === null && setSearch("");
                      }}
                      options={[
                        { value: "1", label: "Tracking Id" },
                        { value: "2", label: "Date" },
                      ]}
                      inputId="dbs"
                      placeholder="Filter..."
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={datas}
                pagination
                customStyles={customStyles}
              />
            </div>

            <Modal
              onClose={() => setPodModal(false)}
              isOpen={podModal}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  <div className="text-themePersian text-center">POD Image</div>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody padding={5}>
                  <div className="flex justify-center">
                    <img src={`${IMG_URL}${podImage}`} alt="pod" />
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal
              onClose={() => setSignModal(false)}
              isOpen={signModal}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  <div className="text-themePersian text-center">
                    Signature Image
                  </div>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <div className="flex justify-center">
                    <img src={`${IMG_URL}${signImage}`} alt="signature" />
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>
          </section>
        }
      />
    </>
  );
}
