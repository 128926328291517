// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import Select from "react-select";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { MiniLoaderTwo } from "../../loader/Loader";
import moment from "moment";

const SetSchedule = () => {
  const SetUserTimingAPI = async (url, postData) => {
    let config = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    };
    try {
      let response = await axios.post(
        "https://backend.pps507.com/" + url,
        postData,
        config
      );
      return response;
    } catch (error) {}
  };

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#D9D9D9",
      outline: "none",
      boxShadow: state.isFocused ? `0 0 0 1px lightGray` : "none",
      cursor: "pointer",
      border: "1px solid #E2E8F0",
      borderRadius: "6px",
      paddingTop: "3px",
      paddingBottom: "3px",
      outerWidth: "300px",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "black" : "black", // You can change these colors as needed
      "&:hover": {
        color: "black", // You can change this color as needed
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "white" : "white",
      color: "black",
      "&:hover": {
        background: "#D9D9D9", // Background color of option on hover
      },
    }),
  };

  const options = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  // Get the current date in YYYY-MM-DD format
  const currentDate = new Date().toISOString().split("T")[0];

  const [data, setData] = useState({
    email: "",
    dropoffDate: "",
    dropoffStartTime: "",
    dropoffEndTime: "",
  });
  const location = useLocation();
  const [keyData, setKeyData] = useState("");
  const [leaveAtDoor, setLeaveAtDoor] = useState("");
  const [btnDisable, setbtnDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [succesModal, setSuccessModal] = useState(false);

  const openModal = () => {
    if (data.email === "") {
      error_toaster("Please Fill Email");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      error_toaster("Please enter a valid email address.");
    } else if (data.dropoffDate === "") {
      error_toaster("Please Select Day and Time");
    } else if (data.dropoffStartTime === "") {
      error_toaster("Please Select Start Day");
    } else if (data.dropoffEndTime === "") {
      error_toaster("Please Select End Day");
    } else if (data.dropoffStartTime === data.dropoffEndTime) {
      error_toaster("Start time and end time cannot be the same.");
    } else if (leaveAtDoor === "") {
      error_toaster("Please Select Leave at door");
    } else {
      setModal(true);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const key = urlParams.get("key");
    setKeyData(key);
  }, [location.search]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let res = await SetUserTimingAPI(
      `customer/dropoffscheduleweb?key=${keyData}`,
      {
        email: data.email,
        dropoffDate: data.dropoffDate,
        dropoffStartTime: data.dropoffStartTime,
        dropoffEndTime: data.dropoffEndTime,
        leaveAtDoor: leaveAtDoor?.label === "Yes" ? true : false,
      }
    );
    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      setbtnDisable(!btnDisable);
      setSuccessModal(true);
      setLoader(false);
      setModal(false);
    } else {
      error_toaster(res?.data?.error);
      setModal(false);
      setLoader(false);
    }
  };

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const convertTo12Hour = (time) => {
    if (time && time !== "") {
      let hours = parseInt(time.substr(0, 2));
      let minutes = time.substr(3, 2);
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${ampm}`;
    }
  };

  return (
    <>
      <div className="bg-white">
        <div className="bg-themePersian py-2">
          <div className="flex justify-center">
            <img src="/images/logo.webp" alt="logo" className="w-20" />
          </div>
        </div>

        <div className="flex flex-col gap-5 justify-center items-center w-4/5 mx-auto py-10">
          <div>
            <img src="/images/dropoff.webp" alt="dropoff" className="w-20" />
          </div>
          <h1 className="text-2xl font-medium">Add Drop Off Details</h1>
          <div className="space-y-5">
            <div className="flex flex-col gap-2">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none w-96"
                value={data.email}
                onChange={onChange}
                type="email"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="dropoffDate">Drop off Date</label>
              <input
                id="dropoffDate"
                name="dropoffDate"
                className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none"
                value={data.dropoffDate}
                onChange={onChange}
                min={currentDate}
                type="date"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="dropoffStartTime">Drop off Start Time</label>
              <input
                id="dropoffStartTime"
                name="dropoffStartTime"
                className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none"
                value={data.dropoffStartTime}
                onChange={onChange}
                type="time"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="dropoffEndTime">Drop off End Time</label>
              <input
                id="dropoffEndTime"
                name="dropoffEndTime"
                className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none"
                value={data.dropoffEndTime}
                onChange={onChange}
                type="time"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="=leaveAtDoor">Leave at Door</label>
              <Select
                onChange={setLeaveAtDoor}
                className="text-base font-medium border border-transparent rounded-lg w-96 focus:outline-none"
                options={options}
                styles={selectStyles}
              ></Select>
            </div>

            <button
              className="block text-center bg-themePersian text-white rounded-md font-medium text-xl 
              border border-themePersian py-2 w-96 hover:bg-transparent hover:text-themePersian"
              onClick={openModal}
            >
              Submit
            </button>
          </div>
        </div>

        <Modal
          onClose={() => {
            setModal(false);
          }}
          isOpen={modal}
          isCentered
        >
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>
              <div className="flex justify-center">
                Confirm Schedule Delivery
              </div>
            </ModalHeader>
            <ModalCloseButton />
            {loader ? (
              <div className="h-[200px]">
                <MiniLoaderTwo />
              </div>
            ) : (
              <ModalBody>
                <div>
                  <div className="text-base font-medium text-center">
                    Are you sure you want to schedule a delivery for{" "}
                    {moment(data.dropoffDate).format("DD-MM-YYYY")} from{" "}
                    {convertTo12Hour(data.dropoffStartTime)} to{" "}
                    {convertTo12Hour(data.dropoffEndTime)}?
                  </div>

                  <div className="flex justify-end gap-2 mt-4">
                    <button
                      className="bg-themePersian text-white border border-themePersian rounded-md 
                  py-1.5 px-3"
                      onClick={handlesubmit}
                    >
                      Yes
                    </button>
                    <button
                      className="bg-themePersian text-white border border-themePersian rounded-md 
                  py-1.5 px-3"
                      onClick={() => setModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </ModalBody>
            )}
          </ModalContent>
        </Modal>

        <Modal
          onClose={() => {
            setSuccessModal(false);
          }}
          isOpen={succesModal}
          isCentered
        >
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>
              <div className="flex justify-center text-themePersian">
                Delivery Scheduled Successfully
              </div>
            </ModalHeader>
            <ModalCloseButton />
            {loader ? (
              <div className="h-[200px]">
                <MiniLoaderTwo />
              </div>
            ) : (
              <ModalBody>
                <div className="space-y-3 pb-5">
                  <div className="text-base font-medium text-center">
                    Your delivery has been scheduled for{" "}
                    {moment(data.dropoffDate).format("DD-MM-YYYY")} from{" "}
                    {convertTo12Hour(data.dropoffStartTime)} to{" "}
                    {convertTo12Hour(data.dropoffEndTime)}. Thanks for Prefering
                    us you can now close this tab
                  </div>
                  {/* <div className="text-base font-medium text-center uppercase text-themePersian">
                    Thanks for Prefering us you can now close this tab
                  </div> */}
                </div>
              </ModalBody>
            )}
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default SetSchedule;
