// @ts-nocheck
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/TableStyle";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import PostAPI from "../../api/PostAPI";
import Layout from "../layout/Layout";
import { BsFillEyeFill } from "react-icons/bs";
import Loader from "../../loader/Loader";
import GetAPI from "../../api/GetAPI";
import moment from "moment";

export default function InTransit() {
  const { getData } = GetAPI("intransitgroups");
  const [tabs, setTabs] = useState("inOngoing");
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const viewDetails = async (id, tabsData, deliveryWarehouseId, bookingIds) => {
    setDisabled(true);
    let res = await PostAPI("intransitgroupdetails", {
      inTransitGroupId: id,
    });

    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      navigate("/incoming_transit_details", {
        state: {
          inTransit: res?.data?.data,
          tabsData,
          groupID: id,
          deliveryWarehouseId,
          bookingIds,
        },
      });
      setDisabled(false);
    } else {
      error_toaster(res?.data?.error);
      setDisabled(false);
    }
  };

  const ongoingFilteredArray = getData?.data?.incoming?.inOngoing.filter(
    (item) =>
      item.transitId
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.setOffDate
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.arrivalDate
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString())
  );
  const completedFilteredArray = getData?.data?.incoming?.inCompleted.filter(
    (item) =>
      item.transitId
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.setOffDate
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.arrivalDate
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString())
  );
  const columns = [
    {
      name: "Sr #",
      selector: (row) => row.Sr,
      grow: 1,
    },
    {
      name: "Transit Id",
      selector: (row) => row.transitId,
    },
    {
      name: "From",
      selector: (row) => row.From,
    },
    {
      name: "To",
      selector: (row) => row.To,
    },
    {
      name: "Parcel (Quantity)",
      selector: (row) => row.quantity,
    },
    {
      name: "Set Off Date",
      selector: (row) => row.setOffDate,
    },
    tabs === "inCompleted" && {
      name: "Arrival Date",
      selector: (row) => row.arrivalDate,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Transporter Guy",
      selector: (row) => row.Driver,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const datas = [];
  if (tabs === "inOngoing") {
    ongoingFilteredArray?.map((ele, index) =>
      datas.push({
        Sr: index + 1,
        transitId: <div className="font-semibold">{ele?.transitId}</div>,
        setOffDate: (
          <div>
            {moment(ele?.setOffDate).format("DD-MM-YYYY")} <br />{" "}
            {moment(ele?.setOffTime, "HH:mm").format("hh:mm A")}
          </div>
        ),
        From: (
          <div>
            <span className="font-semibold">
              {ele?.receivingWarehouseT?.name}
            </span>
            <br /> ({ele?.receivingWarehouseT?.addressDB?.postalCode}{" "}
            {ele?.receivingWarehouseT?.addressDB?.secondPostalCode})
          </div>
        ),
        To: (
          <div>
            <span className="font-semibold">
              {ele?.deliveryWarehouseT?.name}
            </span>
            <br /> ({ele?.deliveryWarehouseT?.addressDB?.postalCode}{" "}
            {ele?.deliveryWarehouseT?.addressDB?.secondPostalCode})
          </div>
        ),
        quantity: ele?.quantity,
        status: (
          <div className="border border-themeOrange text-themeOrange rounded-md p-1">
            {ele?.status}
          </div>
        ),
        Driver: (
          <div>
            <span className="font-semibold">
              {ele?.user?.firstName} {ele?.user?.lastName}
            </span>
            <br /> ({ele?.user?.countryCode}
            {ele?.user?.phoneNum})
          </div>
        ),
        action: (
          <button
            onClick={() =>
              viewDetails(
                ele?.id,
                "inOngoing",
                ele?.deliveryWarehouseId,
                ele?.bookingIds
              )
            }
            className="p-1 bg-themePersian text-white"
          >
            <BsFillEyeFill size={20} />
          </button>
        ),
      })
    );
  } else if (tabs === "inCompleted") {
    completedFilteredArray?.map((ele, index) =>
      datas.push({
        Sr: index + 1,
        transitId: <div className="font-semibold">{ele?.transitId}</div>,
        arrivalDate: (
          <>
            {moment(ele?.arrivalDate).format("DD-MM-YYYY")} <br /> {moment(ele?.arrivalTime, "HH:mm").format("hh:mm A")}
          </>
        ),
        setOffDate: (
          <div>
            {moment(ele?.setOffDate).format("DD-MM-YYYY")} <br />{" "}
            {moment(ele?.setOffTime, "HH:mm").format("hh:mm A")}
          </div>
        ),
        From: (
          <div>
            <span className="font-semibold">
              {ele?.receivingWarehouseT?.name}
            </span>{" "}
            <br /> ({ele?.receivingWarehouseT?.addressDB?.postalCode}{" "}
            {ele?.receivingWarehouseT?.addressDB?.secondPostalCode})
          </div>
        ),
        To: (
          <div>
            <span className="font-semibold">
              {ele?.deliveryWarehouseT?.name}
            </span>
            <br /> ({ele?.deliveryWarehouseT?.addressDB?.postalCode}{" "}
            {ele?.deliveryWarehouseT?.addressDB?.secondPostalCode})
          </div>
        ),
        quantity: ele?.quantity,
        status: (
          <div className="border  border-themeGreen text-themeGreen rounded-md p-1">
            {" "}
            {ele?.status}
          </div>
        ),
        Driver: (
          <div>
            <span className=" font-semibold p-0">
              {ele?.user?.firstName} {ele?.user?.lastName}
            </span>{" "}
            <br />({ele?.user?.countryCode}
            {ele?.user?.phoneNum})
          </div>
        ),
        action: (
          <button
            onClick={() => viewDetails(ele?.id, "inCompleted")}
            className="p-1 bg-themePersian text-white"
            disabled={disabled}
          >
            <BsFillEyeFill size={20} />
          </button>
        ),
      })
    );
  }

  return getData?.length === 0 ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>PPS - In Transit</title>
      </Helmet>
      <Layout
        extend={true}
        data={
          <section>
            <div className="space-y-4">
              <h1>InTransit Details</h1>
              <div className="grid md:grid-cols-3 gap-4">
                <input
                  className="text-base font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none"
                  type="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                />
              </div>
            </div>
            <div>
              <div className="flex mb-5 gap-x-5 justify-center">
                <button
                  onClick={() => setTabs("inOngoing")}
                  className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                    tabs === "inOngoing"
                      ? "bg-themePersian text-white"
                      : "text-themePersian"
                  }`}
                >
                  On-going
                </button>
                <button
                  onClick={() => setTabs("inCompleted")}
                  className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                    tabs === "inCompleted"
                      ? "bg-themePersian text-white"
                      : "text-themePersian"
                  }`}
                >
                  Completed
                </button>
              </div>
              <DataTable
                columns={columns}
                data={datas}
                customStyles={customStyles}
              />
            </div>
          </section>
        }
      />
    </>
  );
}
