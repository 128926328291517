// @ts-nocheck
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/TableStyle";
import { Helmet } from "react-helmet";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import { useNavigate } from "react-router-dom";
import PostAPI from "../../api/PostAPI";
import Layout from "../layout/Layout";
import { BsFillEyeFill } from "react-icons/bs";
import Loader from "../../loader/Loader";
import GetAPI from "../../api/GetAPI";
import moment from "moment";

export default function OutgoingTransit() {
  const { getData } = GetAPI("intransitgroups");

  const [tabs, setTabs] = useState("outOngoing");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const viewDetails = async (id) => {
    let res = await PostAPI("intransitgroupdetails", {
      inTransitGroupId: id,
    });

    if (res?.data?.status === "1") {
      success_toaster(res?.data?.message);
      navigate("/outgoing_transit_details", {
        state: { OutgoingTransit: res?.data?.data },
      });
    } else {
      error_toaster(res?.data?.error);
    }
  };

  const ongoingFilteredArray = getData?.data?.outgoing?.outOngoing.filter(
    (item) =>
      item.transitId
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.setOffDate
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString())
  );
  const completedFilteredArray = getData?.data?.outgoing?.outCompleted.filter(
    (item) =>
      item.transitId
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.setOffDate
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.arrivalDate
        .toLowerCase()
        .includes(searchQuery.toLowerCase().toString())
  );

  const columns = [
    {
      name: "Sr #",
      selector: (row) => row.Sr,
    },
    {
      name: "Transit Id",
      selector: (row) => row.transitId,
      grow: 2,
    },
    {
      name: "From",
      selector: (row) => row.Receiving,
    },
    {
      name: "To",
      selector: (row) => row.Delivery,
    },
    {
      name: "Parcel (Quantity)",
      selector: (row) => row.quantity,
    },
    {
      name: "Set Off Date",
      selector: (row) => row.setOffDate,
    },
    tabs === "outCompleted" && {
      name: "Arrival Date",
      selector: (row) => row.arrivalDate,
    },

    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Transporter",
      selector: (row) => row.Driver,
    },
    {
      name: "Action",
      selector: (row) => row.Status,
    },
  ];

  const datas = [];

  if (tabs === "outOngoing") {
    ongoingFilteredArray
      ?.sort((a, b) => b.id - a.id)
      ?.map((ele, index) =>
        datas.push({
          Sr: index + 1,
          transitId: <div className=" font-semibold">{ele?.transitId}</div>,
          Receiving: (
            <div>
              {" "}
              <span className="font-semibold">
                {ele?.receivingWarehouseT?.name}
              </span>{" "}
              <br /> ({ele?.receivingWarehouseT?.addressDB.postalCode}{" "}
              {ele?.receivingWarehouseT?.addressDB.secondPostalCode}){" "}
            </div>
          ),
          Delivery: (
            <div>
              {" "}
              <span className="font-semibold">
                {ele?.deliveryWarehouseT?.name}
              </span>{" "}
              <br /> ({ele?.deliveryWarehouseT?.addressDB.postalCode}{" "}
              {ele?.deliveryWarehouseT?.addressDB.secondPostalCode}){" "}
            </div>
          ),
          quantity: ele?.quantity,
          setOffDate: (
            <div>
              {moment(ele?.setOffDate).format("DD-MM-YYYY")} <br />{" "}
              {moment(ele?.setOffTime, "HH:mm").format("hh:mm A")}
            </div>
          ),
          arrivalDate: (
            <div>
              {moment(ele?.arrivalDate).format("DD-MM-YYYY")} <br />{" "}
              {moment(ele?.arrivalTime, "HH:mm").format("hh:mm A")}
            </div>
          ),
          status: (
            <div className="border border-themeOrange text-themeOrange rounded-md p-1">
              {" "}
              {ele?.status}
            </div>
          ),
          Driver: (
            <div>
              <span className="font-semibold">
                {ele?.user?.firstName + " " + ele?.user?.lastName}
              </span>
              <br /> ({ele?.user?.countryCode}
              {ele?.user?.phoneNum})
            </div>
          ),
          Status: (
            <button
              onClick={() => viewDetails(ele?.id)}
              className="p-1 bg-themePersian text-white"
            >
              <BsFillEyeFill size={20} />
            </button>
          ),
        })
      );
  } else if (tabs === "outCompleted") {
    completedFilteredArray
      ?.sort((a, b) => b.id - a.id)
      ?.map((ele, index) =>
        datas.push({
          Sr: index + 1,
          transitId: <div className=" font-semibold">{ele?.transitId}</div>,
          Receiving: (
            <div>
              {" "}
              <span className=" font-semibold">
                {ele?.receivingWarehouseT?.name}
              </span>{" "}
              <br /> ({ele?.receivingWarehouseT?.addressDB?.postalCode}{" "}
              {ele?.receivingWarehouseT?.addressDB?.secondPostalCode})
            </div>
          ),
          Delivery: (
            <div>
              {" "}
              <span className=" font-semibold">
                {" "}
                {ele?.deliveryWarehouseT?.name}
              </span>{" "}
              <br /> ({ele?.deliveryWarehouseT?.addressDB?.postalCode}{" "}
              {ele?.deliveryWarehouseT?.addressDB?.secondPostalCode})
            </div>
          ),
          quantity: ele?.quantity,
          setOffDate: (
            <div>
              {moment(ele?.setOffDate).format("DD-MM-YYYY")} <br />{" "}
              {moment(ele?.setOffTime, "HH:mm").format("hh:mm A")}
            </div>
          ),
          arrivalDate: (
            <div>
              {" "}
              {moment(ele?.arrivalDate).format("DD-MM-YYYY")} <br />{" "}
              {moment(ele?.arrivalTime, "HH:mm").format("hh:mm A")}
            </div>
          ),
          status: (
            <div className="border border-themeOrange text-themeOrange rounded-md p-1">
              {" "}
              {ele?.status}
            </div>
          ),
          Driver: (
            <div>
              <span className="font-semibold">
                {ele?.user?.firstName + " " + ele?.user?.lastName}
              </span>
              <br /> ({ele?.user?.countryCode}
              {ele?.user?.phoneNum})
            </div>
          ),
          Status: (
            <button
              onClick={() => viewDetails(ele?.id)}
              className="p-1 bg-themePersian text-white"
            >
              <BsFillEyeFill size={20} />
            </button>
          ),
        })
      );
  }

  return getData?.length === 0 ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>PPS - Outgoing Transit</title>
      </Helmet>

      <Layout
        extend={true}
        data={
          <section>
            <div className="space-y-4">
              <h1>Outgoing Transit</h1>
              <div className="grid md:grid-cols-3 gap-4">
                <input
                  className="text-base font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none"
                  type="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                />
              </div>
            </div>
            <div>
              <div className="flex mb-5 justify-center gap-x-5">
                <button
                  onClick={() => setTabs("outOngoing")}
                  className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                    tabs === "outOngoing"
                      ? "bg-themePersian text-white"
                      : "text-themePersian"
                  }`}
                >
                  Ongoing
                </button>
                <button
                  onClick={() => setTabs("outCompleted")}
                  className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                    tabs === "outCompleted"
                      ? "bg-themePersian text-white"
                      : "text-themePersian"
                  }`}
                >
                  Completed
                </button>
              </div>
              <DataTable
                columns={columns}
                data={datas}
                customStyles={customStyles}
              />
            </div>
          </section>
        }
      />
    </>
  );
}
