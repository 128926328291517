// @ts-nocheck
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PostAPI from "../../api/PostAPI";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import Layout from "../layout/Layout";
import GetAPI from "../../api/GetAPI";
import { btnStyle, inputStyle, liveImgURL } from "../../style/Style";

export default function CreateOrder3() {
  const location = useLocation();
  const navigate = useNavigate();
  const [couponName, setCouponName] = useState("");
  const [couponId, setCouponId] = useState("");
  const [couponDiscount, setCouponDiscount] = useState("0");
  const [disabled, setDisabled] = useState(false);

  const onChange = (e) => {
    setCouponName(e.target.value);
  };
  const { getData } = GetAPI("bookingdata");

  const order = location?.state?.order;

  const stateOrder = location?.state?.stateOrder;

  const chargesRes = location?.state?.chargesRes;
  const [createOrder] = useState({
    senderName: order?.senderName,
    senderEmail: order?.senderEmail,
    senderPhone: order?.senderPhone,
    receiverName: order?.receiverName,
    receiverEmail: order?.receiverEmail,
    receiverPhone: order?.receiverPhone,
    instruction: stateOrder?.instruction,
    subTotal: chargesRes?.subTotal,
    discount: couponDiscount,
    total: chargesRes?.subTotal,
    pickupAddressId: order?.pickupAddressId.value,
    dropoffAddressId: order?.dropoffAddressId.value,
    bookingTypeId: stateOrder?.bookingTypeId,
    categoryId: stateOrder?.categoryId.value,
    couponId: "",
    shipmentTypeId: stateOrder?.shipmentTypeId,
    sizeId: stateOrder?.sizeId,
    setScheduleBy: stateOrder?.setScheduleBy,
    distanceCharge: chargesRes?.distanceCharge,
    weightCharge: chargesRes?.weightCharge,
    categoryCharge: chargesRes?.categoryCharge,
    shipmentTypeCharge: chargesRes?.shipmentTypeCharge,
    packingCharge: chargesRes?.packingCharge,
    serviceCharge: chargesRes?.serviceCharge,
    gstCharge: chargesRes?.gstCharge,
  });
  const createBooking = async (e) => {
    e.preventDefault();
    let res = await PostAPI("createbooking", {
      senderName: createOrder?.senderName,
      senderEmail: createOrder?.senderEmail,
      senderPhone: createOrder?.senderPhone,
      receiverName: createOrder?.receiverName,
      receiverEmail: createOrder?.receiverEmail,
      receiverPhone: createOrder?.receiverPhone,
      instruction: createOrder?.instruction,
      subTotal: createOrder?.subTotal,
      discount: couponDiscount,
      total: createOrder?.subTotal,
      pickupAddressId: createOrder?.pickupAddressId,
      dropoffAddressId: createOrder?.dropoffAddressId,
      bookingTypeId: createOrder?.bookingTypeId,
      categoryId: createOrder?.categoryId,
      couponId: 0,
      shipmentTypeId: createOrder?.shipmentTypeId,
      sizeId: createOrder?.sizeId,
      setScheduleBy: createOrder?.setScheduleBy,
      distanceCharge: createOrder?.distanceCharge,
      weightCharge: createOrder?.weightCharge,
      categoryCharge: createOrder?.categoryCharge,
      shipmentTypeCharge: createOrder?.shipmentTypeCharge,
      packingCharge: createOrder?.packingCharge,
      serviceCharge: createOrder?.serviceCharge,
      gstCharge: createOrder?.gstCharge,
    });
    console.log(res);
    if (res?.data?.status === "1" || res?.data?.status === "2") {
      success_toaster(res?.data?.message);
      navigate("/scan_order", { state: { res: res.data } });
    } else {
      error_toaster(res?.data?.message);
    }
  };
  const couponvalidity = async () => {
    if (couponName === "") {
      // error_toaster(couponName);
      error_toaster("Please Enter Coupon Code");
    } else {
      setDisabled(true);
      let res = await PostAPI("couponvalidity", {
        code: couponName,
        senderEmail: location?.state?.order.senderEmail,
      });
      if (res?.data?.status === "1") {
        setDisabled(false);
        setCouponId(res.data.data.couponId);
        setCouponDiscount(res.data.data.discount);
        success_toaster(res?.data?.message);
      } else {
        setDisabled(false);
        error_toaster(res?.data?.error);
      }
    }
  };

  return (
    <div>
      <Layout
        pageTitle=""
        extend={true}
        data={
          <>
            <section className="bg-white rounded-md my-5 md:px-16 px-4 py-5">
              <div className="xl:grid xl:grid-cols-2 md:space-x-2">
                <div className="w-full md:11/12 lg:11/12 xl:w-11/12 2xl:w-4/5">
                  <div className="space-y-7">
                    <h2 className="text-2xl font-medium">Order Summary</h2>
                    <h3 className="text-lg font-bold">Parcel details</h3>
                    <div className="bg-themeGrey rounded-lg p-4 space-y-4">
                      <div className="sm:flex items-center gap-x-5">
                        <div className="px-10">
                          <img
                            className="w-12 m-auto"
                            src={`${liveImgURL}/box.webp`}
                            alt="box"
                          />
                        </div>
                        <div className="space-y-1">
                          <h5 className="text-sm font-normal">Weight</h5>
                          <p className="text-themeBlue3 text-sm font-medium">
                            {getData?.data?.sizeIds?.find(
                              (ele) => ele.id === createOrder?.sizeId
                            )?.weight || ""}
                          </p>
                          <h5 className="text-sm font-normal">Size</h5>
                          <p className="text-themeBlue3 text-sm font-medium">
                            {getData?.data?.sizeIds?.find(
                              (ele) => ele.id === createOrder?.sizeId
                            )?.title || ""}
                          </p>
                        </div>
                      </div>
                      <div className="space-y-1 [&>div>h6]:text-lg [&>div>h6]:font-normal [&>div>h6]:text-black [&>div>h6]:text-opacity-60 [&>div>p]:text-lg [&>div>p]:font-normal">
                        <div className="flex md:flex-row flex-col justify-between">
                          <h6>Size</h6>
                          <p>
                            (
                            {getData?.data?.sizeIds?.find(
                              (ele) => ele.id === parseInt(createOrder?.sizeId)
                            )?.length || ""}{" "}
                            x{" "}
                            {getData?.data?.sizeIds?.find(
                              (ele) => ele.id === parseInt(createOrder?.sizeId)
                            )?.height || ""}{" "}
                            x{" "}
                            {getData?.data?.sizeIds?.find(
                              (ele) => ele.id === parseInt(createOrder?.sizeId)
                            )?.width || ""}
                            )
                          </p>
                        </div>
                        <div className="flex md:flex-row flex-col justify-between">
                          <h6>Dimesions</h6>
                          <p>
                            {getData?.data?.sizeIds?.find(
                              (ele) => ele.id === parseInt(createOrder?.sizeId)
                            )?.title || ""}
                          </p>
                        </div>
                        <div className="flex md:flex-row flex-col justify-between">
                          <h6>Category</h6>
                          <p>
                            {getData?.data?.categoryIds?.find(
                              (ele) =>
                                ele.id === parseInt(createOrder?.categoryId)
                            )?.title || ""}
                          </p>
                        </div>
                        <div className="flex md:flex-row flex-col justify-between">
                          <h6>Shipment Type</h6>
                          <p>
                            {getData?.data?.sizeIds.find(
                              (ele) =>
                                ele.id === parseInt(createOrder?.shipmentTypeId)
                            )?.title || ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <h3 className="text-lg font-bold">Parcel details</h3>
                    <div className="bg-themeGrey rounded-lg p-4 md:[&>div>h6]:text-lg [&>div>h6]:text-base [&>div>h6]:font-normal [&>div>h6]:text-black [&>div>h6]:text-opacity-60 md:[&>div>p]:text-lg [&>div>p]:text-base [&>div>p]:font-normal">
                      <div className="flex md:flex-row flex-col justify-between">
                        <h6>Sender Name</h6>
                        <p>{createOrder?.senderName}</p>
                      </div>
                      <div className="flex md:flex-row flex-col justify-between">
                        <h6>Email</h6>
                        <p>{createOrder?.senderEmail}</p>
                      </div>
                      <div className="flex md:flex-row flex-col justify-between">
                        <h6>Phone</h6>
                        <p>{createOrder?.senderPhone}</p>
                      </div>
                    </div>
                    <h2 className="text-lg font-bold">
                      Receipient Information
                    </h2>
                    <div className="bg-themeGrey rounded-lg p-4 [&>div>h6]:text-lg [&>div>h6]:font-normal [&>div>h6]:text-black [&>div>h6]:text-opacity-60 [&>div>p]:text-lg [&>div>p]:font-normal">
                      <div className="flex md:flex-row flex-col justify-between">
                        <h6>Receipient Name</h6>
                        <p>{createOrder?.receiverName}</p>
                      </div>
                      <div className="flex md:flex-row flex-col justify-between">
                        <h6>Receipient Email</h6>
                        <p>{createOrder?.receiverEmail}</p>
                      </div>
                      <div className="flex md:flex-row flex-col justify-between">
                        <h6>Receipient Phone</h6>
                        <p>{createOrder?.receiverPhone}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-11/12 xl:w-4/5 2xl:w-4/5 space-y-6">
                  <div className="space-y-7">
                    <h2 className="text-2xl font-medium mt-28">Invoice</h2>
                    <div className="rounded-lg md:p-4 space-y-4">
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Distance Charge
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.distanceCharge ?? 0}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Weight Charge
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.weightCharge ?? 0}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Category Charge
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.categoryCharge ?? 0}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Service Charge
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.serviceCharge ?? 0}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Shipment Charge
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.shipmentTypeCharge ?? 0}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Packing Charge
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.packingCharge ?? 0}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Gst Charge
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.gstCharge ?? 0}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          SubTotal
                        </h6>
                        <p className="text-lg font-medium">
                          $ {createOrder?.subTotal ?? 0}
                        </p>
                      </div>

                      <div className="text-center flex">
                        <input
                          value={couponName}
                          onChange={onChange}
                          name="coupon"
                          className={inputStyle}
                          type="search"
                          placeholder="coupon"
                        />
                        <button
                          onClick={couponvalidity}
                          className="bg-themePersian md:text-lg text-base font-bold text-white border border-themePersian cursor-pointer rounded-md md:px-8 px-4 hover:text-themePersian hover:bg-transparent hover:border hover:border-themePersian"
                          disabled={disabled}
                        >
                          Apply
                        </button>
                      </div>
                      <div className="flex justify-between">
                        <h6 className="text-lg font-normal text-black text-opacity-60">
                          Discount
                        </h6>
                        <p className="text-lg font-medium">
                          $ {couponDiscount}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <h6>Total Amount</h6>
                        <p>$ {createOrder?.subTotal - couponDiscount ?? 0} </p>
                      </div>
                      <div className="text-center">
                        <button
                          onClick={createBooking}
                          className={`${btnStyle} w-full flex justify-center items-center gap-x-2 md:text-2xl text-lg`}
                        >
                          Create Booking
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
}
