// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { liveImgURL } from "../../../style/Style";

export default function ListItem(props) {
  return (
    <>
      <Link
        to={props.path}
        className={`text-dark  flex items-center gap-x-4 px-2 py-2 rounded font-medium text-base text-black 
        ${props.bgColor} ${props.textColor} ${props.textOP}`}
      >
        <li className="flex items-center gap-x-2 relative">
          <img
            className="w-7"
            src={`${liveImgURL}/leftbar/light/icon${props.icon}.webp`}
            alt={props.alts}
          />
          {props.title}
          {props.notification && (
            <div className="absolute -right-6 -top-2 h-6 w-6 pt-1 bg-green-600 text-white text-center rounded-fullest text-xs font-normal">
              {props.amount}
            </div>
          )}
        </li>
      </Link>
    </>
  );
}
