// @ts-nocheck
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { liveImgURL } from "../../style/Style";
import { info_toaster } from "../../toaster/Toaster";
import { ExpandContext } from "./Layout";
import ListItem from "./leftBarElements/ListItem";
import GetAPI from "../../api/GetAPI";

export default function Leftbar() {
  const { expand } = useContext(ExpandContext);
  const { getData } = GetAPI("allincoming");
  const inTransit = GetAPI("intransitgroups");
  const selfPickup = GetAPI("selfpickupbookings");

  const location = useLocation().pathname;
  const navigate = useNavigate();
  const logoutFunc = (e) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginStatus");
    localStorage.removeItem("name");
    localStorage.removeItem("address");
    navigate("/signin");
    info_toaster("Logged Out Sucessfully!");
  };
  const dropdown = (obj) => {
    navigate(obj);
  };

  return (
    <>
      <section
        className={`bg-white  ${!expand ? "w-64" : "w-64"}  ${
          !expand ? "fixed" : "hidden"
        } z-30 py-5 flex flex-col justify-between h-full border border-black border-opacity-10`}
      >
        <Link
          to="/"
          className="flex items-center w-fit justify-start gap-x-5 px-8"
        >
          <img
            className="w-16"
            src={`${liveImgURL}/logo_dark.webp`}
            alt="logo"
          />
          <h1 className="font-extrabold text-3xl">PPS</h1>
        </Link>
        <div className="flex flex-col h-4/5 overflow-auto sidebar px-4">
          <Link
            to="/create_order/step_one"
            className="flex justify-center items-center gap-x-3 text-base font-medium w-full border border-themePersian rounded-md py-2 my-2"
          >
            <img
              className="w-7"
              src={`${liveImgURL}/icon.webp`}
              alt="dashboard"
            />
            Create Order
          </Link>
          <ul className="gap-y-3 flex flex-col">
            <h2 className="font-bold uppercase">Dashboard</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/"
              title="Home"
              alts="Dashboard"
              dropdown="false"
              icon="1"
              textColor={
                location === "/" ? "text-themeOrange" : "text-themePersian"
              }
              bgColor={location === "/" ? "bg-themePersian" : "bg-transparent"}
              textOP={location === "/" ? "text-opacity-100" : "text-opacity-40"}
            />
            <h2 className="font-bold uppercase">Live Tracking</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/track_id"
              title="Track ID"
              alts="track_id"
              dropdown="false"
              icon="1"
              textColor={
                location === "/track_id"
                  ? "text-themeOrange"
                  : "text-themePersian"
              }
              bgColor={
                location === "/track_id" ? "bg-themePersian" : "bg-transparent"
              }
              textOP={
                location === "/track_id"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />

            <h2 className="font-bold uppercase">Warehouse</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/warehouse"
              title="Warehouse"
              dropdown="false"
              icon="10"
              textColor={
                location === "/warehouse" ||
                location === "/at_warehouse" ||
                location === "/returned_packages"
                  ? "text-themeOrange"
                  : "text-themePersian"
              }
              notification={true}
              amount={getData?.data?.atWarehouse?.length}
              bgColor={
                location === "/warehouse" ||
                location === "/at_warehouse" ||
                location === "/returned_packages"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/warehouse" ||
                location === "/at_warehouse" ||
                location === "/returned_packages"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />
            <div>
              <ul className="ml-3 font-normal">
                <li
                  className={
                    location === "/at_warehouse"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/at_warehouse")}
                >
                  <button>At Warehouse</button>
                </li>
                <li
                  className={
                    location === "/require_transportation"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/require_transportation")}
                >
                  <button>Require Transportation</button>
                </li>
                <li
                  className={
                    location === "/returned_packages"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/returned_packages")}
                >
                  <button>Returned Packages</button>
                </li>
              </ul>
            </div>

            <h2 className="font-bold uppercase">Incoming Packages</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/allincoming"
              title="Incoming Packages"
              dropdown="false"
              icon="2"
              textColor={
                location === "/allincoming" ||
                location === "/order_created" ||
                location === "/reached_at_warehouse"
                  ? "text-themeOrange"
                  : "text-themePersian"
              }
              notification={true}
              amount={getData?.data?.bookingCreated?.length}
              bgColor={
                location === "/allincoming" ||
                location === "/order_created" ||
                location === "/reached_at_warehouse"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/allincoming" ||
                location === "/order_created" ||
                location === "/reached_at_warehouse"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />
            <div>
              <ul className="ml-3 font-normal">
                <li
                  className={
                    location === "/order_created"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/order_created")}
                >
                  <button>Order Created</button>
                </li>
                <li
                  className={
                    location === "/reached_at_warehouse"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/reached_at_warehouse")}
                >
                  <button>Reached At Warehouse</button>
                </li>
              </ul>
            </div>
            <h2 className="font-bold uppercase">In Transit</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/incoming_transit"
              title="In Transit"
              dropdown="false"
              icon="3"
              notification={true}
              amount={
                (inTransit?.getData?.data?.incoming?.inOngoing?.length || 0) +
                (inTransit?.getData?.data?.outgoing?.outOngoing?.length || 0)
              }
              textColor={
                location === "/incoming_transit" ||
                location === "/outgoing_transit"
                  ? "text-themeOrange"
                  : "text-black"
              }
              bgColor={
                location === "/incoming_transit" ||
                location === "/outgoing_transit"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/incoming_transit" ||
                location === "/outgoing_transit"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />
            <div>
              <ul className="ml-3 font-normal">
                <li
                  className={
                    location === "/incoming_transit"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/incoming_transit")}
                >
                  <button>Incoming Transit</button>
                </li>
                <li
                  className={
                    location === "/outgoing_transit"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/outgoing_transit")}
                >
                  <button>Outgoing Transit</button>
                </li>
              </ul>
            </div>
            <h2 className="font-bold uppercase">Outgoing Packages</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/all_outgoing"
              title="Outgoing Packages"
              dropdown="false"
              icon="4"
              textColor={
                location === "/all_outgoing" ||
                location === "/waiting_to_be_scheduled" ||
                location === "/waiting_to_be_picked" ||
                location === "/delivered"
                  ? "text-themeOrange"
                  : "text-black"
              }
              bgColor={
                location === "/all_outgoing" ||
                location === "/waiting_to_be_scheduled" ||
                location === "/waiting_to_be_picked" ||
                location === "/delivered"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/all_outgoing" ||
                location === "/waiting_to_be_scheduled" ||
                location === "/waiting_to_be_picked" ||
                location === "/delivered"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />
            <div>
              <ul className="ml-3 font-normal">
                <li
                  className={
                    location === "/waiting_to_be_scheduled"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/waiting_to_be_scheduled")}
                >
                  <button>Waiting To be Scheduled </button>
                </li>
                <li
                  className={
                    location === "/waiting_to_be_picked"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/waiting_to_be_picked")}
                >
                  <button>Waiting to be Picked </button>
                </li>
                <li
                  className={
                    location === "/delivered"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/delivered")}
                >
                  <button>All Delivered</button>
                </li>
              </ul>
            </div>
            <h2 className="font-bold uppercase">Self Pick Up</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/self_pick_up_bookings"
              title="Self Pick Up"
              dropdown="false"
              icon="4"
              notification={true}
              amount={selfPickup?.getData?.data?.waiting.length}
              textColor={
                location === "/self_pick_up_bookings" ||
                location === "/self_pick_delivered" ||
                location === "/self_pickup_waiting"
                  ? "text-themeOrange"
                  : "text-black"
              }
              bgColor={
                location === "/self_pick_up_bookings" ||
                location === "/self_pick_delivered" ||
                location === "/self_pickup_waiting"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/self_pick_up_bookings" ||
                location === "/self_pick_delivered" ||
                location === "/self_pickup_waiting"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />

            <div>
              <ul className="ml-3 text-black text-opacity-80 font-normal">
                <li
                  className={
                    location === "/self_pickup_waiting"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/self_pickup_waiting")}
                >
                  <button>Self Pickup Waiting</button>
                </li>
                <li
                  className={
                    location === "/self_pick_delivered"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/self_pick_delivered")}
                >
                  <button>Picked by Customer </button>
                </li>
              </ul>
            </div>

            <h2 className="font-bold uppercase">Expired Jobs</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/expired-jobs"
              title="Expired Jobs"
              dropdown="false"
              icon="4"
              notification={false}
              amount={selfPickup?.getData?.data?.waiting.length}
              textColor={
                location === "/expired-jobs" ||
                location === "/expired-jobs-pickup" ||
                location === "/expired-jobs-dropoff"
                  ? "text-themeOrange"
                  : "text-black"
              }
              bgColor={
                location === "/expired-jobs" ||
                location === "/expired-jobs-pickup" ||
                location === "/expired-jobs-dropoff"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/expired-jobs" ||
                location === "/expired-jobs-pickup" ||
                location === "/expired-jobs-dropoff"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />
            <div>
              <ul className="ml-3 text-black text-opacity-80 font-normal">
                <li
                  className={
                    location === "/expired-jobs-pickup"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/expired-jobs-pickup")}
                >
                  <button>Expired Jobs (Pickup)</button>
                </li>
                <li
                  className={
                    location === "/expired-jobs-dropoff"
                      ? "text-themeOrange"
                      : "text-[#a6a6ba]"
                  }
                  onClick={() => dropdown("/expired-jobs-dropoff")}
                >
                  <button>Expired Jobs (Dropoff)</button>
                </li>
              </ul>
            </div>

            <h2 className="font-bold uppercase">Utilities</h2>
            <hr className="border-none h-0.5 bg-black bg-opacity-20" />
            <ListItem
              path="/completed_bookings"
              title="Completed Bookings"
              alts="CompletedBookings"
              dropdown="false"
              icon="1"
              textColor={
                location === "/completed_bookings"
                  ? "text-themeOrange"
                  : "text-dark"
              }
              bgColor={
                location === "/completed_bookings"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/completed_bookings"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />

            <ListItem
              path="/driver_signup"
              title="Driver Signup"
              alts="DriverSignup"
              dropdown="false"
              icon="1"
              textColor={
                location === "/driver_signup" ? "text-themeOrange" : "text-dark"
              }
              bgColor={
                location === "/driver_signup"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/driver_signup"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />
            <ListItem
              path="/drivers"
              title="Drivers"
              alts="Drivers"
              dropdown="false"
              icon="1"
              textColor={
                location === "/drivers" ? "text-themeOrange" : "text-dark"
              }
              bgColor={
                location === "/drivers" ? "bg-themePersian" : "bg-transparent"
              }
              textOP={
                location === "/drivers" ? "text-opacity-100" : "text-opacity-40"
              }
            />

            <ListItem
              path="/warehouse_profile"
              title="Profile"
              alts="Profile"
              dropdown="false"
              icon="1"
              textColor={
                location === "/warehouse_profile"
                  ? "text-themeOrange"
                  : "text-dark"
              }
              bgColor={
                location === "/warehouse_profile"
                  ? "bg-themePersian"
                  : "bg-transparent"
              }
              textOP={
                location === "/warehouse_profile"
                  ? "text-opacity-100"
                  : "text-opacity-40"
              }
            />
          </ul>
        </div>
        <div>
          <button
            onClick={logoutFunc}
            className="flex bottom-3 items-center gap-x-5 px-8 py-3"
          >
            <img
              className="w-8"
              src={`${liveImgURL}/logout.webp`}
              alt="logout"
            />
            <h2>Logout</h2>
          </button>
        </div>
      </section>
    </>
  );
}
