import React from "react";
import { Link } from "react-router-dom";
import { liveImgURL } from "../../style/Style";

export default function AuthSide() {
  return (
    <div className="lg:py-12 py-4 lg:px-12 px-2 flex lg:flex-col items-center justify-center lg:gap-y-12 lg:border-r border-white border-opacity-40 h-fit m-auto w-full">
      <Link to={"/"} className="p-0">
        <img
          className="md:w-60 w-40"
          src={`${liveImgURL}/logo_white.webp`}
          alt="Logo"
        />
      </Link>
      <p className="lg:block hidden font-normal xl:text-2xl lg:text-xl text-white text-center">
        Taglines goes here
      </p>
    </div>
  );
}
