// @ts-nocheck
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import PostAPI from "../../api/PostAPI";
import { btnStyle, inputStyle, labelStyle } from "../../style/Style";
import {
  error_toaster,
  info_toaster,
  success_toaster,
} from "../../toaster/Toaster";
import Layout from "../layout/Layout";
import GetAPI from "../../api/GetAPI";

export default function CreateOrder2() {
  const navigate = useNavigate();
  const location = useLocation();
  const stateOrder = location?.state?.order;
  const warehouse = location?.state?.warehouses;

  const [address, setAddress] = useState([]);
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [order, setOrder] = useState({
    pickupAddressId: "",
    dropoffAddressId: "",
    senderName: "",
    senderEmail: "",
    senderPhone: "",
    receiverName: "",
    receiverEmail: "",
    receiverPhone: "",
  });
  const onChange = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };
  const searchAddress = async () => {
    let res = await PostAPI("getaddresses", {
      text: text,
    });

    if (res?.data?.status === "1") {
      setAddress(res?.data?.data?.addresses);
    } else {
      error_toaster(res?.data?.message);
    }
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const getCharges = async (e) => {
    e.preventDefault();
    if (order.senderName === "") {
      info_toaster("Please enter Sender's Name");
    } else if (order.senderEmail === "") {
      info_toaster("Please enter Sender's Email");
    } else if (
      !isValidEmail(order.senderEmail) ||
      !isValidEmail(order.receiverEmail)
    ) {
      info_toaster("Please enter valid email");
    } else if (order.senderPhone === "") {
      info_toaster("Please enter Sender's Phone Number");
    } else if (order.receiverName === "") {
      info_toaster("Please enter Recipient's Name");
    } else if (order.receiverEmail === "") {
      info_toaster("Please enter Recipient's Email");
    } else if (order.receiverPhone === "") {
      info_toaster("Please enter Recipient's Phone Number");
    } else if (order.dropoffAddressId === "") {
      info_toaster("Please provide Drop Off Address");
    } else {
      let res = await PostAPI("getcharges", {
        pickupAddressId: null,
        dropoffAddressId: order.dropoffAddressId.value,
        bookingTypeId: stateOrder?.bookingTypeId,
        categoryId: stateOrder?.categoryId.value,
        shipmentTypeId: stateOrder?.shipmentTypeId,
        sizeId: stateOrder?.sizeId,
      });
      if (res?.data?.status === "1") {
        success_toaster(res?.data?.message);
        navigate("/create_order/step_three", {
          state: {
            order: order,
            stateOrder: stateOrder,
            chargesRes: res?.data?.data,
          },
        });
      } else {
        error_toaster(res?.data?.message);
      }
    }
  };
  const options = [];
  address?.map((dbs, index) =>
    options.push({
      value: dbs?.id,
      label: dbs?.postalCode + " " + dbs?.secondPostalCode,
    })
  );

  const warehouseOptions = [];
  warehouse?.map((data, index) =>
    warehouseOptions.push({
      value: data?.id,
      label:
        data?.addressDB?.postalCode + " " + data?.addressDB?.secondPostalCode,
    })
  );
  return (
    <Layout
      extend={true}
      data={
        <>
          {" "}
          <section className="bg-white rounded-md my-5 md:px-16 px-4 py-5">
            <div className="xl:grid xl:grid-cols-2 space-x-2">
              <div className="w-full md:11/12 lg:11/12 xl:w-11/12 2xl:w-4/5">
                <div className="space-y-7">
                  <h2 className="text-2xl font-medium">Customer Information</h2>
                  <div>
                    <label htmlFor="senderName" className={labelStyle}>
                      Full Name
                    </label>
                    <input
                      value={order.senderName}
                      onChange={onChange}
                      type="text"
                      name="senderName"
                      id="senderName"
                      className={inputStyle}
                      placeholder="Full Name"
                    />
                  </div>
                  <div>
                    <label htmlFor="senderEmail" className={labelStyle}>
                      Sender's email
                    </label>
                    <input
                      value={order.senderEmail}
                      onChange={onChange}
                      type="email"
                      name="senderEmail"
                      id="senderEmail"
                      className={inputStyle}
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <label htmlFor="senderPhone" className={labelStyle}>
                      Sender's phone
                    </label>
                    <PhoneInput
                      country={"pa"}
                      value={order.senderPhone}
                      onChange={(e) => setOrder({ ...order, senderPhone: e })}
                      inputProps={{
                        name: "senderPhone",
                        id: "senderPhone",
                      }}
                      inputStyle={{
                        display: "block",
                        border: "transparent",
                        width: "100%",
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        background: "#F4F7FF",
                      }}
                    />
                  </div>
                  {/* <div>
                    <label className={labelStyle} htmlFor="pickupAddressId">
                      Sender's Address
                    </label>
                    <Select
                      inputValue={text}
                      onInputChange={(text) => {
                        setText(text);
                        text === "" ? setAddress([]) : searchAddress();
                      }}
                      value={order.pickupAddressId}
                      onChange={(e) =>
                        setOrder({ ...order, pickupAddressId: e })
                      }
                      options={options}
                      inputId="pickupAddressId"
                      placeholder="Enter something to search DBS"
                    />
                  </div> */}
                </div>
              </div>
              <div className="w-full md:w-11/12 xl:w-4/5 2xl:w-4/5 space-y-6">
                <div className="space-y-7">
                  <h2 className="text-2xl font-medium">
                    Receipient Information
                  </h2>
                  <div>
                    <label htmlFor="receiverName" className={labelStyle}>
                      Full Name
                    </label>
                    <input
                      value={order.receiverName}
                      onChange={onChange}
                      type="text"
                      name="receiverName"
                      id="receiverName"
                      className={inputStyle}
                      placeholder="Full Name"
                    />
                  </div>
                  <div>
                    <label htmlFor="receiverEmail" className={labelStyle}>
                      Receipient Email
                    </label>
                    <input
                      value={order.receiverEmail}
                      onChange={onChange}
                      type="email"
                      name="receiverEmail"
                      id="receiverEmail"
                      className={inputStyle}
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <label htmlFor="receiverPhone" className={labelStyle}>
                      Receipient phone
                    </label>
                    <PhoneInput
                      country={"pa"}
                      value={order.receiverPhone}
                      onChange={(e) => setOrder({ ...order, receiverPhone: e })}
                      inputProps={{
                        name: "receiverPhone",
                        id: "receiverPhone",
                      }}
                      inputStyle={{
                        display: "block",
                        border: "transparent",
                        width: "100%",
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        background: "#F4F7FF",
                      }}
                    />
                  </div>
                  {stateOrder?.bookingTypeId === "1" ? (
                    <div>
                      <label className={labelStyle} htmlFor="dropoffAddressId">
                        Receipient's Address
                      </label>
                      <Select
                        inputValue={text2}
                        onInputChange={(text) => {
                          setText2(text);
                          text === "" ? setAddress([]) : searchAddress();
                        }}
                        value={order.dropoffAddressId}
                        onChange={(e) =>
                          setOrder({ ...order, dropoffAddressId: e })
                        }
                        options={options}
                        inputId="dropoffAddressId"
                        placeholder="Search DBS"
                      />
                    </div>
                  ) : (
                    <div>
                      <label className={labelStyle} htmlFor="dropoffAddressId">
                        Warehouse's Address
                      </label>
                      <Select
                        value={order.dropoffAddressId}
                        onChange={(e) =>
                          setOrder({ ...order, dropoffAddressId: e })
                        }
                        options={warehouseOptions}
                        inputId="dropoffAddressId"
                        placeholder="Search DBS"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end my-12 gap-x-4">
              <button onClick={getCharges} className={btnStyle}>
                Next
              </button>
            </div>
          </section>
        </>
      }
    />
  );
}
