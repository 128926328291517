// @ts-nocheck
import React from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/TableStyle";
import { Helmet } from "react-helmet";
import Layout from "../layout/Layout";
import GetAPI from "../../api/GetAPI";
import PostAPI from "../../api/PostAPI";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import { useNavigate } from "react-router-dom";

export default function PendingPaymentOrders() {
  const { getData } = GetAPI("pendingpaymentorders");
  const navigate = useNavigate();
  const confirmPayment = async (id) => {
    let res = await PostAPI("confirmpayment", {
      bookingId: id,
    });
    if (res?.data?.status === "1") {
      navigate("/");
      success_toaster(res?.data?.message);
    } else {
      error_toaster(res?.data?.message);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Tracking Id",
      selector: (row) => row.trackingId,
      sortable: true,
      grow: 2,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Shipment Type",
      selector: (row) => row.shipmentType,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const datas = [];
  getData?.data?.map((dri, index) => {
    datas.push({
      id: index + 1,
      trackingId: `${dri?.trackingId}`,
      date: `${dri?.date}`,
      shipmentType: dri?.shipmentType?.title,
      action: (
        <div>
          <button
            onClick={() => {
              confirmPayment(dri?.id);
            }}
            className="bg-themePersian text-white text-sm font-medium border border-themePersian cursor-pointer rounded-md px-3 lg:px-20 py-3 hover:text-themePersian hover:bg-transparent hover:border hover:border-themePersian"
          >
            Confirm Payment
          </button>
        </div>
      ),
    });
  });

  return (
    <>
      <Helmet>
        <title>PPS - All Drivers</title>
      </Helmet>

      <Layout
        extend={true}
        data={
          <section>
            <DataTable
              columns={columns}
              data={datas}
              customStyles={customStyles}
              pagination
            />
          </section>
        }
      />
    </>
  );
}
