// @ts-nocheck
import React, { useState } from "react";
import csvtojson from "csvtojson";

export default function Csv() {
  const [data, setData] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const csvData = event.target.result;
      const jsonData = await csvtojson().fromString(csvData);
      setData(jsonData);
    };

    reader.readAsText(file);
  };
  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      <ul>
        {data.map((item, index) => (
          <li key={index}>{item.name}</li>
        ))}
      </ul>
    </div>
  );
}
