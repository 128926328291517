// @ts-nocheck
import React, { useEffect, useState } from "react";
import GetAPI from "../api/GetAPI";

export default function Pagination() {
  const [dataArray, setDataArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const { getData } = GetAPI("allincoming");
  useEffect(() => {
    setDataArray(getData?.data?.bookingCreated);
  }, [getData]);

  const getCurrentItems = () => {
    const filteredArray = dataArray.filter((item) =>
      item.trackingId.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    const totalPages = Math.ceil(dataArray.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search..."
      />
      {getData?.data?.bookingCreated.length > 0 &&
        dataArray !== undefined &&
        getCurrentItems().map((item, index) => (
          <div key={index}>{item.trackingId}</div>
        ))}
      <button
        className="bg-themePersianLight rounded-md px-4 py-2"
        onClick={handlePrevClick}
      >
        Previous
      </button>
      <button onClick={handleNextClick}>Next</button>
    </div>
  );
}
