// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import { customStyles } from "../../style/TableStyle";
import Layout from "../layout/Layout";

const OutgoingTransitDetails = () => {
  const [OutgoingTransit, setOutgoingTransit] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setOutgoingTransit(location.state.OutgoingTransit);
  }, [location.state.OutgoingTransit]);

  const columns = [
    {
      name: "Sr #",
      selector: (row) => row.Sr,
    },
    {
      name: "Tracking ID",
      selector: (row) => row.tracking,
    },
    {
      name: "From",
      selector: (row) => row.From,
    },
    {
      name: "To",
      selector: (row) => row.To,
    },
    
    {
      name: "Shipment Type",
      selector: (row) => row.ShipmentType,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
    },
  ];

  const datas = [];
    OutgoingTransit?.map((ele, index) =>
      datas.push({
        Sr: index + 1,
        From: ele.dropoffAddress.postalCode + " " + ele.dropoffAddress.secondPostalCode,
        To: ele.pickupAddress.postalCode + " " +  ele.dropoffAddress.secondPostalCode,
        tracking: ele?.trackingId,
        ShipmentType: ele?.shipmentType?.title,
        Status: <button className="px-5 lg:px-8 py-3 rounded-md text-[#66CC99] bg-[#C7FFE3]">{ele.bookingStatus.title}</button>,
      })
    );

  return (
    <>
      <Helmet>
        <title>PPS - In Transit Details</title>
      </Helmet>
      <Layout
        pageTitle=""
        extend={true}
        data={
          <section>
            <h1>Transit Details</h1>
            <div>
              <DataTable
                columns={columns}
                data={datas}
                customStyles={customStyles}
              />
            </div>
          </section>
        }
      />
    </>
  );
};

export default OutgoingTransitDetails;
