import moment from "moment";
import React from "react";

export default function LargeCard(props) {
  return (
    <div>
      <div className="bg-white px-6 pt-5 pb-2 rounded-lg space-y-2 my-5 grid grid-cols-12">
        <div className="col-span-10">
          <div className="">
            <h3 className="text-themeRed text-xl font-medium text-opacity-60">
              New Order with {props?.ele?.trackingId} created sucessfully !
            </h3>
            <div className="flex gap-x-20">
              <h1 className="text-base font-bold text-black text-opacity-60">
                Pickup Address:{" "}
                <span className="text-black">
                  {props?.ele?.pickupAddress.postalCode +
                    " " +
                    props?.ele?.pickupAddress?.secondPostalCode}
                </span>{" "}
              </h1>
              <h1 className="text-base font-bold text-black text-opacity-60">
                Total:{" "}
                <span className="text-black">
                  {props?.ele?.currencyUnit}
                  {props?.ele?.total}
                </span>
              </h1>
            </div>
          </div>
        </div>

        <div className=" col-span-2">
          <h3 className="text-themeRed text-xl font-medium text-opacity-60">
            {moment(props?.ele?.pickupDate).format("DD-MM-YYYY")}
          </h3>
        </div>
      </div>
    </div>
  );
}
