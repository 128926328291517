// @ts-nocheck
import axios from "../../api/API";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { FaWarehouse, FaPhoneSquareAlt, FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
export default function WarehouseProfile() {
  const [resData, setResData] = useState();
  const [loading, setloading] = useState(true);

  const getprofile = async () => {
    try {
      const res = await axios.get("/warehouse/getprofile", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setResData(res.data);
      setloading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getprofile();
  }, []);
  return (
    <>
      <Layout
        pageTitle="Warehouse Profile"
        extend={true}
        data={
          loading ? (
            <section className="grid grid-cols-2 bg-white">
              <div className="p-5 space-y-4">
                <h1 className="flex items-center gap-x-3 text-black text-opacity-40 ">
                  <span className="text-black  capitalize w-20">
                    <Skeleton />
                  </span>{" "}
                </h1>
                <h1 className="flex items-center gap-x-3 text-black text-opacity-40 ">
                  <span className="text-black  capitalize w-40">
                    <Skeleton />
                  </span>{" "}
                </h1>
                <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                  <span className="text-black  capitalize w-96">
                    <Skeleton />
                  </span>{" "}
                </h1>
                <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                  <span className="text-black  capitalize w-60">
                    <Skeleton />
                  </span>{" "}
                </h1>
                <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                  <span className="text-black capitalize w-40">
                    {" "}
                    <Skeleton />
                  </span>{" "}
                </h1>
              </div>
            </section>
          ) : (
            <>
              <section className="grid grid-cols-2 bg-white">
                <div className="p-5 space-y-4">
                  <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                    <FaWarehouse /> ID :{" "}
                    <span className="text-black  capitalize">
                      {resData?.data?.id || <Skeleton />}
                    </span>{" "}
                  </h1>
                  <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                    <FaWarehouse /> Name:{" "}
                    <span className="text-black  capitalize">
                      {resData?.data?.name || <Skeleton />}
                    </span>{" "}
                  </h1>
                  <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                    <MdEmail /> Email:{" "}
                    <span className="text-black  capitalize">
                      {resData?.data?.email}
                    </span>{" "}
                  </h1>
                  <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                    <FaPhoneSquareAlt /> Phone:{" "}
                    <span className="text-black  capitalize">
                      {resData?.data?.countryCode +
                        " " +
                        resData?.data?.phoneNum}
                    </span>{" "}
                  </h1>
                  <h1 className="flex items-center gap-x-3 text-black text-opacity-40">
                    <FaAddressCard /> Address:{" "}
                    <span className="text-black capitalize">
                      {" "}
                      {resData?.data?.addressDB.postalCode +
                        " " +
                        resData?.data?.addressDB.secondPostalCode}
                    </span>{" "}
                  </h1>
                </div>
              </section>
            </>
          )
        }
      />
    </>
  );
}
