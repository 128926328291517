import React from "react";
import { Link } from "react-router-dom";
import { btnStyle, btnStyle1, inputStyle, labelStyle } from "../../style/Style";
import Layout from "../layout/Layout";
import { FiSearch } from "react-icons/fi";

export default function CustomerInfo() {
  return (
    <>
      <Layout
        pageTitle=""
        extend={true}
        data={
          <>
            <section className="bg-white rounded-md my-5 px-16 py-5 ">
              <div className="xl:grid xl:grid-cols-2 space-x-2">
                <div className="w-full md:11/12 lg:11/12 xl:w-11/12 2xl:w-4/5">
                  <div className="space-y-7">
                    <h2 className="text-2xl font-medium">
                      Customer Information
                    </h2>
                    <div>
                      <label htmlFor="first_name" className={labelStyle}>
                        First name
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="last_name" className={labelStyle}>
                        Last name
                      </label>
                      <input
                        type="text"
                        id="last_name"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="first_name" className={labelStyle}>
                        Sender’s email
                      </label>
                      <input
                        type="email"
                        id="sender_email"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="sender_phone" className={labelStyle}>
                        Sender’s phone
                      </label>
                      <input
                        type="tel"
                        id="sender_phone"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div className="relative">
                      <label htmlFor="dbs" className={labelStyle}>
                        Provide DBS code of sender
                      </label>
                      <input
                        type="text"
                        id="dbs"
                        className={inputStyle}
                        required
                      />
                      <div className="absolute bottom-3 right-3 pointer-events-none">
                        <FiSearch />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-11/12 xl:w-4/5 2xl:w-4/5 space-y-6">
                  <div className="space-y-7">
                    <h2 className="text-2xl font-medium">
                      Receipient Information
                    </h2>
                    <div>
                      <label className={labelStyle}>First name</label>
                      <input
                        type="text"
                        id="first_name"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div>
                      <label className={labelStyle}>Last name</label>
                      <input
                        type="text"
                        id="last_name"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div>
                      <label className={labelStyle}>Receipient email</label>
                      <input
                        type="email"
                        id="sender_email"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div>
                      <label className={labelStyle}>Receipient phone</label>
                      <input
                        type="tel"
                        id="sender_phone"
                        className={inputStyle}
                        required
                      />
                    </div>
                    <div className="relative">
                      <label className={labelStyle}>
                        Provide DBS code of Receipient
                      </label>
                      <input
                        type="text"
                        id="dbs"
                        className={inputStyle}
                        required
                      />
                      <div className="absolute bottom-3 right-3 pointer-events-none">
                        <FiSearch />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end my-12 gap-x-4">
                <Link to="/create_order" className={btnStyle1}>
                  Back
                </Link>
                <Link to="/order_summary" className={btnStyle}>
                  Next
                </Link>
              </div>
            </section>
          </>
        }
      />
    </>
  );
}
