// @ts-nocheck
import React, { useEffect, useState } from "react";
import OrderCreatedCard from "../allincoming/allIncomingElements/OrderCreatedCard";
import axios from "../../api/API";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import Layout from "../layout/Layout";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

export default function WaitingToBeScheduled() {
  const [tabs] = useState("waiting");
  const [resData, setResData] = useState();

  const [waitingPagination, setWaitingPagination] = useState([]);
  const [scheduledBookings, setScheduledBookings] = useState([]);
  const [outgoing, setoutgoing] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setWaitingPagination(resData?.data?.waiting);
    setScheduledBookings(resData?.data?.scheduledBookings);
    setoutgoing(resData?.data?.outgoing);
  }, [resData]);

  const getMyPostData = async () => {
    try {
      const res = await axios.get("/warehouse/alloutgoing", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setResData(res.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    getMyPostData();
  }, []);

  const getwaiting = () => {
    const filteredArray = resData?.data?.waiting.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const getscheduledBookings = () => {
    const filteredArray = resData?.data?.scheduledBookings.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const getoutgoing = () => {
    const filteredArray = resData?.data?.outgoing.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    let handlePagination = [];
    if (tabs === "waiting") handlePagination = waitingPagination;
    else if (tabs === "scheduledBookings") handlePagination = scheduledBookings;
    else handlePagination = outgoing;

    const totalPages = Math.ceil(handlePagination.length / itemsPerPage);

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>PPS - Waiting To Scheduled</title>
      </Helmet>

      <Layout
        extend={true}
        data={
          <section>
            <h1>Waiting To Scheduled</h1>
            <div className="mt-6">
              <div>
                <input
                  className="text-base w-96 font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none"
                  type="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                />
              </div>
              {!resData?.data ? (
                <div className="grid grid-cols-12 bg-white p-5 rounded-lg my-8">
                  <div className="col-span-10 space-y-3">
                    <div className="flex items-center gap-x-2">
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                      <div className="w-48">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-1">
                        <div className="w-5 m-auto">
                          <Skeleton count={3} />
                        </div>
                      </div>
                      <div className="col-span-11 flex flex-col justify-between space-y-4">
                        <div>
                          <h3 className="w-28 text-base font-medium text-black text-opacity-60">
                            <Skeleton />
                          </h3>
                        </div>
                        <div className="flex gap-x-16">
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 gap-x-5 flex flex-col items-end justify-between">
                    <div>
                      <div className="space-x-1 w-20">
                        <span>
                          <Skeleton />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {tabs === "waiting"
                    ? getwaiting()?.map((ele, key) => (
                        <OrderCreatedCard
                          apiEle={ele}
                          badge={ele.bookingStatus.title}
                          bg="bg-themeYellow"
                        />
                      ))
                    : tabs === "scheduledBookings"
                    ? getscheduledBookings()?.map((ele, key) => (
                        <OrderCreatedCard
                          apiEle={ele}
                          badge={ele.bookingStatus.title}
                          bg="bg-themeBlue3"
                        />
                      ))
                    : getoutgoing()?.map((ele, key) => (
                        <OrderCreatedCard
                          apiEle={ele}
                          badge={ele.bookingStatus.title}
                          bg={
                            ele?.bookingStatusId === 11
                              ? "bg-themeYellow"
                              : ele?.bookingStatusId === 17
                              ? "bg-themeGrey"
                              : ele?.bookingStatusId === 14
                              ? "bg-themeRed"
                              : "bg-themeGreen"
                          }
                        />
                      ))}
                </div>
              )}
              <div className="flex justify-end gap-x-4">
                <button
                  className="text-lg text-themePersian  border border-themePersian flex items-center rounded-md px-4 py-2"
                  onClick={handlePrevClick}
                >
                  <RiArrowLeftSLine /> Previous
                </button>
                <button
                  className="text-lg text-themePersian border border-themePersian flex items-center justify-center rounded-md px-4 py-2 w-[123px] text-center"
                  onClick={handleNextClick}
                >
                  Next <RiArrowRightSLine />
                </button>
              </div>
            </div>
          </section>
        }
      />
    </>
  );
}
