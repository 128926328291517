import React, { useEffect, useState } from "react";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import PostAPI from "../../api/PostAPI";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
  } from "@chakra-ui/react";
import QRCode from "react-qr-code";
import { FaArrowDown } from "react-icons/fa";
import moment from "moment";

export default function PrintReceipt() {
  const [trackID, setTrackID] = useState("");
  const [disbaled, setDisabled] = useState(false);
  const [res, setRes] = useState([]);
  console.log("🚀 ~ PrintReceipt ~ res:", res)
  const [modal, setModal] = useState(false);

  const track = async () => {
    if (trackID === "") {
      error_toaster("Please Enter Tracking ID");
    } else {
      setDisabled(true);
      const res = await PostAPI("/trackorder", {
        trackingId: trackID,
      });
      if (res?.data.status === "1") {
        success_toaster(res?.data?.message);
        setModal(true);
        setRes(res?.data);
        setDisabled(false);
      } else {
        error_toaster(res?.data?.message);
        setDisabled(false);
      }
    }
  };

  const handlePrint = () => {
    const sectionToPrint = document.getElementById("sectionToPrint");
    if (sectionToPrint) {
      const printContents = sectionToPrint.innerHTML;

      const newWindow = window.open();
      newWindow.document.write(`<!DOCTYPE html>
      <html lang="en">
   <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
    <title>Print Coupon</title>
    </head>
   <body>`);
      newWindow.document.write(printContents);
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      setTimeout(() => {
        newWindow.print();
        setModal(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (modal) {
      setTimeout(handlePrint, 500);
    }
  }, [modal]);

  return (
    <>
      <div className="bg-white">
        <div className="bg-themePersian py-2">
          <div className="flex justify-center">
            <img src="/images/logo.webp" alt="logo" className="w-20" />
          </div>
        </div>

        <div className="flex flex-col gap-5 justify-center items-center w-4/5 mx-auto py-10">
          <h1 className="text-2xl font-medium">Print Receipt</h1>
          <div className="space-y-5">
            <div className="flex flex-col gap-2">
              <label htmlFor="trackingId">Tracking Number</label>
              <input
                id="trackingId"
                name="trackingId"
                className="bg-[#D9D9D9] p-2 rounded-md border-none outline-none w-96"
                type="text"
                value={trackID}
                onChange={(e) => setTrackID(e.target.value)}
              />
            </div>

            <div>
              <button
                className="block text-center bg-themePersian text-white rounded-md font-medium text-xl 
              border border-themePersian py-2 w-96 hover:bg-transparent hover:text-themePersian"
                onClick={track}
                disabled={disbaled}
              >
                Print
              </button>
            </div>
          </div>
        </div>

        <Modal
          onClose={() => {
            setModal(false);
          }}
          isOpen={modal}
          isCentered
          size={"3xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody paddingTop={5} paddingBottom={5}>
              <div id="sectionToPrint" style={{ width: "80%", margin: "auto" }}>
                <div className="border-2 border-black m-10">
                  <div className="grid grid-cols-7">
                    <div
                      className="text-7xl flex items-center font-bold p-4 border-black border-r-2 
                col-span-1"
                    >
                      E
                    </div>
                    <div className="col-span-6 p-4 flex flex-col gap-2">
                      <div className="flex justify-end text-xs font-semibold">
                        panamapostal.com
                      </div>
                      <div className="text-sm font-semibold">
                        <h2>
                          Send:{" "}
                          {res?.data?.senderDetails?.name}
                        </h2>
                        <p>
                          From:{" "}
                          {
                            res?.data?.deliveryDetails
                              ?.pickupCode
                          }
                        </p>
                      </div>
                      <div className="flex justify-center text-sm font-semibold">
                        Date:{" "}
                        {moment(res?.data?.createdAt).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="border-black border-t-2 border-b-2 py-3">
                    <div className="text-xl font-semibold uppercase text-center">
                      {res?.data?.parcelDetails?.shipmentType}{" "}
                      <sup className="uppercase text-sm">Tm</sup>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 p-4">
                    <div className="col-span-3 space-y-5">
                      <div>
                        <div className="flex gap-5 text-sm font-semibold">
                          <div>Sending Warehouse:</div>
                          <div>
                            <div>{localStorage.getItem("name")}</div>
                            <div className="flex justify-center my-2">
                              <FaArrowDown />
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-5 text-sm font-semibold">
                          <div>Receiving Warehouse: </div>
                          <div>
                            {
                              res?.data?.deliveryWarehouse
                                ?.name
                            }
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-5 text-sm font-semibold">
                        <div>Receives: </div>
                        <div className="flex flex-col">
                          <div className="capitalize">
                            {res?.data?.recipientDetails?.name}
                          </div>
                          <div>
                            {
                              res?.data?.deliveryDetails
                                ?.dropoffCode
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <img src="/images/logo-gray.webp" alt="logo-gray" />
                    </div>
                  </div>
                  <div className="border-black border-t-2 border-b-2 py-3 flex flex-col items-center gap-2">
                    <h2 className="text-xl font-semibold uppercase text-center">
                      Pps Tracking #
                    </h2>
                    <QRCode
                      size={256}
                      style={{
                        height: "80px",
                        width: "80px",
                      }}
                      value={res?.data?.qrCode}
                      viewBox={`0 0 256 256`}
                    />
                    <div className="text-sm text-center">
                      {res?.data?.trackingId}
                    </div>
                  </div>
                  <div className="p-4 text-xl font-semibold uppercase text-center">
                    {res?.data?.deliveryDetails?.dropoffCode}
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}
