// @ts-nocheck
import React from "react";
import StatusPill from "./StatusPill";

export default function IncomingDetailsStatus(props) {
  const convertTo12Hour = (time) => {
    if (time && time !== "") {
      let hours = parseInt(time.substr(0, 2));
      let minutes = time.substr(3, 2);
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${ampm}`;
    }
  };
  return (
    <div>
      <div className="mt-5 card flex gap-5 [&>div]:min-w-[12.5%] overflow-auto pb-5 bg-white shadow-xl rounded-lg p-5 [&>div]:space-y-4">
        {props?.data?.bookingHistory?.map((booking, index) => (
          <StatusPill
            title={booking?.statusText}
            text={booking?.statusDesc}
            pillStatus={booking?.status ? "completed" : "inProcess"}
            date={booking?.date}
            time={convertTo12Hour(booking?.time)}
          />
        ))}
        {/* {props?.data?.bookingHistory[14]?.status ? (
          <div>
            <div className="bg-themeOrange h-12 w-12 rounded-full relative">
              <div className="text-white absolute top-[50%] left-[50%]  -translate-x-[50%] -translate-y-[50%]">
                <MdCancel />
              </div>
            </div>
            <div>
              <h3 className="text-sm text-red-500 font-bold">
                {
                  props?.data?.bookingHistory.find(
                    (ele) => ele.bookingStatusId === 14
                  )?.statusText
                }
              </h3>
              <div>
                <p className="text-xs font-normal">
                  {props.data?.bookingHistory[14]?.date}
                </p>
                <p className="text-xs font-normal">
                  {props.data?.bookingHistory[14].time}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <StatusPill
              title={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 3
                )?.statusText
              }
              cleared={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 3
                )?.status
                  ? true
                  : false
              }
              inProcess={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 3
                )?.status
                  ? true
                  : false
              }
              date={
                <div>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 3
                      ).date
                    }
                  </h1>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 3
                      ).time
                    }
                  </h1>
                </div>
              }
            />
            <StatusPill
              title={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 4
                )?.statusText
              }
              cleared={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 4
                )?.status
                  ? true
                  : false
              }
              inProcess={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 4
                )?.status
                  ? true
                  : false
              }
              date={
                <div>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 4
                      ).date
                    }
                  </h1>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 4
                      ).time
                    }
                  </h1>
                </div>
              }
            />

            <StatusPill
              title={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 5
                )?.statusText
              }
              cleared={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 5
                )?.status
                  ? true
                  : false
              }
              inProcess={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 5
                )?.status
                  ? true
                  : false
              }
              date={
                <div>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 5
                      ).date
                    }
                  </h1>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 5
                      ).time
                    }
                  </h1>
                </div>
              }
            />
            <StatusPill
              title={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 6
                )?.statusText
              }
              cleared={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 6
                )?.status
                  ? true
                  : false
              }
              inProcess={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 6
                )?.status
                  ? true
                  : false
              }
              date={
                <div>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 6
                      ).date
                    }
                  </h1>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 6
                      ).time
                    }
                  </h1>
                </div>
              }
            />
            <StatusPill
              title={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 7
                )?.statusText
              }
              cleared={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 7
                )?.status
                  ? true
                  : false
              }
              inProcess={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 7
                )?.status
                  ? true
                  : false
              }
              date={
                <div>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 7
                      ).date
                    }
                  </h1>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 7
                      ).time
                    }
                  </h1>
                </div>
              }
            />
            <StatusPill
              title={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 8
                )?.statusText
              }
              cleared={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 8
                )?.status
                  ? true
                  : false
              }
              inProcess={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 8
                )?.status
                  ? true
                  : false
              }
              date={
                <div>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 8
                      ).date
                    }
                  </h1>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 8
                      ).time
                    }
                  </h1>
                </div>
              }
            />
            <StatusPill
              title={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 10
                )?.statusText
              }
              cleared={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 10
                )?.status
                  ? true
                  : false
              }
              inProcess={
                props?.data?.bookingHistory.find(
                  (ele) => ele.bookingStatusId === 10
                )?.status
                  ? true
                  : false
              }
              date={
                <div>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 10
                      ).date
                    }
                  </h1>
                  <h1>
                    {
                      props?.data?.bookingHistory.find(
                        (ele) => ele.bookingStatusId === 10
                      ).time
                    }
                  </h1>
                </div>
              }
            />
          </>
        )} */}
      </div>
    </div>
  );
}
