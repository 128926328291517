import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import Skeleton from "react-loading-skeleton";
import GetAPI from "../../api/GetAPI";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import TabHead from "../allincoming/allIncomingElements/TabHead";
import OrderCreatedCard from "../allincoming/allIncomingElements/OrderCreatedCard";
import axios from "../../api/API";
import PostAPI from "../../api/PostAPI";
import { error_toaster, success_toaster } from "../../toaster/Toaster";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

export default function Warehouse() {
  const [tabs, setTabs] = useState("At warehouse");
  const [returnTab, setReturnTab] = useState("Returned Jobs(Incoming)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const { getData, reFetch } = GetAPI("allincoming");
  const returnedPackages = GetAPI("returned-packeges");
  const [wareHouseData, setwareHouseData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [atWarehouse, setAtWarehouse] = useState([]);
  const [dataDeliveredAtWarehouse, setDeliveredAtWarehouse] = useState([]);
  const [returnPackages, setReturnPackages] = useState([]);
  const [assignTransporter, setassignTransporter] = useState("");
  const [transporterGuyData, settransporterGuyData] = useState();
  const [bookingIds, setbookingIds] = useState("");
  const [check, setCheck] = useState(false);
  const inputRef = useRef(null);

  const getAtWarehouse = () => {
    const filteredArray = getData?.data?.atWarehouse.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const getDeliveredAtWarehouse = () => {
    const filteredArray = getData?.data?.deliveredAtWarehouse.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const getReturnPackInc = () => {
    const filteredArray = returnedPackages?.getData?.data?.incomming.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const getReturnPackReceived = () => {
    const filteredArray = returnedPackages?.getData?.data?.received.filter(
      (item) =>
        item.trackingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shipmentType.title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredArray.slice(startIndex, endIndex);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    let handlePagination = [];
    if (tabs === "At warehouse") handlePagination = atWarehouse;
    else if (tabs === "Returned packages") handlePagination = returnPackages;
    else handlePagination = dataDeliveredAtWarehouse;
    const totalPages = Math.ceil(handlePagination.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setAtWarehouse(getData?.data?.atWarehouse);
    setDeliveredAtWarehouse(getData?.data?.deliveredAtWarehouse);
    setReturnPackages(returnedPackages?.getData?.data);
  }, [getData, tabs, returnedPackages]);

  // Get Active Warehouse data
  const getActiveWarehouse = async () => {
    try {
      const res = await axios.get("/warehouse/allactivewarehouse", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setwareHouseData(res.data);
    } catch (error) {}
  };

  // Get Active Transporter Guys data
  const getTransporterGuy = async () => {
    try {
      const res = await axios.get("/warehouse/allactivetransporterguy", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      settransporterGuyData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getActiveWarehouse();
    getTransporterGuy();
  }, []);

  const [firstWarehouseName, setFirstWarehouseName] = useState("");
  const [firstWarehouseID, setFirstWarehouseID] = useState();
  const [isFirstCheckboxClick, setIsFirstCheckboxClick] = useState(true);

  const handlecheckbox = async (id, warehouseID, warehouseName) => {
    if (isFirstCheckboxClick) {
      setFirstWarehouseName(warehouseName);
      setFirstWarehouseID(warehouseID);
      setIsFirstCheckboxClick(false);
    }

    if (warehouseName === firstWarehouseName) {
      if (bookingIds.includes(id)) {
        setbookingIds(bookingIds.filter((filterId) => filterId !== id));
        if (bookingIds.length === 1) {
          setFirstWarehouseName("");
          setFirstWarehouseID("");
          setIsFirstCheckboxClick(true);
        }
      } else {
        setbookingIds([...bookingIds, id]);
        setCheck(true);
        setIsFirstCheckboxClick(false);
      }
    } else if (isFirstCheckboxClick) {
      if (bookingIds.includes(id)) {
        setbookingIds(bookingIds.filter((filterId) => filterId !== id));
      } else {
        setbookingIds([...bookingIds, id]);
        setCheck(true);
        setIsFirstCheckboxClick(false);
      }
    } else {
      error_toaster(`Warehouse should ${firstWarehouseName}`);
    }
  };

  const transporterid = (e) => {
    setassignTransporter(e.target.value);
  };
  const assignTransporterData = async () => {
    if (bookingIds.length === 0) {
      error_toaster("Please Select Order First");
    } else if (assignTransporter === "") {
      error_toaster("Please Select Transporter Guy");
    } else {
      let res = await PostAPI("totransit", {
        bookingIds: bookingIds,
        transporterGuyId: assignTransporter,
        deliveryWarehouseId: firstWarehouseID,
      });
      if (res?.data?.status === "1") {
        onClose();
        reFetch();
        setbookingIds([]);
        success_toaster(res?.data?.message);
      } else {
        error_toaster(res?.data?.error);
      }
    }
  };

  return (
    <>
      <Layout
        pageTitle=""
        extend={true}
        data={
          <section>
            <h1>Warehouse</h1>
            <div className="flex justify-between my-5 relative before:absolute before:content-[''] before:-bottom-6 before:z-20 before:w-full before:h-2 before:rounded-md before:bg-[#FF745A33]">
              <TabHead
                title="At Warehouse"
                activeTab={tabs === "At warehouse" && true}
                onClick={() => {
                  setTabs("At warehouse");
                }}
              />
              <TabHead
                title="Require Transportation"
                activeTab={tabs === "Require Transportation" && true}
                onClick={() => {
                  setTabs("Require Transportation");
                }}
              />
              <TabHead
                title="Returned Packages"
                activeTab={tabs === "Returned packages" && true}
                onClick={() => {
                  setTabs("Returned packages");
                }}
              />
            </div>
            <div className="mt-14">
              {tabs === "Returned packages" ? (
                <div className="flex items-center justify-between">
                  <div className="flex my-5 ">
                    <button
                      onClick={() => setReturnTab("Returned Jobs(Incoming)")}
                      className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                        returnTab === "Returned Jobs(Incoming)"
                          ? "bg-themePersian text-white"
                          : "text-themePersian"
                      }`}
                    >
                      Returned Jobs(Incoming)
                    </button>
                    <button
                      onClick={() => setReturnTab("Returned Jobs(Received)")}
                      className={`font-medium text-lg border border-themePersian px-4 py-3 ${
                        returnTab === "Returned Jobs(Received)"
                          ? "bg-themePersian text-white"
                          : "text-themePersian"
                      }`}
                    >
                      Returned Jobs(Received)
                    </button>
                  </div>
                  <div>
                    <input
                      className="text-base font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none w-96"
                      type="search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search..."
                      ref={inputRef}
                      autoFocus
                    />
                  </div>
                </div>
              ) : (
                <div className="grid md:grid-cols-3 gap-4">
                  <input
                    className="text-base font-normal rounded-lg border border-transparent px-4 py-3 focus:outline-none"
                    type="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    ref={inputRef}
                    autoFocus
                  />
                </div>
              )}
              {tabs === "Require Transportation" &&
                getData?.data?.deliveredAtWarehouse && (
                  <>
                    <div className="flex lg:flex-row flex-col justify-end gap-3 my-4">
                      <select
                        onChange={(e) => setSearchWarehouse(e.target.value)}
                        className="text-base font-medium border border-transparent bg-white rounded-lg py-3 px-4 w-full lg:w-80 focus:outline-none"
                      >
                        <option value="">Select WareHouse</option>
                        {wareHouseData?.data?.map((ele, key) => (
                          <option value={ele.id}>{ele.name}</option>
                        ))}
                      </select>
                      <button
                        className="text-lg font-bold font-feixen text-white bg-themePersian px-3 xl:px-5 py-2 rounded-md"
                        onClick={onOpen}
                      >
                        Assign Transporter Guy
                      </button>
                    </div>
                    <Modal
                      className="font-feixen"
                      onClose={onClose}
                      isOpen={isOpen}
                      isCentered
                    >
                      <ModalOverlay style={{ background: "rgba(0,0,0,0.1)" }} />
                      <ModalContent>
                        <ModalHeader>Modal Title</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          {" "}
                          <select
                            onChange={transporterid}
                            className="text-base font-medium border border-transparent shadow-inner rounded-lg py-3 px-4 w-full focus:outline-none"
                          >
                            <option>Select Transpoter Guy</option>
                            {transporterGuyData?.data?.map((ele, key) => (
                              <option value={ele.id}>
                                {ele.firstName + " " + ele.lastName}
                              </option>
                            ))}
                          </select>
                        </ModalBody>
                        <div className="model_footer flex items-center justify-between m-6">
                          <button
                            onClick={assignTransporterData}
                            className="text-normal font-bold text-white border border-transparent bg-themePersian px-3 xl:px-5 py-1 rounded-md hover:text-black hover:bg-transparent hover:border hover:border-black"
                          >
                            Assign
                          </button>
                          <button
                            className="text-normal font-bold text-white border border-transparent bg-themeRed px-3 xl:px-5 py-1 rounded-md hover:text-black hover:bg-transparent hover:border hover:border-black"
                            onClick={onClose}
                          >
                            Close
                          </button>
                        </div>
                      </ModalContent>
                    </Modal>
                  </>
                )}

              {!getData?.data ? (
                <div className="grid grid-cols-12 bg-white p-5 rounded-lg my-8">
                  <div className="col-span-10 space-y-3">
                    <div className="flex items-center gap-x-2">
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                      <div className="w-48">
                        <Skeleton />
                      </div>
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-1">
                        <div className="w-5 m-auto">
                          <Skeleton count={3} />
                        </div>
                      </div>
                      <div className="col-span-11 flex flex-col justify-between space-y-4">
                        <div>
                          <h3 className="w-28 text-base font-medium text-black text-opacity-60">
                            <Skeleton />
                          </h3>
                        </div>
                        <div className="flex gap-x-16">
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                          <div>
                            <h3 className="w-20">
                              <Skeleton />
                            </h3>
                            <h3 className="w-14">
                              <Skeleton />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 gap-x-5 flex flex-col items-end justify-between">
                    <div>
                      <div className="space-x-1 w-20">
                        <span>
                          <Skeleton />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {getData?.data?.atWarehouse.length > 0 &&
                  tabs === "At warehouse"
                    ? getAtWarehouse().map((ele, key) => (
                        <OrderCreatedCard
                          apiEle={ele}
                          badge={ele.bookingStatus.title}
                          bg={
                            ele.bookingStatusId === 1
                              ? "bg-themeOrange"
                              : "bg-themePersianLight"
                          }
                        />
                      ))
                    : getData?.data?.deliveredAtWarehouse.length > 0 &&
                      tabs === "Require Transportation"
                    ? getDeliveredAtWarehouse().map((ele, key) =>
                        searchWarehouse === "" ? (
                          <>
                            <div className="relative">
                              {check && bookingIds.includes(ele?.id) ? (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked={false}
                                />
                              )}

                              <OrderCreatedCard
                                apiEle={ele}
                                badge="Received Warehouse"
                                bg="bg-themeGreen"
                              />
                            </div>
                          </>
                        ) : ele?.deliveryWarehouseId ===
                          parseInt(searchWarehouse) ? (
                          <>
                            <div className="relative">
                              {check && bookingIds.includes(ele?.id) ? (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className="absolute lg:top-1/2 top-10 lg:-left-10 right-10 h-6 w-6"
                                  onChange={() =>
                                    handlecheckbox(
                                      ele.id,
                                      ele.deliveryWarehouseId,
                                      ele.deliveryWarehouse.name
                                    )
                                  }
                                  checked={false}
                                />
                              )}

                              <OrderCreatedCard
                                apiEle={ele}
                                badge="Received Warehouse"
                                bg="bg-themeGreen"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      )
                    : tabs === "Returned packages" &&
                      (returnTab === "Returned Jobs(Incoming)"
                        ? getReturnPackInc().map((ele, key) => (
                            <OrderCreatedCard
                              key={key}
                              apiEle={ele}
                              badge={ele.bookingStatus.title}
                              bg={
                                ele.bookingStatusId === 1
                                  ? "bg-themeOrange"
                                  : "bg-themePersianLight"
                              }
                            />
                          ))
                        : getReturnPackReceived().map((ele, key) => (
                            <OrderCreatedCard
                              key={key}
                              apiEle={ele}
                              badge={ele.bookingStatus.title}
                              bg={
                                ele.bookingStatusId === 1
                                  ? "bg-themeOrange"
                                  : "bg-themePersianLight"
                              }
                            />
                          )))}

                  <div className="flex justify-end gap-x-4">
                    <button
                      className="text-lg text-themePersian  border border-themePersian flex items-center rounded-md px-4 py-2"
                      onClick={handlePrevClick}
                    >
                      <RiArrowLeftSLine /> Previous
                    </button>
                    <button
                      className="text-lg text-themePersian border border-themePersian flex items-center justify-center rounded-md px-4 py-2 w-[123px] text-center"
                      onClick={handleNextClick}
                    >
                      Next <RiArrowRightSLine />
                    </button>
                  </div>
                </div>
              )}
            </div>

            <Modal
              className="font-feixen"
              onClose={onClose}
              isOpen={isOpen}
              isCentered
            >
              <ModalOverlay style={{ background: "rgba(0,0,0,0.1)" }} />
              <ModalContent>
                <ModalHeader>Modal Title</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {" "}
                  <select
                    onChange={transporterid}
                    className="text-base font-medium border border-transparent shadow-inner rounded-lg py-3 px-4 w-full focus:outline-none"
                  >
                    <option>Select Transpoter Guy</option>
                    {transporterGuyData?.data?.map((ele, key) => (
                      <option value={ele.id}>
                        {ele.firstName + " " + ele.lastName}
                      </option>
                    ))}
                  </select>
                </ModalBody>
                <div className="model_footer flex items-center justify-between m-6">
                  <button
                    onClick={assignTransporterData}
                    className="text-normal font-bold text-white border border-transparent bg-themePersian px-3 xl:px-5 py-1 rounded-md hover:text-black hover:bg-transparent hover:border hover:border-black"
                  >
                    Assign
                  </button>
                  <button
                    className="text-normal font-bold text-white border border-transparent bg-themeRed px-3 xl:px-5 py-1 rounded-md hover:text-black hover:bg-transparent hover:border hover:border-black"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </ModalContent>
            </Modal>
          </section>
        }
      />
    </>
  );
}
