// @ts-nocheck
import React, { useEffect, useState } from "react";
import Card from "./homeElements/Card";
import axios from "../../api/API";
import {
  BsTelephoneInbound,
  BsTelephoneOutbound,
  BsFolderPlus,
} from "react-icons/bs";
import { AiOutlineFileDone } from "react-icons/ai";
import { BiCalendarX } from "react-icons/bi";
import { MdRestaurantMenu } from "react-icons/md";
import LargeCard from "./homeElements/LargeCard";
import Layout from "../layout/Layout";
import Skeleton from "react-loading-skeleton";

export default function Home() {
  const [resData, setResData] = useState();
  const [recentData, setRecentData] = useState();
  const [loading, setloading] = useState(true);
  const [recentloading, setRecentloading] = useState(true);
  const dashboard = async () => {
    try {
      const res = await axios.get("/warehouse/dashboard/general", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setResData(res.data);
      setloading(false);
    } catch (error) {
    }
  };
  const recent = async () => {
    try {
      const res = await axios.get("/warehouse/dashboard/recent", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      });
      setRecentData(res.data);
      setRecentloading(false);
    } catch (error) {
    }
  };
  useEffect(() => {
    dashboard();
    recent();
  }, []);

  return (
    <>
      <Layout
        pageTitle="Dashboard"
        extend={true}
        data={
          <section>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-x-6 gap-y-6">
              {loading === true ? (
                <>
                  <div className="flex items-center bg-white lg:px-6 px-3 lg:py-4 py-2 rounded-lg space-y-2  md:h-[152px] h-[150px] w-full">
                    <div className="">
                      <h3 className="text-black lg:text-xl text-lg font-medium text-opacity-60 mb-3">
                        <Skeleton />
                      </h3>
                      <div className="flex items-center lg:gap-x-5 gap-x-2">
                        <div className="w-12">
                          <Skeleton />
                        </div>
                        <h1 className="w-16">
                          <Skeleton />
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center bg-white lg:px-6 px-3 lg:py-4 py-2 rounded-lg space-y-2  md:h-[152px] h-[150px] w-full">
                    <div className="">
                      <h3 className="text-black lg:text-xl text-lg font-medium text-opacity-60 mb-3">
                        <Skeleton />
                      </h3>
                      <div className="flex items-center lg:gap-x-5 gap-x-2">
                        <div className="w-12">
                          <Skeleton />
                        </div>
                        <h1 className="w-16">
                          <Skeleton />
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center bg-white lg:px-6 px-3 lg:py-4 py-2 rounded-lg space-y-2  md:h-[152px] h-[150px] w-full">
                    <div className="">
                      <h3 className="text-black lg:text-xl text-lg font-medium text-opacity-60 mb-3">
                        <Skeleton />
                      </h3>
                      <div className="flex items-center lg:gap-x-5 gap-x-2">
                        <div className="w-12">
                          <Skeleton />
                        </div>
                        <h1 className="w-16">
                          <Skeleton />
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center bg-white lg:px-6 px-3 lg:py-4 py-2 rounded-lg space-y-2  md:h-[152px] h-[150px] w-full">
                    <div className="">
                      <h3 className="text-black lg:text-xl text-lg font-medium text-opacity-60 mb-3">
                        <Skeleton />
                      </h3>
                      <div className="flex items-center lg:gap-x-5 gap-x-2">
                        <div className="w-12">
                          <Skeleton />
                        </div>
                        <h1 className="w-16">
                          <Skeleton />
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center bg-white lg:px-6 px-3 lg:py-4 py-2 rounded-lg space-y-2  md:h-[152px] h-[150px] w-full">
                    <div className="">
                      <h3 className="text-black lg:text-xl text-lg font-medium text-opacity-60 mb-3">
                        <Skeleton />
                      </h3>
                      <div className="flex items-center lg:gap-x-5 gap-x-2">
                        <div className="w-12">
                          <Skeleton />
                        </div>
                        <h1 className="w-16">
                          <Skeleton />
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center bg-white lg:px-6 px-3 lg:py-4 py-2 rounded-lg space-y-2  md:h-[152px] h-[150px] w-full">
                    <div className="">
                      <h3 className="text-black lg:text-xl text-lg font-medium text-opacity-60 mb-3">
                        <Skeleton />
                      </h3>
                      <div className="flex items-center lg:gap-x-5 gap-x-2">
                        <div className="w-12">
                          <Skeleton />
                        </div>
                        <h1 className="w-16">
                          <Skeleton />
                        </h1>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Card
                    title="Incoming Orders"
                    quantity={resData?.data?.incoming}
                    percentage="168.1%"
                    variation="up"
                    Icon={BsTelephoneInbound}
                    bgColor="bg-themePurple"
                    textColor="text-themePurple"
                  />
                  <Card
                    title="Outgoing Orders"
                    quantity={resData?.data?.outgoing}
                    percentage="168.1%"
                    variation="down"
                    Icon={BsTelephoneOutbound}
                    bgColor="bg-themePurple2"
                    textColor="text-themePurple2"
                  />
                  <Card
                    title="Order Created"
                    quantity={resData?.data?.created}
                    percentage="168.1%"
                    variation="up"
                    Icon={BsFolderPlus}
                    bgColor="bg-themeBlue"
                    textColor="text-themeBlue"
                  />
                  <Card
                    title="Order Delivered"
                    quantity={resData?.data?.delivered}
                    percentage="168.1%"
                    variation="up"
                    Icon={MdRestaurantMenu}
                    bgColor="bg-themeGreen"
                    textColor="text-themeGreen"
                  />
                  <Card
                    title="Cancelled Orders"
                    quantity={resData?.data?.cancelled}
                    percentage="168.1%"
                    variation="up"
                    Icon={BiCalendarX}
                    bgColor="bg-themeYellow"
                    textColor="text-themeYellow"
                  />
                  <Card
                    title="In Transist Order"
                    quantity={resData?.data?.inTransit}
                    percentage="168.1%"
                    variation="up"
                    Icon={AiOutlineFileDone}
                    bgColor="bg-themeBlue2"
                    textColor="text-themeBlue2"
                  />
                </>
              )}
            </div>
            <div className="">
              <h1 className="my-5">Recent Activity</h1>
              {recentloading === true ? (
                <div className="space-y-3">
                  <div className="bg-white px-6 pt-5 pb-2 rounded-lg">
                    <div className="flex justify-between">
                      <h3 className="w-32">
                        <Skeleton />
                      </h3>
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                    </div>
                    <div className="flex flex-col gap-x-5">
                      <h1 className="w-48">
                        <Skeleton />
                      </h1>
                      <h1 className="w-24">
                        <Skeleton />
                      </h1>
                    </div>
                  </div>
                  <div className="bg-white px-6 pt-5 pb-2 rounded-lg">
                    <div className="flex justify-between">
                      <h3 className="w-32">
                        <Skeleton />
                      </h3>
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                    </div>
                    <div className="flex flex-col gap-x-5">
                      <h1 className="w-48">
                        <Skeleton />
                      </h1>
                      <h1 className="w-24">
                        <Skeleton />
                      </h1>
                    </div>
                  </div>
                  <div className="bg-white px-6 pt-5 pb-2 rounded-lg">
                    <div className="flex justify-between">
                      <h3 className="w-32">
                        <Skeleton />
                      </h3>
                      <h3 className="w-24">
                        <Skeleton />
                      </h3>
                    </div>
                    <div className="flex flex-col gap-x-5">
                      <h1 className="w-48">
                        <Skeleton />
                      </h1>
                      <h1 className="w-24">
                        <Skeleton />
                      </h1>
                    </div>
                  </div>
                </div>
              ) : (
                recentData?.data?.map((ele, key) => <LargeCard ele={ele} />)
              )}
            </div>
          </section>
        }
      />
    </>
  );
}
